import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Popup,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as ActionGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import axios from "axios";
import useLookup from "../consumer/LookupConsumer";
import { FormProvider } from "react-hook-form";
import ActionDetailsScreen from "./ActionDetailsScreen";

function ActionListScreen(visitId) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [rows, setRows] = useState([]);
  const [row, setRow] = useState({});
  const { lookups: fipActionType } = useLookup(6111);

  const transformData = (rows) => {
    return rows.map((row) => ({
      id: row.id,
      action: row.earlyProviderAction.action
        ? row.earlyProviderAction.action.replace(/<\/?[^>]+(>|$)/g, "")
        : null,
      actionArea: row.earlyProviderAction.actionArea,
      actionDate: row.earlyProviderAction.actionDate,
      actionType: row.earlyProviderAction.actionType,
      agreedInpDate: row.earlyProviderAction.agreedInpDate,
      completedDate: row.earlyProviderAction.completedDate,
      evidence: row.earlyProviderAction.evidence
        ? row.earlyProviderAction.evidence.replace(/<\/?[^>]+(>|$)/g, "")
        : null,
      person: row.earlyProviderAction.person
        ? row.earlyProviderAction.person.forename +
          " " +
          row.earlyProviderAction.person.surname +
          " (" +
          row.earlyProviderAction.person.personId +
          ")"
        : "",
      forename: row.earlyProviderAction.person
        ? row.earlyProviderAction.person.forename
        : null,
      surname: row.earlyProviderAction.person
        ? row.earlyProviderAction.person.surname
        : null,
      personId: row.earlyProviderAction.person
        ? row.earlyProviderAction.personId
        : null,
      validationFlag: row.validationFlag,
      rejectComments: row.rejectComments,
      rejectedDate: row.rejectedDate,
      rejectedBy: row.rejectedBy,
    }));
  };

  const fetchAction = () => {
    axios
      .get(`/api/early-visit-note-action-list/${visitId.visitId}`)
      .then(({ data: rows }) => {
        setRows(rows);
      })
      .then(console.log);
  };

  useEffect(() => {
    if (visitId.visitId) {
      fetchAction();
    }
  }, []);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Actions")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <>
            <ActionGrid
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <UserAccessComponent requires="PPORT_PM_FIP_L_EDIT">
                    <Popup
                      content={t("Edit")}
                      trigger={
                        <Icon
                          circular
                          name="eye"
                          className={"dark-blue-border"}
                          color="black"
                          onClick={() => {
                            setRow(row);
                            setShowDetail(true);
                          }}
                        />
                      }
                    />
                  </UserAccessComponent>
                </div>
              )}
              columns={[
                {
                  key: "id",
                  name: t("Action Id"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "actionType",
                  name: t("Action Type"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: fipActionType,
                },
                {
                  key: "actionDate",
                  name: t("Action Date"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter,
                },
                {
                  key: "action",
                  name: t("Action"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "person",
                  name: t("Action Owner"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "agreedInpDate",
                  name: t("Agreed Implementation Date"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter,
                },
                {
                  key: "completedDate",
                  name: t("Completed Date"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter,
                },
              ]}
              rows={transformData(rows)}
            />
          </>
        ) : (
          <ActionDetailsScreen
            row={row}
            onBack={() => {
              setShowDetail(false);
              setRow({});
            }}
          />
        )}
      </Segment>
    </>
  );
}

export default ActionListScreen;
