import { useEffect } from "react";
import useGridRows from "../hooks/useGridRows";
import { useSession } from "../consumer/SessionConsumer";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import axios from "axios";
import WellcommAssessmentGridScreen from "./WellcommAssessmentGridScreen";
import WellcommAssessmentDetailsScreen from "./WellcommAssessmentDetailsScreen";

const addSpaceAtEnd = str => (str ? str + " " : "");

export const wellcommAssessmentRowMapper = ({ person, ...rest }) => ({
  completedBy: person
    ? `${addSpaceAtEnd(person?.forename)}${addSpaceAtEnd(person?.surname)} (${
        person?.personId
      })`
    : "",
  ...rest
});

function WellcommAssessmentScreen() {
  const { rows, row, refetch, setRows, setRow, setRefetch } = useGridRows();
  const { selectedProvider: provider } = useSession();
  const match = useRouteMatch();

  useEffect(() => {
    const fetchStaffRecords = () => {
      if (provider.providerId) {
        axios
          .get(`/api/wellcomm-assessment-list/${provider.providerId}`)
          .then(({ data }) => {
            setRows(data.map(wellcommAssessmentRowMapper));
          });
      }
    };
    fetchStaffRecords();
  }, [provider.providerId, refetch]);

  return (
    <Switch>
      <Route path={`${match.path}/:wellcomAssessId`}>
        <WellcommAssessmentDetailsScreen
          setRefetch={setRefetch}
          refetch={refetch}
          row={row}
          setRow={setRow}
          rows={rows}
        />
      </Route>
      <Route>
        <WellcommAssessmentGridScreen
          rows={rows}
          setRow={setRow}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      </Route>
    </Switch>
  );
}

export default WellcommAssessmentScreen;
