import { Grid } from "semantic-ui-react";
import PickupDropOffScreen from "../components/PickupDropOffScreen";
import EstablishmentPickupDropOffScreen from "../components/EstablishmentPickupDropOffScreen";
import ServiceProvidedScreen from "../components/ServicesProvidedScreen";
import TransportRouteScreen from "../components/TransportRouteScreen";
import SpecialProvisions from "../components/SpecialProvisionsScreen";
import FacilityScreen from "../components/FacilitiesScreen";

function DetailsScreen({ setValidationFlag, urlSlug }) {
  return (
    <Grid stackable>
      <Grid.Row className={"padding-top-0 padding-bottom-0"}>
        <Grid.Column width={8} className={"padding-left-0 padding-right-10"}>
          <PickupDropOffScreen
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"padding-left-0 padding-right-3"}>
          <EstablishmentPickupDropOffScreen
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={"padding-top-0 padding-bottom-0"}>
        <Grid.Column width={8} className={"padding-left-0 padding-right-10"}>
          <ServiceProvidedScreen
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"padding-left-0 padding-right-3"}>
          <TransportRouteScreen
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={"padding-top-0 padding-bottom-0"}>
        <Grid.Column width={8} className={"padding-left-0 padding-right-10"}>
          <SpecialProvisions
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
          />
        </Grid.Column>
        <Grid.Column width={8} className={"padding-left-0 padding-right-3"}>
          <FacilityScreen
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default DetailsScreen;
