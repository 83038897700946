import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Container } from "semantic-ui-react";
import LoginScreen from "./LoginScreen";
import RegisterScreen from "./RegisterScreen";
import ActivateScreen from "./ActivateScreen";
import RequestPasswordResetScreen from "./RequestPasswordResetScreen";
import PasswordResetScreen from "./PasswordResetScreen";

function PublicScreen() {
  const history = useHistory();

  useEffect(() => {
    if (!!sessionStorage.getItem("jwt")) {
      history.push("/");
    }
  });

  return (
    <Container>
      <h1>Providers Portal</h1>
      <Switch>
        <Route path="/public/login">
          <LoginScreen />
        </Route>
        <Route path="/public/register">
          <RegisterScreen />
        </Route>
        <Route path="/public/activate/:key">
          <ActivateScreen />
        </Route>
        <Route path="/public/reset/:key">
          <PasswordResetScreen />
        </Route>
        <Route path="/public/reset">
          <RequestPasswordResetScreen />
        </Route>
      </Switch>
    </Container>
  );
}

export default PublicScreen;
