import {useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Menu, Segment,} from "semantic-ui-react";
import {Dropdown} from "@caci/react-form-fields";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import UserAccessComponent from "./UserAccessComponent";

const schema = yup.object({
  templateCode: yup.string().required("Please select a value"),
});

function ProfileOutput({
  title,
  generateUserAccessCode,
  generateOutput,
  getOutputList = [],
}) {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ templateCode }) => {
    generateOutput(templateCode);
  };
  useEffect(() => {}, []);

  return (
    <>
      <Container fluid>
        <Grid stackable>
          <Grid.Row className={"padding-top-0"}>
            <Menu attached="top" className={"border-bottom"}>
              <Menu.Item header className={"active"}>
                {t(title)}
              </Menu.Item>
            </Menu>
            <Segment attached={"bottom"}>
              <Grid.Column
                width={4}
                className={"padding-left-0 padding-right-10"}
              >
                <FormProvider {...methods}>
                  <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Dropdown
                      fluid
                      search
                      selection
                      label={t("Output Template")}
                      options={getOutputList}
                      name="templateCode"
                    />
                    <br />
                    <UserAccessComponent requires={generateUserAccessCode}>
                      <Button className={"purple"} type="submit">
                        <Icon name="file alternate outline" />
                        {t("Generate Output")}
                      </Button>
                    </UserAccessComponent>
                  </Form>
                </FormProvider>
              </Grid.Column>
            </Segment>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
}

export default ProfileOutput;
