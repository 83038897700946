import {Fragment, useEffect, useState} from "react";
import {DefaultFilter, Grid as AgeRangeGrid} from "@caci/react-grid";
import axios from "axios";
import {Button, Form, Grid, Icon, Menu, Popup, Segment,} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import useLookup from "../consumer/LookupConsumer";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import {Input} from "@caci/react-form-fields";
import * as yup from "yup";
import {useToasts} from "react-toast-notifications";
import {useSession} from "../consumer/SessionConsumer";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import moment from "moment";
import RejectCommentsModal from "./RejectCommentsModal";

const validationFlagsList = ["1", "2", "3"];

const schema = yup.object({
  description: yup
    .string()
    .required("Required")
    .max(30, "30 character limit exceeded"),
  ageTo: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(2, "2 character limit exceeded"),
  ageFrom: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(2, "2 character limit exceeded"),

  regPlaces: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(3, "3 character limit exceeded"),

  waitPlaces: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(3, "3 character limit exceeded"),

  vacPlaces: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(3, "3 character limit exceeded"),
});

const putObject = {
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};

function AgeRangesScreen({ holidayId, setValidationFlag, urlSlug }) {
  const [rows, setRows] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [lastModifiedDate, setLastModifiedDate] = useState();
  const [totalRegPlaces, setTotalRegPlaces] = useState();
  const [totalVacPlaces, setTotalVacPlaces] = useState();
  const [totalWaitPlaces, setTotalWaitPlaces] = useState();
  const { lookups: availability } = useLookup(896);
  const { selectedProvider: provider } = useSession();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleDelete = (id) => {
    const data = [{ op: "add", path: "/validationFlag", value: "D" }];
    axios
      .put(`/api/pport-early-places/${id}`, data, putObject)
      .then(() => {
        addToast(`${t("Age Range")} ${t("has been successfully removed")}`, {
          appearance: "success",
        });
        fetchEarlyPlaces();
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Age Range")} ${t("has been successfully removed")}`, {
          appearance: "error",
        });
      });
  };

  const onSubmit = ({
    placesId,
    description,
    ageFrom,
    ageTo,
    waitPlaces,
    regPlaces,
    vacPlaces,
  }) => {
    const data = {
      description: description,
      ageFrom: Number(ageFrom),
      ageTo: Number(ageTo),
      waitPlaces: waitPlaces,
      regPlaces: regPlaces,
      vacPlaces: vacPlaces,
      holidayId: holidayId.holidayId,
      validationFlag: "I",
    };

    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/description", value: description },
      { op: "add", path: "/ageFrom", value: ageFrom },
      { op: "add", path: "/ageTo", value: ageTo },
      { op: "add", path: "/waitPlaces", value: waitPlaces },
      { op: "add", path: "/regPlaces", value: regPlaces },
      { op: "add", path: "/vacPlaces", value: vacPlaces },
    ];

    if (placesId) {
      axios
        .put(`/api/pport-early-places/${placesId}`, put, putObject)
        .then(() => {
          addToast(`${t("Age Range")} ${t("has been successfully updated")}`, {
            appearance: "success",
          });
          fetchEarlyPlaces();
        })
        .catch(console.log);
    } else {
      axios
        .post(`/api/pport-early-places`, data)
        .then(() => {
          addToast(`${t("Age Range")} ${t("has been successfully created")}`, {
            appearance: "success",
          });
          fetchEarlyPlaces();
        })
        .catch(console.log);
    }
  };

  const fetchEarlyPlaces = () => {
    if (holidayId) {
      axios
        .get(`/api/pport-early-places-list/${holidayId.holidayId}`)
        .then(({ data: rows }) => {
          if (rows.length > 0) setRows(rows);
          const validationFlagIndicator = rows.some((row) =>
            validationFlagsList.includes(row.validationFlag)
          );
          setValidationFlag(urlSlug, {
            name: "ageRanges",
            value: validationFlagIndicator,
          });
          setShowExclamation(validationFlagIndicator);
          const modifiedDates = rows.map((row) => moment(row.modifiedDate));
          let lastModifiedDate;
          if (modifiedDates.length > 0)
            lastModifiedDate = moment.max(modifiedDates);

          const {
            totalRegPlaces,
            totalWaitPlaces,
            totalVacPlaces,
          } = rows.reduce(
            (acc, row) => {
              let { totalRegPlaces, totalWaitPlaces, totalVacPlaces } = acc;
              totalRegPlaces += row.regPlaces ? row.regPlaces : 0;
              totalWaitPlaces += row.waitPlaces ? row.waitPlaces : 0;
              totalVacPlaces += row.vacPlaces ? row.vacPlaces : 0;
              return { totalRegPlaces, totalWaitPlaces, totalVacPlaces };
            },
            { totalRegPlaces: 0, totalWaitPlaces: 0, totalVacPlaces: 0 }
          );

          setTotalRegPlaces(totalRegPlaces);
          setTotalVacPlaces(totalVacPlaces);
          setTotalWaitPlaces(totalWaitPlaces);
        })
        .catch(console.log);
    }
  };

  const { reset } = methods;

  useEffect(() => {
    fetchEarlyPlaces();
    return () => {
      reset({
        placesId: "",
        description: "",
        ageFrom: "",
        rateTo: "",
        ageTo: "",
        regPlaces: "",
        waitPlaces: "",
        vacPlaces: "",
      });
    };
  }, [provider.providerId, holidayId]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Age Ranges (Years)")}
        </Menu.Item>
        {showExclamation && (
          <Menu.Menu position="right">
              <Menu.Item>
                  <Icon color={"red"}>
                      <i className="fas fa-exclamation-triangle" />
                  </Icon>
              </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <Fragment>
            <div>
              <AgeRangeGrid
                actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <UserAccessComponent requires="PPORT_PM_HCP_PLACE_L_EDIT">
                      <Popup
                        content={t("Edit")}
                        trigger={
                          <Icon
                            circular
                            name="pencil"
                            className={"dark-blue-border"}
                            color="black"
                            onClick={() => {
                              setShowDetail(true);
                              methods.setValue(`placesId`, row.placesId);
                              methods.setValue(`description`, row.description);
                              methods.setValue(`ageFrom`, row.ageFrom);
                              methods.setValue(`ageTo`, row.ageTo);
                              methods.setValue(`regPlaces`, row.regPlaces);
                              methods.setValue(`waitPlaces`, row.waitPlaces);
                              methods.setValue(`vacPlaces`, row.vacPlaces);
                            }}
                          />
                        }
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_PLACE_L_EDIT">
                      <RejectCommentsModal
                        rejectComments={row.rejectComments}
                        rejectedDate={row.rejectedDate}
                        rejectedBy={row.rejectedBy}
                        disabled={
                          !validationFlagsList.includes(row.validationFlag)
                        }
                        circular
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_PLACE_L_DEL">
                      <DeleteConfirmationModal
                        message={t(
                          "Are you sure you want to delete this Age Range?"
                        )}
                        onDelete={() => handleDelete(row.placesId)}
                        iconName="trash alternate outline"
                        color="red-border"
                        circular
                      />
                    </UserAccessComponent>
                  </div>
                )}
                columns={[
                  {
                    key: "description",
                    name: t("Range Description"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "ageFrom",
                    name: t("Age From"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "ageTo",
                    name: t("Age To"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "regPlaces",
                    name: t("Registered Places"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "waitPlaces",
                    name: t("Waiting List"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "vacPlaces",
                    name: t("Vacancies"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                ]}
                rows={rows}
              />
            </div>
            <div>
              <div className={"gap-btw-grid-buttons"}>
                <Grid stackable>
                  <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                    <Grid.Column
                      width={7}
                      className={"padding-left-0 padding-right-10"}
                    >
                      <UserAccessComponent requires="PPORT_PM_HCP_PLACE_L_ADD">
                        <Button
                          onClick={() => setShowDetail(true)}
                          className={"purple"}
                        >
                          <Icon>
                            <i className="far fa-sort-numeric-up" />
                          </Icon>
                          {t("New Age Range")}
                        </Button>
                      </UserAccessComponent>
                    </Grid.Column>
                    <Grid.Column
                      width={9}
                      className={"padding-left-0 padding-right-10"}
                    >
                      <Form>
                        <Form.Group widths={"equal"}>
                          <Form.Input
                            fluid
                            label={t("Total Registered Places")}
                            name="totalRegPlaces"
                            value={totalRegPlaces}
                            readOnly
                          />
                          <Form.Input
                            fluid
                            label={t("Total Waiting List")}
                            name="totalWaitPlaces"
                            value={totalWaitPlaces}
                            readOnly
                          />
                          <Form.Input
                            fluid
                            label={t("Total Vacancies")}
                            name="totalVacPlaces"
                            value={totalVacPlaces}
                            readOnly
                          />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid>
                  <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                    <Grid.Column
                      width={8}
                      className={"padding-left-0 padding-right-10"}
                    >
                      <Input
                        label={t("Range Description")}
                        name="description"
                      />
                      <Input label={t("Age From")} name="ageFrom" />
                      <Input label={t("Age To")} name="ageTo" />
                      <Input label={t("Registered Places")} name="regPlaces" />
                    </Grid.Column>
                    <Grid.Column
                      width={8}
                      className={"padding-left-0 padding-right-10"}
                    >
                      <Input label={t("Waiting List")} name="waitPlaces" />
                      <Input label={t("Vacancies")} name="vacPlaces" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br />
                <Button
                  onClick={() => {
                    setShowDetail(false);
                    reset();
                  }}
                  color="orange"
                >
                  <Icon name="arrow circle left" />
                  {t("Back")}
                </Button>
                <UserAccessComponent requires="PPORT_PM_HCP_PLACE_D_SUB">
                  <Button className={"green"} type="submit">
                    <Icon name="check circle" />
                    {t("Submit")}
                  </Button>
                </UserAccessComponent>
              </Form>
            </FormProvider>
          </Fragment>
        )}
      </Segment>
    </>
  );
}

export default AgeRangesScreen;
