import { Fragment, useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Menu, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Input, Dropdown } from "@caci/react-form-fields";
import { setValueHelper, yesNoOptions } from "../utils";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import RejectCommentsModal from "./RejectCommentsModal";

const validationFlagsList = ["1", "2", "3"];

const putObject = {
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};

function HoursChargesPlacesDetails({
  holidayId = {},
  rows,
  setValidationFlag,
  urlSlug,
}) {
  const { selectedProvider: provider } = useSession();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);

  const schema = yup.object({
    nonRegPlaces: yup.string().matches(/^[0-9]+$/, "Must be only digits"),

    assistPlaces: yup.string().matches(/^[0-9]+$/, "Must be only digits"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({
    nonRegPlaces,
    assistPlaces,
    playScheme,
    fullDayCare,
    continuouslyOpen,
    overnightCcInd,
  }) => {
    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/nonRegPlaces", value: nonRegPlaces },
      { op: "add", path: "/assistPlaces", value: assistPlaces },
      { op: "add", path: "/playScheme", value: playScheme },
      { op: "add", path: "/fullDayCare", value: fullDayCare },
      { op: "add", path: "/continuouslyOpen", value: continuouslyOpen },
      { op: "add", path: "/overnightCcInd", value: overnightCcInd },
    ];
    axios
      .put(`/api/pport-early-holiday/${holidayId.holidayId}`, put, putObject)
      .then(() => {
        addToast(
          `${t("Hours, Charges & Places record")} ${t(
            "has been successfully updated"
          )}`,
          {
            appearance: "success",
          }
        );
        if (!!holidayId.setEarlyHolidays) {
          const data = {
            nonRegPlaces,
            assistPlaces,
            playScheme,
            fullDayCare,
            continuouslyOpen,
            overnightCcInd,
          };
          holidayId.setEarlyHolidays(
            rows.map((r) => {
              if (r.holidayId === holidayId.holidayId) {
                return {
                  ...r,
                  ...data,
                };
              }
              return r;
            })
          );
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (holidayId) {
      const earlyHoliday =
        rows.find((eh) => eh.holidayId === holidayId.holidayId) || {};
      setValueHelper(
        {
          nonRegPlaces: earlyHoliday.nonRegPlaces || "",
          assistPlaces: earlyHoliday.assistPlaces || "",
          playScheme: earlyHoliday.playScheme,
          fullDayCare: earlyHoliday.fullDayCare,
          continuouslyOpen: earlyHoliday.continuouslyOpen,
          overnightCcInd: earlyHoliday.overnightCcInd,
        },
        methods.setValue
      );

      const validationFlagIndicator = validationFlagsList.includes(
        earlyHoliday.validationFlag
      );

      setValidationFlag(urlSlug, {
        name: "hoursChargesPlaces",
        value: validationFlagIndicator,
      });

      setShowExclamation(validationFlagIndicator);
    }
  }, [provider.providerId, holidayId.holidayId]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Hours, Charges & Places Details")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <UserAccessComponent requires="PPORT_PM_HCP_PER_L_EDIT">
              <Menu.Item>
                <RejectCommentsModal
                  rejectComments={holidayId ? holidayId.rejectComments : ""}
                  rejectedDate={holidayId ? holidayId.rejectedDate : ""}
                  rejectedBy={holidayId ? holidayId.rejectedBy : ""}
                  color={"orange"}
                />
              </Menu.Item>
            </UserAccessComponent>
          )}
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>

      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Segment attached={"bottom"}>
            <Grid stackable>
              <Grid.Row
                className={"padding-top-0 padding-bottom-0"}
                style={{ paddingBottom: "3px" }}
              >
                <Grid.Column
                  width={8}
                  className={"padding-left-0 padding-right-10"}
                >
                  <Input
                    label={t("Non-Registered Places")}
                    name="nonRegPlaces"
                  />
                  <Input
                    label={t("Places with Assistants")}
                    name="assistPlaces"
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Play Scheme")}
                    name="playScheme"
                    options={yesNoOptions}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Full Day Care")}
                    name="fullDayCare"
                    options={yesNoOptions}
                  />
                </Grid.Column>
                <Grid.Column width={8} className={"padding-left-right-0"}>
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Continuously Open")}
                    name="continuouslyOpen"
                    options={yesNoOptions}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Overnight Childcare")}
                    name="overnightCcInd"
                    options={yesNoOptions}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <UserAccessComponent requires="PPORT_PM_HCP_PER_D_SUB">
              <Button className={"green"} type="submit">
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Segment>
        </Form>
      </FormProvider>
    </>
  );
}

export default HoursChargesPlacesDetails;
