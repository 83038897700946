import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Popup, Button, Icon, Form } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { Checkbox } from "@caci/react-form-fields";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import styled from "styled-components";
import {processFileUploadError} from "../utils";
import {useToasts} from "react-toast-notifications";

const ContentHeaderWrapper = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`;

const ContentBodyWrapper = styled.div`
  margin-bottom: 24px;
`;

const CheckBoxWrapper = styled.div`
  margin-bottom: 24px;
`;

function FileUploadModal({
  name,
  title,
  headerContentModule,
  headerContentArea,
  headerContentItem,
  bodyContentModule,
  bodyContentArea,
  bodyContentItem,
  appendedName,
  postApi,
  setError,
  setRefetch,
  tableId,
  providerId
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const [isLoading, setLoading] = useState(false);
  const [headerContent, setHeaderContent] = useState();
  const [bodyContent, setBodyContent] = useState();
  const { addToast } = useToasts();

  const schema = yup.object({
    readAndAgreed: yup.bool().oneOf([true], "Field must be checked")
  });

  const methods = useForm({ resolver: yupResolver(schema) });

  const uploadFile = payload => {
    setLoading(true);
    axios
      .post(`${postApi}`, payload)
      .then(() => {
        setError("");
        addToast(`${t("Associated File")} ${t(`has been successfully uploaded`)}`, {
          appearance: "success"
        });
        setRefetch();
      })
      .catch(error => {
        addToast(`${t("Associated File")} ${t(`has not been uploaded`)}`, {
          appearance: "error"
        });
        setError(processFileUploadError(error));
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    setOpen(false);
    methods.reset({ readAndAgreed: false });
  };

  const onSubmit = () => {
    if (!file) {
      return;
    }
    handleClose();

    const formData = new FormData();

    formData.append("file", file);
    formData.append(`${appendedName}`, tableId);
    formData.append("providerId", providerId)

    uploadFile(formData);
  };

  useEffect(() => {
    if (headerContentModule && headerContentArea && headerContentItem) {
      axios
        .get(
          `/api/portal-content/${headerContentModule}/${headerContentArea}/${headerContentItem}/content`
        )
        .then(({ data: { contentValue } }) => setHeaderContent(contentValue))
        .catch(console.log);
    }

    if (bodyContentModule && bodyContentArea && bodyContentItem) {
      axios
        .get(
          `/api/portal-content/${bodyContentModule}/${bodyContentArea}/${bodyContentItem}/content`
        )
        .then(({ data: { contentValue } }) => setBodyContent(contentValue))
        .catch(console.log);
    }
  }, []);

  return (
    <Modal
      className="dark-modal"
      trigger={
        <Popup
          trigger={
            <Button
              className="purple gap-btw-grid-buttons"
              onClick={() => setOpen(true)}
            >
              <Icon name={name} />
              {title}
            </Button>
          }
          content={t("Attachment")}
        />
      }
      open={open}
      onClose={() => handleClose()}
    >
      <Modal.Header className="modal-header">{t("Attach Files")}</Modal.Header>
      <Modal.Content className="modal-content">
        <Modal.Description>
          {headerContent && (
            <ContentHeaderWrapper
              dangerouslySetInnerHTML={{ __html: headerContent || "" }}
            />
          )}

          {bodyContent && (
            <ContentBodyWrapper
              dangerouslySetInnerHTML={{ __html: bodyContent || "" }}
            />
          )}

          <FormProvider {...methods}>
            <Form>
              <CheckBoxWrapper>
                <Checkbox name="readAndAgreed" label={t("Read & Understood")} />
              </CheckBoxWrapper>

              <Form.Field>
                <label className={"text-base"}>{t("Upload File")}</label>
                <Form.Input
                  type="file"
                  onChange={e => setFile(e.target.files[0])}
                />
              </Form.Field>
            </Form>
          </FormProvider>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="modal-actions">
        <Button className="orange" onClick={() => handleClose()}>
          <Icon name="arrow circle left" /> {t("Back")}
        </Button>
        <Button
          className="green"
          onClick={methods.handleSubmit(onSubmit)}
          disabled={!file || isLoading}
        >
          <Icon name="check circle" /> {t("Submit")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default FileUploadModal;
