import { useState, useEffect } from "react";
import { Menu, Segment, Icon, Popup, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  Grid as ServicesProvidedGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useSession } from "../consumer/SessionConsumer";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import RejectCommentsModal from "./RejectCommentsModal";
import { getValidationFlag } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import ServicesProvidedDetails from "./ServicesProvidedDetailsScreen";

const validationFlagsList = ["1", "2", "3"];

function ServiceProvidedScreen({ setValidationFlag, urlSlug }) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [rows, setRows] = useState([]);
  const [showExclamation, setShowExclamation] = useState(false);
  const [row, setRow] = useState({});
  const { selectedProvider: provider } = useSession();
  const { addToast } = useToasts();
  const { lookups: typeNameLookups } = useLookup(880);

  const handleDelete = row => {
    const data = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(row.validationFlag, "D")
      }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    };

    axios
      .put(`/api/pport-early-provisions/${row.id}`, data, putObject)
      .then(() => {
        addToast(
          `${t("Service Provided")} ${t("has been successfully removed")}`,
          {
            appearance: "success"
          }
        );
        setRefetch(!refetch);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Service Provided")} ${t("has not been removed")}`, {
          appearance: "error"
        });
      });
  };

  useEffect(() => {
    const fetchPickups = () => {
      if (!showDetail)
        axios
          .get(`/api/pport-early-provisions-list/${provider.providerId}`)
          .then(({ data: rows }) => {
            setRows(rows);
            const validationFlagIndicator = rows.some(row =>
              validationFlagsList.includes(row.validationFlag)
            );
            setValidationFlag(urlSlug, {
              name: "servicesProvided",
              value: validationFlagIndicator
            });
            setShowExclamation(validationFlagIndicator);
          })
          .then(console.log);
    };
    if (provider.providerId) fetchPickups();
  }, [provider.providerId, showDetail, refetch]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Services Provided")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <>
            <ServicesProvidedGrid
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <UserAccessComponent requires="PPORT_PM_DT_SER_L_EDIT">
                    <Popup
                      content={t("Edit")}
                      trigger={
                        <Icon
                          circular
                          name="pencil"
                          className={"dark-blue-border"}
                          color="black"
                          onClick={() => {
                            setRow(row);
                            setShowDetail(true);
                          }}
                        />
                      }
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_DT_SER_L_EDIT">
                    <RejectCommentsModal
                      rejectComments={row.rejectComments}
                      rejectedDate={row.rejectedDate}
                      rejectedBy={row.rejectedBy}
                      disabled={
                        !validationFlagsList.includes(row.validationFlag)
                      }
                      circular
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_DT_SER_L_DEL">
                    <DeleteConfirmationModal
                      message={t(
                        "Are you sure you want to delete this Service Provided?"
                      )}
                      onDelete={() => handleDelete(row)}
                      iconName="trash alternate outline"
                      color="red-border"
                      circular
                    />
                  </UserAccessComponent>
                </div>
              )}
              columns={[
                {
                  key: "typeName",
                  name: t("Service Provided"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: typeNameLookups
                }
              ]}
              rows={rows}
            />
            <div className={"gap-btw-grid-buttons"}>
              <UserAccessComponent requires="PPORT_PM_DT_SER_L_ADD">
                <Button
                  onClick={() => setShowDetail(true)}
                  className={"purple"}
                >
                  <Icon>
                    <i className="fas fa-abacus" />
                  </Icon>
                  {t("New Service Provided")}
                </Button>
              </UserAccessComponent>
            </div>
          </>
        ) : (
          <ServicesProvidedDetails
            row={row}
            onBack={() => {
              setShowDetail(false);
              setRow({});
            }}
            typeNameValid={rows.map(r => r.typeName)}
          />
        )}
      </Segment>
    </>
  );
}

export default ServiceProvidedScreen;
