import React, { useState, useEffect } from "react";
import { Button, Form, Container, Message, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Input, Dropdown } from "@caci/react-form-fields";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { handleFormValidationError } from "../utils";
import { useTranslation } from "react-i18next";
import {merge} from "lodash/object";

const validations = yup.object().shape({
  forename: yup.string().required("Required"),
  surname: yup.string().required("Required"),
  login: yup.string()
      .required("Required")
      .matches(/^[aA-zZ]+$/, "Your username can only contain letters")
      .max(30, "30 character limit exceeded"),
  email: yup.string().required("Required").email('Please enter a valid email'),
  password: yup.string().required("Required"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
  providerId: yup.string().required("Required"),
  startDate: yup.string().required("Required"),
});

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
  ERROR: 3,
};

function RegisterScreen() {
  const { t } = useTranslation();
  const [earlyProviders, setEarlyProviders] = useState([]);
  const [status, setStatus] = useState(STATUS.INIT);

  const form = useForm({
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    axios.get("/api/early-provider").then(({ data }) => {
      setEarlyProviders(data);
    });
  }, []);

  const onSubmit = (values) => {
    const finalValues= merge(values, {
      portalCode: 'PROV_PORTAL'
    });

    axios
      .post("/api/register", finalValues)
      .then(() => setStatus(STATUS.DONE))
      .catch(handleFormValidationError(form.setError));
  };

  if (status === STATUS.DONE) {
    return (
        <Container as={Link} to="/public/login">
          <Message icon success>
            <Icon name="check" />
            <Message.Content>
              <b>{t("Thank you")}</b>, {t("you will receive an activation email if registered successfully.")}
            </Message.Content>
          </Message>
        </Container>
    );
  }

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <Input label="First Name" name="forename" />
        <Input label="Last Name" name="surname" />
        <Input label="Username" name="login" />
        <Input label="Email" name="email" />
        <Input label="Password" name="password" type="password" />
        <Input label="Confirm Password" name="confirmPassword" type="password" />
        <Dropdown
          label="Provider\Setting Name"
          name="providerId"
          options={earlyProviders.map((earlyProvider) => ({
            value: earlyProvider.providerId,
            text: `${earlyProvider.providerName} (${earlyProvider.providerId})`,
          }))}
        />
        <Input label="Start Date" name="startDate" type="date" />
        <Button>Register</Button>
        <Button as={Link} to="/public/login">
          Cancel
        </Button>
      </Form>
    </FormProvider>
  );
}

export default RegisterScreen;
