import { useEffect } from "react";
import { Menu, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useLookup from "../consumer/LookupConsumer";
import { withRouter } from "react-router-dom";
import axios from "axios";
import useGridRows from "../hooks/useGridRows";
import { stripHtml } from "../utils";
import ActionScreen from "./ActionScreen";

function FocusedImprovementScreen({ row = [], match, history }) {
  const { rows, setRows } = useGridRows();
  const { lookups: earlyYearsOutcomeArea } = useLookup(6109);

  const { t } = useTranslation();

  useEffect(() => {
    if (match.params.id) {
      axios
        .get(`/api/early-assessment-recommend-list/${match.params.id}/outcome`)
        .then(({ data }) => setRows(data))
        .catch(console.log);
    }
  }, [match.params.id]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Focussed Improvement Plan")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        {earlyYearsOutcomeArea.map(foc => {
          const filteredRows = rows.filter(
            row => row.outcomeArea === foc.codeValue
          );
          if (filteredRows.length > 0) {
            return (
              <div className={"padding-bottom-10 grid-container-200"}>
                <b>
                  <p className={"custom-label-color padding-bottom-5"}>
                    {foc.codeDescription}
                  </p>
                </b>

                {filteredRows.map((row, index) => (
                  <>
                    <p style={{ marginTop: index !== 0 ? "10px" : 0 }}>
                      {" "}
                      <b>{t("Recommendation") + ":  "}</b>
                      <span>{stripHtml(row.recommendText)}</span>
                    </p>
                    <ActionScreen recommendationId={row.recommendId} />
                  </>
                ))}
              </div>
            );
          } else {
            return (
              <div className={"padding-bottom-10"}>
                <b>
                  <p className={"custom-label-color padding-bottom-5"}>
                    {foc.codeDescription}
                  </p>
                </b>

                <p>
                  <b>{t("Recommendation") + ":  "}</b>
                  <span>
                    {t(
                      "No Recommendations have been created for this statement."
                    )}
                  </span>
                </p>
              </div>
            );
          }
        })}
      </Segment>
    </>
  );
}

export default withRouter(FocusedImprovementScreen);
