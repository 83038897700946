import { useToasts } from "react-toast-notifications";

function withToast(Component) {
  return function WrappedComponent(props) {
    const addToast = useToasts();
    return <Component {...props} {...addToast} />;
  };
}

export default withToast;
