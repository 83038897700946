import { useState, useEffect } from "react";
import { Menu, Image, Dropdown, Icon, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ImpulseFinal from "../assets/images/impulse_final.png";
import axios from "axios";
import i18n, { DEFAULT_NS, DEFAULT_LANG } from "../translation/i18n";
import { useTranslation } from "react-i18next";
import ProfileModal from "./ProfileModal";
import AboutModal from "./AboutModal";
import SideBar from "./SideBar";
import UserNotifications from "./UserNotifications";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "./UserAccessComponent";
import { useSession } from "../consumer/SessionConsumer";

function TopMenu() {
  const [languages, setLanguages] = useState([]);
  const [langKey, setLangKey] = useState(DEFAULT_LANG);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);
  const [isAboutModalOpen, setAboutModalOpen] = useState(false);
  const { userDetails, person, selectedProvider } = useSession();
  const [notifications, setNotifications] = useState([]);
  const [notificationIcon, setNotificationIcon] = useState("fas fa-bell");
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const { t } = useTranslation();
  const { addToast } = useToasts();

  // Translation
  const updateUserLanguage = locale => {
    axios
      .put(`/api/user/translation/${locale}`)
      .then(() => setLangKey(locale))
      .catch(console.log);
  };

  const fetchLanguages = () => {
    axios
      .get("/api/translation-language")
      .then(({ data }) => setLanguages(data))
      .catch(console.log);
  };

  const fetchTranslations = locale => {
    axios
      .get(`/api/translations/${locale}`)
      .then(({ data }) => {
        const translations = data.reduce((acc, cur) => {
          acc[cur.code] = cur.translation;
          return acc;
        }, {});
        i18n.addResourceBundle(locale, DEFAULT_NS, translations);
        i18n.changeLanguage(locale);
      })
      .catch(console.log);
  };

  // Notifications
  const fetchNotifications = () => {
    axios
      .get(`/api/notification-list`)
      .then(({ data: rows }) => {
        let bellClassName = "far fa-bell";
        const unread = rows.some(row => !row.readDate && !row.readBy);
        if (unread) {
          bellClassName = "fas fa-bell";
        }
        setNotifications(rows);
        setNotificationIcon(bellClassName);
      })
      .catch(console.log);
  };

  const handleDelete = notificationId => {
    axios
      .delete(`/api/notification/${notificationId}`)
      .then(() => {
        addToast(`${t("Notification")} ${t("has been successfully removed")}`, {
          appearance: "success"
        });
        fetchNotifications();
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Notification")} ${t("has not been deleted")}`, {
          appearance: "error"
        });
      });
  };

  const handleRead = notification => {
    if (!notification.readDate && !notification.readBy) {
      axios
        .post(`/api/notification/${notification.id}/read`)
        .then(() => fetchNotifications())
        .catch(console.log);
    } else {
      axios
        .put(`/api/notification/${notification.id}/unread`)
        .then(() => fetchNotifications())
        .catch(console.log);
    }
  };

  const getUserName = () =>
    `${person.forename ? person.forename + " " : ""} ${
      person.surname ? person.surname : ""
    }`;

  useEffect(() => {
    fetchLanguages();
    fetchNotifications();
  }, []);

  useEffect(() => {
    fetchTranslations(langKey);
  }, [langKey]);

  useEffect(() => {
    if (userDetails.langKey) setLangKey(userDetails.langKey);
  }, [userDetails.langKey]);

  return (
    <>
      <Menu attached inverted className="main-navigation">
        <Menu.Item className="image-container">
          <Image src={ImpulseFinal} alt="IMPULSE" />
        </Menu.Item>
        <Menu.Menu position="right">
          {/* Selected Provider */}
          {Object.keys(selectedProvider).length > 0 && (
            <Menu.Item as={Link} to="/">
              <Icon name="cubes" />
              {selectedProvider.providerName} ({selectedProvider.providerId})
            </Menu.Item>
          )}

          {/* Notification */}
          <UserAccessComponent requires="USR_NOT">
            <Menu.Item onClick={() => setNotificationOpen(true)}>
              <Popup
                trigger={
                  <Icon style={{ marginRight: "0px" }}>
                    <i
                      style={{ fontSize: "16px" }}
                      className={notificationIcon}
                    />
                  </Icon>
                }
                content={t("Notifications")}
              />
            </Menu.Item>
          </UserAccessComponent>

          {/* Language Select */}
          <Dropdown icon="globe" item simple className="lang-dropdown">
            <Dropdown.Menu>
              {languages.map(({ locale, description }) => (
                <Dropdown.Item
                  key={locale}
                  onClick={() => updateUserLanguage(locale)}
                  text={description}
                  icon={langKey === locale ? "check" : undefined}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {/* User Dropdown */}
          <Dropdown
            text={getUserName()}
            item
            simple
            icon="caret down"
            className={"user-dropdown"}
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setProfileModalOpen(true)}>
                <Icon>
                  <i className="far fa-user-circle icon" />
                </Icon>{" "}
                {t("Profile")}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setAboutModalOpen(true)}>
                <Icon>
                  <i className="far fa-info-circle icon" />
                </Icon>{" "}
                {t("About")}
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/public/login"
                onClick={() => {
                  sessionStorage.removeItem("jwt");
                }}
                icon="log out"
                text={t("Logout")}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
      <ProfileModal
        isProfileModalOpen={isProfileModalOpen}
        closeProfileModal={() => setProfileModalOpen(false)}
        userDetails={userDetails}
        person={person}
      />
      <AboutModal
        isAboutModalOpen={isAboutModalOpen}
        closeAboutModal={() => setAboutModalOpen(false)}
      />

      <SideBar
        header={t("Notifications")}
        visible={isNotificationOpen}
        onClose={() => setNotificationOpen(false)}
        width={"540px"}
        hideBackButton
        background={"white"}
      >
        <UserNotifications
          handleClose={() => setNotificationOpen(false)}
          notifications={notifications}
          onDelete={handleDelete}
          onMarkAsRead={handleRead}
        />
      </SideBar>
    </>
  );
}

export default TopMenu;
