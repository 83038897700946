import { Search } from "@caci/react-form-fields";
import { Item } from "semantic-ui-react";
import { formatDate } from "../utils";
import { useTranslation } from "react-i18next";

function ProviderSearch({
  name,
  onResultSelect,
  onSearchChange,
  onValueChange,
  label,
  disabled
}) {
  const { t } = useTranslation();
  return (
    <Search
      fluid
      name={name}
      disabled={disabled}
      label={label}
      placeholder="Enter a Provider Name"
      resultFormatter={({ providerName, providerId }) =>
        `${providerName} (${providerId})`
      }
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
      onValueChange={onValueChange}
      selectOn="providerId"
      searchLength="2"
      resultRenderer={({ providerName, providerId, dateDeleted }) => (
        <Item key={providerId}>
          <Item.Content>
            <Item.Meta>
              <b>{t("Provider ID")}:</b> {providerId}
            </Item.Meta>
            <Item.Meta>
              <b>{t("Provider Name")}:</b> {providerName}
            </Item.Meta>
            <Item.Meta>
              <b>{t("Date Closed")}:</b> {formatDate(dateDeleted)}
            </Item.Meta>
          </Item.Content>
        </Item>
      )}
    />
  );
}

export default ProviderSearch;
