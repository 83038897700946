import { Container } from "semantic-ui-react";
import { useHistory, Switch, Route } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import Home from "./private/Home";
import TopMenu from "./components/TopMenu";
import LookupProvider from "./provider/LookupProvider";
import SessionProvider from "./provider/SessionProvider";
import Portal from "./private/Portal";
import { ActiveLicenceContext, UserAccessContext } from "./context";
import axios from "axios";
import LoadingMessage from "./components/LoadingMessage";
import validations from "./validations";

// Load custom validations
validations();

function App() {
  const history = useHistory();
  const [userGroupAccess, setUserGroupAccess] = useState([]);
  const [loadingUserGroupAccess, setLoadingUserGroupAccess] = useState(true);
  const [activeLicenceComponents, setActiveLicenceComponents] = useState([]);
  const [loadingLicenceComponents, setLoadingLicenceComponents] = useState(
    true
  );
  const [state, setState] = useState({ homeViewCount: 0 });

  const setViewCount = useCallback((viewCount) => setState({ ...state, ...viewCount }), []);

  const fetchUserGroupAccess = useCallback(() => {
    axios
      .get("/api/user-groups/user-group-access/current-user")
      .then(({ data }) => {
        setUserGroupAccess(data);
        setLoadingUserGroupAccess(false);
      })
      .catch(err => {
        console.log(err);
        setLoadingUserGroupAccess(false);
      });
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("jwt")) {
      history.push("/public/login");
    } else {
      fetchUserGroupAccess();
      axios
        .get("/api/component/active")
        .then(({ data }) => {
          setActiveLicenceComponents(data);
          setLoadingLicenceComponents(false);
        })
        .catch(err => {
          console.log(err);
          setLoadingLicenceComponents(false);
        });
    }
  }, [fetchUserGroupAccess, history]);

  if (loadingUserGroupAccess || loadingLicenceComponents) {
    return <LoadingMessage />;
  }

  return (
    <UserAccessContext.Provider value={{ userGroupAccess }}>
      <ActiveLicenceContext.Provider value={{ activeLicenceComponents }}>
        <SessionProvider>
          <Container fluid>
            <TopMenu />
          </Container>
          <Container fluid className={"home-container"}>
            <LookupProvider>
              <Switch>
                <Route path="/" exact>
                  <Home homeViewCount={state.homeViewCount} setViewCount={setViewCount} />
                </Route>
                <Route path="/portal/:providerId">
                  <Portal />
                </Route>
              </Switch>
            </LookupProvider>
          </Container>
        </SessionProvider>
      </ActiveLicenceContext.Provider>
    </UserAccessContext.Provider>
  );
}

export default App;
