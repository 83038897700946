import {Fragment, useEffect, useState} from "react";
import {DateFilter, DateFormatter, DefaultFilter, Grid as AssessmentGrid,} from "@caci/react-grid";
import axios from "axios";
import {Button, Form, Grid, Icon, Menu, Popup, Segment,} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import UserAccessComponent from "./UserAccessComponent";
import {withRouter} from "react-router-dom";

const transformData = (rows) => {
  return rows.map((row) => ({
    ...row,
    summary: row.summary ? row.summary.replace(/<\/?[^>]+(>|$)/g, "") : "",
  }));
};

function EarlyAssessmentVisitNote({history, location, match }) {
  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const { t } = useTranslation();

  const fetchEarlyAssessmentVisitNote = () => {
    axios
      .get(`/api/early-visit-note?assessmentId=${match.params.id}`)
      .then(({ data }) => {
        if (data.length > 0) setRows(data);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchEarlyAssessmentVisitNote();
  }, []);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Notes of Visits")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <Fragment>
            <div>
              <AssessmentGrid
                actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <UserAccessComponent requires="PPORT_PM_NOV_L_EDIT">
                      <Popup
                        content={t("Edit")}
                        trigger={
                          <Icon
                            circular
                            name="pencil"
                            className={"dark-blue-border"}
                            color="black"
                            onClick={() => {
                                history.push(
                                    `${location.pathname}/${row.id}`
                                );
                            }}
                          />
                        }
                      />
                    </UserAccessComponent>
                  </div>
                )}
                columns={[
                  {
                    key: "id",
                    name: t("ID"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "visitDate",
                    name: t("Visit Date"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DateFilter,
                    formatter: DateFormatter,
                  },
                  {
                    key: "startTime",
                    name: t("Start Time"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "finishTime",
                    name: t("Finish Time"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "orgPosName",
                    name: t("Completed By"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "summary",
                    name: t("Summary"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                ]}
                rows={transformData(rows)}
              />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Input label={t("ID")} value={editData.id} />
                    <Form.Input
                      label={t("Visit Date")}
                      value={editData.visitDate}
                    />
                    <Form.Input
                      label={t("Start Time")}
                      value={editData.startTime}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      label={t("Finish Time")}
                      value={editData.finishTime}
                    />

                    <Form.Input
                      label={t("Completed By")}
                      value={editData.orgPosName}
                    />

                    <Form.Input label={t("Summary")} value={editData.summary} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button
                onClick={() => {
                  setShowDetail(false);
                }}
                color="orange"
              >
                <Icon name="arrow circle left" />
                {t("Back")}
              </Button>
            </Form>
          </Fragment>
        )}
      </Segment>
    </>
  );
}

export default withRouter(EarlyAssessmentVisitNote);
