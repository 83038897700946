import { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import StaffDetails from "../components/StaffDetails";
import { useParams } from "react-router-dom";
import QualificationScreen from "../components/QualificationScreen";
import ExperienceScreen from "../components/ExperienceScreen";

function StaffDetailsScreen({
  setRefetch,
  refetch,
  row,
  setRow,
  setValidationFlag,
  urlSlug
}) {
  const { staffId } = useParams();

  const isNewRecord = () => staffId === "new";

  useEffect(() => {
    return () => {
      setRefetch(!refetch);
      setRow({});
    };
  }, []);

  return (
    <Grid stackable>
      <Grid.Row className={"padding-top-0"}>
        <Grid.Column width={4} className={"padding-left-0 padding-right-10"}>
          <StaffDetails row={row} setRow={setRow} />
        </Grid.Column>
        <Grid.Column width={12} className={"padding-left-0 padding-right-3"}>
          <QualificationScreen
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
            personId={row.personId}
            isNewRecord={isNewRecord()}
          />

          <ExperienceScreen
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
            personId={row.personId}
            isNewRecord={isNewRecord()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default StaffDetailsScreen;
