import { useState } from "react";
import axios from "axios";

export const usePostQuery = (url, options) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const query = (data) => {
    setLoading(true);
    axios
      .post(url, data)
      .then((response) => {
        setLoading(false);
        setResponse(response);
        !!options.onSuccess && options.onSuccess(response);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setError(error.response);
          !!options.onFailure && options.onFailure(error.response);
        } else if (error.request) {
          setError(error.request);
          !!options.onFailure && options.onFailure(error.request);
        } else {
          setError(error.message);
          !!options.onFailure && options.onFailure(error.message);
        }
        console.log(error);
      });
  };

  return [query, { loading, error, response }];
};
