import { clone } from "ramda";
export const SET_VALIDATION_FLAG = "SET_VALIDATION_FLAG";
export const SET_VALIDATION_FLAG_DATA = "SET_VALIDATION_FLAG_DATA";
export const SET_REJECTION_FLAG_LOADED = "SET_REJECTION_FLAG_LOADED";

export const initialState = {
  tabs: [
    {
      tabName: "Provider",
      userAccessCode: "PPORT_PM_PR",
      urlSlug: "provider-details",
      exclamation: "Y",
      icon: "fas fa-address-book",
    },
    {
      tabName: "Details",
      userAccessCode: "PPORT_PM_DT",
      urlSlug: "provider-details-ext",
      exclamation: "Y",
      icon: "fas fa-th",
    },
    {
      tabName: "Communications",
      userAccessCode: "PPORT_PM_CM",
      urlSlug: "provider-communications",
      exclamation: "N",
      icon: "fas fa-comments",
    },
    {
      tabName: "Hours, Charges & Places",
      userAccessCode: "PPORT_PM_HCP",
      urlSlug: "provider-hcp",
      exclamation: "Y",
      icon: "far fa-clock",
    },
    {
      tabName: "Staff",
      userAccessCode: "PPORT_PM_ST",
      urlSlug: "provider-staff",
      exclamation: "Y",
      icon: "fas fa-sitemap",
    },
    {
      tabName: "Assessment",
      userAccessCode: "PPORT_PM_AS",
      urlSlug: "provider-assessment",
      exclamation: "N",
      icon: "fal fa-clipboard-list-check",
    },
    {
      tabName: "FIP",
      userAccessCode: "PPORT_PM_FIP",
      urlSlug: "provider-fip",
      exclamation: "N",
      icon: "fas fa-map-marker-alt",
    },
    {
      tabName: "WellComm Assessment",
      userAccessCode: "PPORT_PM_WC",
      urlSlug: "provider-wellcomm",
      exclamation: "N",
      icon: "fas fa-traffic-light",
    },
    {
      tabName: "Timeline",
      userAccessCode: "PPORT_PM_TMLN",
      urlSlug: "provider-timeline",
      exclamation: "N",
      icon: "fas fa-timeline",
    },
    {
      tabName: "Funding",
      userAccessCode: "PPORT_EYF_FUND",
      urlSlug: "provider-funding",
      exclamation: "N",
      icon: "fas fa-pound-sign",
    },
  ],
  entriesRejectedTab: {
    loaded: false,
  },
  validationFlagObj: {},
};

const setProviderValidationFlag = (state, action) => {
  const newState = clone(state);
  const { entriesRejectedTab: t, validationFlagObj: v } = newState;
  t[action.urlSlug] = action.payload;
  if (action.initiateValidationFlagObj) {
    // load validation Flag objects at the beginning
    v[action.urlSlug] = {};
  }
  return newState;
};

const setProviderValidationFlagData = (state, action) => {
  const { validationFlagObj: v, entriesRejectedTab: e } = state;
  const {
    urlSlug,
    payload: { name, value },
  } = action;
  v[urlSlug][name] = value;
  const validateEntriesInFrontend = Object.values(v[urlSlug]).some((t) => t);
  if (e[urlSlug] === validateEntriesInFrontend) return state;
  return {
    ...setProviderValidationFlag(state, {
      urlSlug,
      payload: validateEntriesInFrontend,
    }),
    validationFlagObj: v,
  };
};

function providerTabsReducer(state, action) {
  switch (action.type) {
    case SET_VALIDATION_FLAG_DATA:
      return setProviderValidationFlagData(state, action);
    case SET_VALIDATION_FLAG:
      return setProviderValidationFlag(state, {
        ...action,
        initiateValidationFlagObj: true,
      });
    case SET_REJECTION_FLAG_LOADED:
      return setProviderValidationFlag(state, {
        urlSlug: "loaded",
        payload: true,
      });
    default:
      return state;
  }
}

export default providerTabsReducer;
