import React, { Component } from "react";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import GenericModal from "./GenericModal";
import MatrixSlider from "./MatrixSlider";
import Translate from "../translation/Translate";

class ProfileMatrixDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadMode: false,
      note: this.props.note,
      isCurrentBand: 0,
      bandColour: this.props.threadMinColorValue,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
    this.setSliderColor = this.setSliderColor.bind(this);
    this.setBand = this.setBand.bind(this);
    this.inputRef = React.createRef();
  }

  async componentDidMount() {
    this.setBand(this.props.isCurrentBand);
  }

  setBand(isCurrentBand) {
    this.setState(
      { isCurrentBand: isCurrentBand != null ? isCurrentBand : 0 },
      () =>
        this.props.onChange(
          "isCurrentBand",
          this.state.isCurrentBand,
          this.props.rowIndex
        )
    );

    this.setSliderColor(isCurrentBand);
  }

  handleOpen() {
    this.setState({ isModalOpen: true });
  }

  handleClose() {
    this.setState({ isModalOpen: false });
  }

  sliderChange(e) {
    const { threadType, threadCode } = this.props;
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChange(
      e.target.name,
      parseInt(e.target.value),
      this.props.rowIndex
    );
    this.setSliderColor(parseInt(e.target.value));
  }

  setSliderColor(value) {
    if (value === this.props.minBand) {
      this.setState({ bandColour: this.props.threadMinColorValue });
    } else if (value === this.props.maxBand) {
      this.setState({ bandColour: this.props.threadMaxColorValue });
    } else if (value) {
      this.setState({ bandColour: "orange" });
    }
  }
  handleChange(event) {
    this.setState({ note: event.target.value });
  }

  render() {
    return (
      <Grid stackable>
        <Grid.Row className={"padding-top-0 padding-bottom-0"}>
          <Grid style={{ width: "100%" }}>
            <Grid.Row className={"padding-top-0 padding-bottom-0"}>
              <label
                className={"matrix-label"}
                style={{ padding: "0 0.9375rem 0.45rem" }}
              >
                <Translate text={this.props.threadDesc} />
              </label>
            </Grid.Row>
          </Grid>

          <Grid style={{ width: "100%" }}>
            <Grid.Row className={"padding-top-0 padding-bottom-0"}>
              <MatrixSlider
                minBand={this.props.minBand}
                maxBand={this.props.maxBand}
                band={this.state.isCurrentBand}
                onChange={this.sliderChange}
                sliderColor={this.state.bandColour}
                inputRef={this.inputRef}
                width={"90%"}
                name="isCurrentBand"
              />
              <GenericModal
                open={this.state.isModalOpen}
                onClose={this.handleClose}
                size="tiny"
                buttonContent={
                  <Icon
                    bordered
                    link
                    color="black"
                    style={{ marginLeft: "5px" }}
                    className={"white-border"}
                    onClick={() => {
                      this.handleOpen();
                    }}
                    name={
                      this.props.note ? "sticky note" : "sticky note outline"
                    }
                  />
                }
                toolTip={<Translate text="Notes" />}
                HeaderTitle={<Translate text="Notes" />}
                content={
                  <div>
                    <Form>
                      <Form.TextArea
                        value={this.state.note}
                        name="note"
                        onChange={this.handleChange}
                      />
                      {this.state.note && 4000 < this.state.note.length && (
                        <p style={{ color: "red" }}>
                          {"Character limit exceeded, " +
                            this.state.note.length +
                            " of 4000."}
                        </p>
                      )}
                    </Form>
                  </div>
                }
                footer={
                  <React.Fragment>
                    <div style={{ textAlign: "left" }}>
                      <Button
                        onClick={() => {
                          this.handleClose();
                          this.setState({ note: this.props.note });
                        }}
                        className={"orange"}
                      >
                        <Icon name="arrow circle left" />
                        {<Translate text="Back" />}
                      </Button>
                      <Button
                        onClick={() => {
                          this.handleClose();
                          this.props.onChange(
                            "note",
                            this.state.note,
                            this.props.rowIndex
                          );
                        }}
                        className={"green"}
                        disabled={
                          this.state.note && 4000 < this.state.note.length
                        }
                      >
                        <Icon name="check circle" />{" "}
                        {<Translate text="Submit" />}
                      </Button>
                    </div>
                  </React.Fragment>
                }
              />
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "0.5em",
                  backgroundColor: this.state.bandColour,
                  borderRadius: "50%",
                }}
              />
            </Grid.Row>
          </Grid>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ProfileMatrixDetails;
