import {Button, Form, Grid, Icon, Menu, Segment, TextArea} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import useLookup from "../consumer/LookupConsumer";
import { formatDate, lookupCodeToValue } from "../utils";
import React from "react";
import moment from "moment";

function VisitDetails({ history, rows }) {
    const { lookups: inspectionStatus } = useLookup(6092);
    const { t } = useTranslation();
    return (
        <>
            <Segment attached="bottom">
                <Menu attached="top" className={"border-bottom"}>
                    <Menu.Item header className={"active"}>
                        {t("Visit Details")}
                    </Menu.Item>
                </Menu>

                <Form>
                    <Grid stackable>
                        <Grid.Row className={"padding-top-0"}>
                            <Grid.Column
                                width={16}
                                className={"padding-left-0 padding-right-10"}
                            >
                                <Form.Input
                                    label={t("Visit ID")}
                                    readOnly
                                    name="id"
                                    value={rows.id}
                                />
                                <Form.Input
                                    label={t("Date Scheduled")}
                                    readOnly
                                    name="plannedVistDate"
                                    value={rows.plannedVistDate ? formatDate(rows.plannedVistDate) : ""}
                                />
                                <Form.Input
                                    label={t("Visit Date")}
                                    readOnly
                                    name="visitDate"
                                    value={rows.visitDate ? formatDate(rows.visitDate) : ""}
                                />
                                <Form.Input
                                    label={t("Completed By")}
                                    readOnly
                                    name="orgPosId"
                                    value={rows.orgPosId}
                                />
                                <Form.Input
                                    label={t("Start Time")}
                                    readOnly
                                    type="time"
                                    name="startTime"
                                    value={moment(rows.startTime).format("hh:mm")}
                                />
                                <Form.Input
                                    label={t("Finish Time")}
                                    readOnly
                                    type="time"
                                    value={moment(rows.finishTime).format("hh:mm")}
                                />
                                <Form.Input
                                    label={t("Current Inspection Status")}
                                    readOnly
                                    value={lookupCodeToValue(
                                        inspectionStatus,
                                        rows.inspStatus
                                    )}
                                    name="inspStatus"
                                />

                                <Form.TextArea
                                    label={t("Review/Impact of Ofsted actions/SEF/FIP")}
                                    readOnly
                                    value={rows.impact ? rows.impact.replace(/<\/?[^>]+(>|$)/g, "") : ""}
                                    name="impact"
                                />
                                <Form.TextArea
                                    label={t("Summary evaluation of visit")}
                                    readOnly
                                    value={rows.summary ? rows.summary.replace(/<\/?[^>]+(>|$)/g, "") : ""}
                                    name="summary"
                                />
                                <Segment className={'child-bg'}>
                                    <div align="center" style={{ marginBottom: '1rem' }}>
                                        <b className={'text-white-color'}>{t('Tags')}</b>
                                    </div>
                                    <TextArea
                                        name="inspCycle"
                                        value={rows.supportCategory}
                                    />
                                </Segment>
                                <Form.Input
                                    label={t("Next Visit Date")}
                                    readOnly
                                    value={rows.nextVisitDate ? formatDate(rows.nextVisitDate) : null}
                                    name="nextVisitDate"
                                />
                                <Form.Input
                                    label={t("Focus")}
                                    readOnly
                                    value={rows.focus ? rows.focus.replace(/<\/?[^>]+(>|$)/g, "") : ""}
                                    name="focus"
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                <br />
                <Button
                    onClick={() => {
                        history.goBack();
                    }}
                    className={"orange"}
                >
                    <Icon name="arrow circle left" />
                    {t("Back")}
                </Button>
            </Segment>
        </>
    );
}

export default withRouter(VisitDetails);
