import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Image,
  Modal,
  Segment
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ImpulseFinal from "../assets/images/impulse_final.png";
import { getBrowserInfo } from "../utils";
import axios from "axios";
import moment from "moment";

function AboutModal({ isAboutModalOpen, closeAboutModal }) {
  const [showDetail, setShowDetail] = useState(false);
  const [about, setAbout] = useState({});
  const [provPortalInfo, setProvPortalInfo] = useState({});

  const fetchAbout = () => {
    axios
      .get("/api/about")
      .then(({ data: details }) => setAbout(details))
      .catch(console.log);
  };

  const fetchProviderPortalInfo = () => {
    axios
        .get("/actuator/info")
        .then(({ data: { build: info } }) => setProvPortalInfo(info))
        .catch(console.log);
  };

  useEffect(() => {
    fetchAbout();
    fetchProviderPortalInfo();
  }, []);

  const { t } = useTranslation();
  return (
    <div>
      <Modal
        className="dark-modal"
        open={isAboutModalOpen}
        onClose={() => setShowDetail(false)}
        size="tiny"
        centered={false}
      >
        <Modal.Content className={"about-modal-content"}>
          <Grid centered>
            <Form>
              <Image
                centered
                src={ImpulseFinal}
                alt="IMPULSE"
                style={{
                  width: "90px",
                  margin: "15px auto"
                }}
              />
              <Form.Field>
                <label>{t("You're on database version")} {about.appVersion}</label>
              </Form.Field>
              <Form.Field>
                <label>
                  {t("You're on Impulse Provider Portal version")}{" "}
                  {provPortalInfo?.version}
                  <br />
                  {t("Built on")}{" "}
                  {moment(provPortalInfo?.time).format('DD/MM/YYYY')}
                </label>
              </Form.Field>
              <Form.Field>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowDetail(true)}
                >
                  {t("Additional Information")}
                </a>
              </Form.Field>
              {showDetail && (
                <Segment>
                  <div style={{ textAlign: "left" }}>
                    <Form.Field>
                      <label>{t("DB version")}: {about.dbDetails}</label>
                    </Form.Field>
                    <Form.Field>
                      <label>
                        {t("Browser")}: {getBrowserInfo()}
                      </label>
                    </Form.Field>
                  </div>
                </Segment>
              )}
              <Button
                className="orange"
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
                onClick={() => {
                  closeAboutModal();
                  setShowDetail(false);
                }}
              >
                <Icon name="times circle" />
                {t("Back")}
              </Button>
            </Form>
          </Grid>
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default AboutModal;
