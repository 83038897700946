import { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import ProviderRegNameAddressDetails from "../components/ProviderRegNameAddressDetails";
import ProviderDetailsScreen from "../components/ProviderDetailsScreen";
import ProviderContactDetails from "../components/ProviderContactDetails";
import AdditionalContactsList from "../components/AdditionalContactsList";
import useGridRows from "../hooks/useGridRows";
import axios from "axios";

function ProviderScreen({ setValidationFlag, urlSlug }) {
  // set hideRegCon to True and wait until the HIDE_REG_CON API loads
  const [hideRegCon, setHideRegCon] = useState(true);
  const { row, setRow } = useGridRows();

  useEffect(() => {
    axios
      .get("/api/impulse-config/HIDE_REG_CON")
      .then(response => setHideRegCon(response.data.active === "Y"))
      .catch(console.log);
  }, []);

  return (
    <>
      <Grid stackable>
        <Grid.Row className={"padding-top-0 padding-bottom-0"}>
          <Grid.Column width={4} className={"padding-left-0 padding-right-10"}>
            <ProviderDetailsScreen
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>

          <Grid.Column width={12} className={"padding-left-0 padding-right-10"}>
            {!hideRegCon && (
              <ProviderRegNameAddressDetails
                setValidationFlag={setValidationFlag}
                urlSlug={urlSlug}
                row={row}
                setRow={setRow}
              />
            )}
            <ProviderContactDetails
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
              row={row}
              setRow={setRow}
            />
            <AdditionalContactsList
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default ProviderScreen;
