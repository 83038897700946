import { Grid, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSession } from "../consumer/SessionConsumer";
import { lookupCodeToValue } from "../utils";
import useLookup from "../consumer/LookupConsumer";

const valueStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden"
};

function ProviderDetails() {
  const { t } = useTranslation();
  const { selectedProvider: provider } = useSession();
  const { lookups: providerTypes } = useLookup(912);
  const { lookups: registrationStatus } = useLookup(913);

  const IDENTIFIERS = [
    { codeDescription: t("Establishment"), codeValue: "E" },
    { codeDescription: t("Agency"), codeValue: "A" }
  ];

  return (
    <Grid stretched className={"no-border inner-grid"}>
      <Grid.Column
        width={8}
        verticalAlign="middle"
        className="padding-bottom-14"
      >
        <List size="small">
          <List.Item>
            <List.Content>
              <List.Header>{t("Impulse ID")}</List.Header>
              <List.Description style={valueStyle}>
                {provider.providerId || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{t("Record Type")}</List.Header>
              <List.Description style={valueStyle}>
                {lookupCodeToValue(IDENTIFIERS, provider.identifierType) || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{t("Known As")}</List.Header>
              <List.Description style={valueStyle}>
                {provider.knownAs || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{t("Provider Type")}</List.Header>
              <List.Description style={valueStyle}>
                {lookupCodeToValue(providerTypes, provider.providerType) || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Grid.Column>

      <Grid.Column width={8} className="padding-bottom-14">
        <List size="small">
          <List.Item>
            <List.Content>
              <List.Header>{t("Name")}</List.Header>
              <List.Description style={valueStyle}>
                {provider.providerName || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{t("Ofsted URN")}</List.Header>
              <List.Description style={valueStyle}>
                {provider.ofstedUrn || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{t("Website")}</List.Header>
              <List.Description style={valueStyle}>
                {provider.websiteAdd || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{t("Registration Status")}</List.Header>
              <List.Description style={valueStyle}>
                {lookupCodeToValue(registrationStatus, provider.registrationStatus) || <br />}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Grid.Column>
    </Grid>
  );
}

export default ProviderDetails;
