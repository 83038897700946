import {Button, Form, Grid, Icon, Menu, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import useLookup from "../consumer/LookupConsumer";
import {lookupCodeToValue} from "../utils";
import moment from "moment";

function EarlyAssessmentDetails({ match, history, location, rows }) {
  const { lookups: inspectionStatus } = useLookup(6092);
  const { lookups: categoryOfSupport } = useLookup(6093);
  const { lookups: inspectionCycle } = useLookup(6094);
  const { lookups: earlyYearsQiOffer } = useLookup(6095);
  const { lookups: frequencyOfContacts } = useLookup(6096);
  const { t } = useTranslation();

  return (
      <>
        <Menu attached="top" className={"border-bottom"}>
          <Menu.Item header className={"active"}>
            {t("Assessment Details")}
          </Menu.Item>
        </Menu>
        <Segment attached="bottom">
          <Form>
            <Grid stackable>
              <Grid.Row className={"padding-top-0"}>
                <Grid.Column
                    width={16}
                    className={"padding-left-0 padding-right-10"}
                >
                  <Form.Input
                      label={t("Assessment ID")}
                      readOnly
                      name="id"
                      value={rows.id}
                  />
                  <Form.Input
                      label={t("Registration Date")}
                      readOnly
                      name="regDate"
                      value={rows.regDate ? moment(rows.regDate).format("DD/MM/YYYY") : ""}
                  />
                  <Form.Input
                      label={t("Assessment Date")}
                      readOnly
                      name="assessmentDate"
                      value={rows.assessmentDate ? moment(rows.assessmentDate).format("DD/MM/YYYY") : ""}
                  />
                  <Form.Input
                      label={t("Inspection Date")}
                      readOnly
                      name="inspDate"
                      value={rows.inspDate ? moment(rows.inspDate).format("DD/MM/YYYY") : ""}
                  />
                  <Form.Input
                      label={t("Inspection Status")}
                      readOnly
                      value={lookupCodeToValue(inspectionStatus, rows.inspStatus)}
                      name="inspStatus"
                  />
                  <Form.Input
                      label={t("Category of Support")}
                      readOnly
                      value={lookupCodeToValue(
                          categoryOfSupport,
                          rows.supportCategory
                      )}
                      name="supportCategory"
                  />

                  <Form.Input
                      label={t("Inspection Cycle")}
                      readOnly
                      value={lookupCodeToValue(inspectionCycle, rows.inspCycle)}
                      name="inspCycle"
                  />
                  <Form.Input
                      label={t("Agreement Date")}
                      readOnly
                      value={rows.agreementDate ? moment(rows.agreementDate).format("DD/MM/YYYY") : ""}
                      name="agreementDate"
                  />
                  <Form.Input
                      label={t("Early Years QI Offer")}
                      readOnly
                      value={lookupCodeToValue(earlyYearsQiOffer, rows.qiOffer)}
                      name="qiOffer"
                  />

                  <Form.Input
                      label={t("Frequency of Contact")}
                      readOnly
                      value={lookupCodeToValue(
                          frequencyOfContacts,
                          rows.contactFreq
                      )}
                      name="contactFreq"
                  />
                  <Form.Input
                      label={t("Shared with Setting Date")}
                      readOnly
                      value={rows.showSettingDate ? moment(rows.showSettingDate).format("DD/MM/YYYY") : ""}
                      name="showSettingDate"
                  />
                  <Form.Input
                      label={t("Progress Review Date")}
                      readOnly
                      value={rows.progressReviewDate ? moment(rows.progressReviewDate).format("DD/MM/YYYY") : ""}
                      name={"progressReviewDate"}
                  />
                  <Form.Input
                      label={t("Exit Strategy Completed")}
                      readOnly
                      value={rows.exitStrategyDate ? moment(rows.exitStrategyDate).format("DD/MM/YYYY") : ""}
                      name="exitStrategyDate"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Button
              onClick={() => {
                history.goBack();
              }}
              className={"orange"}
          >
            <Icon name="arrow circle left" />
            {t("Back")}
          </Button>
        </Segment>
      </>
  );
}

export default withRouter(EarlyAssessmentDetails);
