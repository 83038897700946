import { useState, useEffect } from "react";
import { Button, Form, Grid, Icon, Menu, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { Input } from "@caci/react-form-fields";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import { setValueHelper } from "../utils";

function NewAssessments({ row, wellcomAssessId, isNewRecord, dateCompleted }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [saving, setSaving] = useState(false);

  const schema = yup.object({
    newAmber: yup
      .string()
      .isNumeric(t("This value must be numeric"))
      .nullable(),
    newGreen: yup
      .string()
      .isNumeric(t("This value must be numeric"))
      .nullable(),
    newRed: yup
      .string()
      .isNumeric(t("This value must be numeric"))
      .nullable()
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ newAmber, newGreen, newRed }) => {
    const putData = [
      { op: "add", path: "/newAmber", value: newAmber },
      { op: "add", path: "/newGreen", value: newGreen },
      { op: "add", path: "/newRed", value: newRed }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    };

    setSaving(true);

    axios
      .put(`/api/wellcomm-assessment/${wellcomAssessId}`, putData, putObject)
      .then(({ data }) => {
        addToast(
          `${t("WellComm Assessment")} ${t("has been successfully updated")}`,
          {
            appearance: "success"
          }
        );
        setSaving(false);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("WellComm Assessment")} ${t("has not been updated")}`, {
          appearance: "error"
        });
        setSaving(false);
      });
  };

  useEffect(() => {
    setValueHelper(row, methods.setValue);
  }, [row]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className="active">
          {t("New Assessments")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={4} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>
                      {t("New child first assessment - Red")}
                    </b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input name="newRed" />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={4} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>
                      {t("New child first assessment - Amber")}
                    </b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input name="newAmber" />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={4} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>
                      {t("New child first assessment - Green")}
                    </b>
                  </p>
                </Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input name="newGreen" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <UserAccessComponent requires="PPORT_PM_WC_D_SUB">
              <Button
                className={"green"}
                type="submit"
                disabled={saving || isNewRecord || !!dateCompleted}
                onClick={methods.handleSubmit(onSubmit)}
              >
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Segment>
    </>
  );
}

export default NewAssessments;
