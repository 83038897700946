import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Icon,
  Input as SemanticInput,
  Menu,
  Segment
} from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  lookupCodeToValue,
  lookupToOptionsTranslation,
  setValueHelper
} from "../utils";
import { Dropdown, Input } from "@caci/react-form-fields";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "./UserAccessComponent";
import { useHistory, useParams } from "react-router-dom";
import { useSession } from "../consumer/SessionConsumer";
import { isEmpty } from "ramda";
import { wellcommAssessmentRowMapper } from "../private/WellcommAssessmentScreen";
import useLookup from "../consumer/LookupConsumer";
import useGridRows from "../hooks/useGridRows";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

function WellcommAssessmentDetails({
  row = {},
  setRow,
  isNewRecord,
  rows = []
}) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [saving, setSaving] = useState(false);
  const [completeActionProcess, setCompleteActionProcess] = useState(false);
  const history = useHistory();
  const { selectedProvider: provider, userDetails } = useSession();
  const { wellcomAssessId } = useParams();
  const { lookups: termLookups } = useLookup(6113);
  const { refetch, setRefetch } = useGridRows();

  const schema = yup.object({
    termCode: yup
      .string()
      .required(t("Please enter a value"))
      .unique({
        existing: rows.map(({ termCode }) => termCode),
        message: t("An Assessment already exists for this term")
      })
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ id, termCode, personId, dateCompleted }) => {
    const postData = {
      termCode,
      providerId: provider.providerId
    };

    const putData = [
      { op: "add", path: "/personId", value: personId },
      { op: "add", path: "/dateCompleted", value: dateCompleted }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    };

    setSaving(true);

    if (id) {
      axios
        .put(`/api/wellcomm-assessment/${id}`, putData, putObject)
        .then(({ data }) => {
          addToast(
            `${t("WellComm Assessment")} ${t("has been successfully updated")}`,
            {
              appearance: "success"
            }
          );
          setRow(data);
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(`${t("WellComm Assessment")} ${t("has not been updated")}`, {
            appearance: "error"
          });
          setSaving(false);
        });
    } else {
      axios
        .post(`/api/wellcomm-assessment`, postData)
        .then(({ data }) => {
          addToast(
            `${t("WellComm Assessment")} ${t("has been successfully created")}`,
            {
              appearance: "success"
            }
          );
          methods.setValue("id", data.id);
          setRow(wellcommAssessmentRowMapper(data));
          setSaving(false);
          history.push(
            `/portal/${provider.providerId}/provider/provider-wellcomm/${data.id}`
          );
        })
        .catch(error => {
          console.log(error);
          addToast(`${t("WellComm Assessment")} ${t("has not been created")}`, {
            appearance: "error"
          });
          setSaving(false);
        });
    }
  };

  const handleCompleteAction = () => {
    const { id } = row;

    const putData = [
      { op: "add", path: "/personId", value: userDetails.personId },
      { op: "add", path: "/dateCompleted", value: moment().toJSON() }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    };

    setCompleteActionProcess(true);

    axios
      .put(`/api/wellcomm-assessment/${id}`, putData, putObject)
      .then(({ data }) => {
        setRow(wellcommAssessmentRowMapper(data));
        addToast(`${t("WellComm Assessment")} ${t("has been updated")}`, {
          appearance: "success"
        });
        setRefetch(true);
        setCompleteActionProcess(false);
      })
      .catch(err => {
        addToast(`${t("WellComm Assessment")} ${t("has not been updated")}`, {
          appearance: "error"
        });
        setCompleteActionProcess(false);
      });
  };

  useEffect(() => {
    setValueHelper(row, methods.setValue);
  }, [row]);

  useEffect(() => {
    if ((isEmpty(row) && !isNewRecord) || refetch) {
      axios
        .get(`/api/wellcomm-assessment/${wellcomAssessId}`)
        .then(({ data }) => {
          setRow(wellcommAssessmentRowMapper(data));
          setRefetch(false);
        })
        .catch(err => {
          console.log(err);
          setRefetch(false);
        });
    }
  }, [wellcomAssessId, refetch]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("WellComm Assessment Details")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form>
            <Input label={t("Assessment ID")} name="id" readOnly />
            {!isNewRecord ? (
              <Form.Field>
                <label>{t("Term")}</label>
                <SemanticInput
                  fluid
                  value={lookupCodeToValue(termLookups, row.termCode)}
                  readOnly
                />
              </Form.Field>
            ) : (
              <Dropdown
                label={t("Term")}
                name="termCode"
                options={termLookups.map(lookupToOptionsTranslation(t))}
              />
            )}
            <Input label={t("Completed By")} name="completedBy" readOnly />
            <Input
              label={t("Date Completed")}
              name="dateCompleted"
              type="date"
              readOnly
            />
            <UserAccessComponent requires="PPORT_PM_WC_D_COM">
              <div style={{ marginBottom: "10px" }}>
                <Button
                  onClick={() => handleCompleteAction()}
                  color="light-blue"
                  disabled={
                    !row.id ||
                    row.dateCompleted ||
                    completeActionProcess ||
                    refetch
                  }
                >
                  <Icon name="check circle" />
                  {t("Complete Assessment")}
                </Button>
              </div>
            </UserAccessComponent>
            <Button
              onClick={() =>
                history.push(
                  `/portal/${provider.providerId}/provider/provider-wellcomm`
                )
              }
              color="orange"
            >
              <Icon name="arrow circle left" />
              {t("Back")}
            </Button>
            {isNewRecord && (
              <UserAccessComponent requires="PPORT_PM_WC_D_SUB">
                <Button
                  className={"green"}
                  type="submit"
                  disabled={saving}
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  <Icon name="check circle" />
                  {t("Submit")}
                </Button>
              </UserAccessComponent>
            )}
          </Form>
        </FormProvider>
      </Segment>
    </>
  );
}

export default WellcommAssessmentDetails;
