import UserAccessComponent from "../components/UserAccessComponent";
import { Button, Icon, Popup, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as StaffGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import { useHistory, useLocation } from "react-router-dom";
import RejectCommentsModal from "../components/RejectCommentsModal";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import { validationFlagsList } from "./StaffScreen";
import { getValidationFlag } from "../utils";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

function StaffGridScreen({ rows, setRow, refetch, setRefetch }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { addToast } = useToasts();

  const yesNoValues = [
    { codeValue: "Y", codeDescription: t("Yes") },
    { codeValue: "N", codeDescription: t("No") }
  ];

  const handleDelete = row => {
    const data = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(row.validationFlag, "D")
      }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    };

    axios
      .put(`/api/pport-early-staff/${row.id}`, data, putObject)
      .then(() => {
        addToast(`${t("Staff")} ${t("has been successfully removed")}`, {
          appearance: "success"
        });
        if (!!setRefetch) setRefetch(!refetch);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Staff")} ${t("has not been removed")}`, {
          appearance: "error"
        });
      });
  };

  return (
    <Segment className="grid-container-550">
      <StaffGrid
        actionsCell={({ row }) => (
          <div style={{ textAlign: "center" }}>
            <UserAccessComponent requires="PPORT_PM_ST_L_EDIT">
              <Popup
                content={t("Edit")}
                trigger={
                  <Icon
                    circular
                    name="pencil"
                    className={"dark-blue-border"}
                    color="black"
                    onClick={() => {
                      setRow(row);
                      history.push(`${pathname}/${row.id}`);
                    }}
                  />
                }
              />
            </UserAccessComponent>
            <UserAccessComponent requires="PPORT_PM_ST_L_EDIT">
              <RejectCommentsModal
                rejectComments={row.rejectComments}
                rejectedDate={row.rejectedDate}
                rejectedBy={row.rejectedBy}
                disabled={!validationFlagsList.includes(row.validationFlag)}
                circular
              />
            </UserAccessComponent>

            <UserAccessComponent requires="PPORT_PM_ST_L_DEL">
              <DeleteConfirmationModal
                message={t(
                  "Are you sure you want to delete this Staff record?"
                )}
                onDelete={() => handleDelete(row)}
                iconName="trash alternate outline"
                color="red-border"
                circular
              />
            </UserAccessComponent>
          </div>
        )}
        columns={[
          {
            key: "personName",
            name: t("Name"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "startDate",
            name: t("Start Date"),
            filterable: true,
            sortable: true,
            formatter: DateFormatter,
            filterRenderer: DateFilter
          },
          {
            key: "endDate",
            name: t("End Date"),
            filterable: true,
            sortable: true,
            formatter: DateFormatter,
            filterRenderer: DateFilter
          },
          {
            key: "hourlyRate",
            name: t("Hourly Rate"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "hoursPerWeek",
            name: t("Hours Per Week"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "hoursPerYear",
            name: t("Hours Per Year"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "agency",
            name: t("Agency"),
            filterable: true,
            sortable: true,
            lookups: yesNoValues,
            formatter: LookupFormatter,
            filterRenderer: LookupFilter
          },
          {
            key: "managementRole",
            name: t("Management Role"),
            filterable: true,
            sortable: true,
            lookups: yesNoValues,
            formatter: LookupFormatter,
            filterRenderer: LookupFilter
          },
          {
            key: "workWith34Olds",
            name: t("Teaching Staff Working with 2/3/4 Year Olds"),
            filterable: true,
            sortable: true,
            lookups: yesNoValues,
            formatter: LookupFormatter,
            filterRenderer: LookupFilter
          }
        ]}
        rows={rows}
      />
      <div className={"gap-btw-grid-buttons"}>
        <UserAccessComponent requires="PPORT_PM_ST_L_ADD">
          <Button
            onClick={() => history.push(`${pathname}/new`)}
            className={"purple"}
          >
            <Icon>
              <i className="fas fa-sitemap" />
            </Icon>
            {t("New Staff")}
          </Button>
        </UserAccessComponent>
      </div>
    </Segment>
  );
}

export default StaffGridScreen;
