import { useContext, useEffect } from "react";
import { LookupContext } from "../context";
import { getLoadingKey } from "../provider/LookupProvider";

function useLookup(codeType) {
  const {
    [codeType]: lookups,
    [getLoadingKey(codeType)]: loading,
    fetchLookup
  } = useContext(LookupContext);

  useEffect(() => {
    fetchLookup(codeType);
  }, [codeType]);

  return { lookups: lookups || [], loading };
}

export default useLookup;
