import { Grid, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const valueStyle = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden"
};

const Address = ({ houseNo, street, district, town, postcode, t, county }) => (
  <>
    {(houseNo || street) && (
      <List.Item>
        <List.Content>
          <List.Description style={valueStyle}>
            {t(houseNo)} {t(street)}
          </List.Description>
        </List.Content>
      </List.Item>
    )}
    {district && (
      <List.Item>
        <List.Content>
          <List.Description style={valueStyle}>{t(district)}</List.Description>
        </List.Content>
      </List.Item>
    )}
    {town && (
      <List.Item>
        <List.Content>
          <List.Description style={valueStyle}>{t(town)}</List.Description>
        </List.Content>
      </List.Item>
    )}
    {county && (
      <List.Item>
        <List.Content>
          <List.Description style={valueStyle}>{t(county)}</List.Description>
        </List.Content>
      </List.Item>
    )}
    {postcode && (
      <List.Item>
        <List.Content>
          <List.Description style={valueStyle}>{t(postcode)}</List.Description>
        </List.Content>
      </List.Item>
    )}
  </>
);

const ProviderHeaderAddress = ({
  t,
  houseNo,
  street,
  district,
  town,
  postcode,
  county,
  name,
  icon,
  noAddressDisplay,
  noPersonDisplay
}) => (
  <Grid stretched className={"no-border"}>
    <Grid.Column
      width={2}
      verticalAlign="middle"
      textAlign="center"
      className="padding-bottom-14"
    >
      {icon}
    </Grid.Column>

    <Grid.Column
      width={14}
      verticalAlign="middle"
      className="padding-bottom-14"
    >
      <List size="small">
        {name ? (
          <List.Item>
            <List.Content>
              <List.Description style={valueStyle}>{name}</List.Description>
            </List.Content>
          </List.Item>
        ) : (
          <List.Item>
            <List.Content>
              <List.Header>{t(noPersonDisplay)}</List.Header>
            </List.Content>
          </List.Item>
        )}
        {houseNo || district || postcode || street || town || county ? (
          <Address
            houseNo={houseNo}
            district={district}
            postcode={postcode}
            street={street}
            town={town}
            t={t}
            county={county}
          />
        ) : (
          <List.Item>
            <List.Content>
              <List.Header style={valueStyle}>
                {t(noAddressDisplay)}
              </List.Header>
            </List.Content>
          </List.Item>
        )}
      </List>
    </Grid.Column>
  </Grid>
);

export default ProviderHeaderAddress;
