import { createContext } from 'react';

// Lookups
export const LookupContext = createContext({});

// Session
export const SessionContext = createContext({});

// User Access
export const UserAccessContext = createContext({});

// Active Licence Component
export const ActiveLicenceContext = createContext({});