import { useEffect, useState } from "react";
import { Modal, Form, Button, Icon, Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  addZeroes,
  lookupToOptionsTranslation,
  setValueHelper,
} from "../utils";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UserAccessComponent from "./UserAccessComponent";
import { Dropdown, Input, TextArea, Checkbox } from "@caci/react-form-fields";
import styled from "styled-components";
import axios from "axios";
import useLookup from "../consumer/LookupConsumer";
import { useSession } from "../consumer/SessionConsumer";
import { useToasts } from "react-toast-notifications";

const FieldWrapper = styled(Form.Group)`
  .field:first-child {
    width: 300px;
  }
`;

export const setPersonName = (person) =>
  `${person.forename ? person.forename + " " : ""}${person.surname} (${
    person.personId
  })`;

function CourseApplicationModal({
  isModalOpen,
  onClose,
  course,
  isApplyForStaff,
  info,
}) {
  const { t } = useTranslation();
  const [eyDpState, setEyDpState] = useState({});
  const [eyAcState, setEyAcState] = useState({});
  const { lookups: inclusionNeedsLookups } = useLookup(6140);
  const [showDetailsField, setShowDetailsField] = useState("");
  const { person, selectedProvider: provider } = useSession();
  const [personOptions, setPersonOptions] = useState([]);
  const { addToast } = useToasts();
  const [submitting, setSubmitting] = useState(false);

  const schema = yup.object({
    personId: yup.string().required(t("Please enter a value")),
    costAgreedInd: yup.bool().oneOf([true], "Field must be checked"),
    dateAgreedInd: yup.bool().oneOf([true], "Field must be checked"),
    attCanAgreedInd: yup.bool().oneOf([true], "Field must be checked"),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (fields) => {
    let postData = {
      ...fields,
      costAgreedInd: fields.costAgreedInd ? "Y" : "N",
      personId: !isApplyForStaff ? person.personId : fields.personId,
      providerId: provider.providerId,
      courseDatesId: course.courseDatesId,
    };

    if (eyDpState.active === "Y") {
      postData = {
        ...postData,
        dateAgreedInd: fields.dateAgreedInd ? "Y" : "N",
      };
    }

    if (eyAcState.active === "Y") {
      postData = {
        ...postData,
        attCanAgreedInd: fields.attCanAgreedInd ? "Y" : "N",
      };
    }

    setSubmitting(true);

    axios
      .post("/api/course-candidate", postData)
      .then(({ data }) => {
        addToast(
          `${t("Course Application")} ${t("has been successfully completed.")}`,
          {
            appearance: "success",
          }
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.type === "AssociatedRecordFoundException") {
          addToast(
            `${t("Course Application")} ${t(
              "has already been received for candidate."
            )}`,
            {
              appearance: "error",
            }
          );
        } else {
          addToast(
            `${t("Course Application")} ${t(
              "has not completed successfully."
            )}`,
            {
              appearance: "error",
            }
          );
        }
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    axios
      .get("/api/impulse-config/EY_DP_STATE")
      .then(({ data }) => setEyDpState(data))
      .catch(console.log);

    axios
      .get("/api/impulse-config/EY_AC_STATE")
      .then(({ data }) => setEyAcState(data))
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (!isApplyForStaff) {
      setValueHelper({ personId: person.personId }, methods.setValue);
    }
  }, [person.personId, isModalOpen]);

  useEffect(() => {
    if (isApplyForStaff && provider.providerId) {
      axios
        .get(`/api/person-list/${provider.providerId}/early-staff`)
        .then(({ data }) =>
          setPersonOptions(
            data.map((p) => ({ text: setPersonName(p), value: p.personId }))
          )
        )
        .catch(console.log);
    }
  }, [isApplyForStaff, provider.providerId]);

  return (
    <Modal size="medium" open={isModalOpen} onClose={onClose}>
      <Modal.Header>{t("Course Application")}</Modal.Header>
      <Modal.Content>
        <FormProvider {...methods}>
          <Form>
            {info.active === "Y" && (
              <Label as="a" color="yellow" style={{width:"100%"}}>
                Please note: Maximum course candidates per setting is {info.infoText}
              </Label>
            )}

            <Form.Input
              fluid
              label={t("Course Name")}
              value={course.courseDesc}
              readOnly
            />

            <Form.Group>
              <Form.Input
                fluid
                label={t("Cost for LA School\\Setting")}
                value={course.laCost ? addZeroes(course.laCost, 2) : "0.00"}
                width={8}
                readOnly
              />

              <Form.Input
                fluid
                label={t("Cost for Other School\\Setting")}
                value={course.othCost ? addZeroes(course.othCost, 2) : "0.00"}
                width={8}
                readOnly
              />
            </Form.Group>

            {eyDpState.active === "Y" && (
              <FieldWrapper>
                <Form.Field>
                  <a
                    href={eyDpState.infoText}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    {t("Data protection statement")}
                  </a>
                </Form.Field>
                <Checkbox name="dateAgreedInd" label={t("Read & Agreed")} />
              </FieldWrapper>
            )}

            {eyAcState.active === "Y" && (
              <FieldWrapper>
                <Form.Field>
                  <a
                    href={eyAcState.infoText}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "underline" }}
                  >
                    {t("Non-attendance & cancellations statement")}
                  </a>
                </Form.Field>
                <Checkbox name="attCanAgreedInd" label={t("Read & Agreed")} />
              </FieldWrapper>
            )}

            {!isApplyForStaff ? (
              <Form.Input
                label={t("Attendee Name")}
                value={setPersonName(person)}
                readOnly
              />
            ) : (
              <Dropdown
                label={t("Attendee Name")}
                name="personId"
                options={personOptions}
              />
            )}

            <Dropdown
              label={t("Inclusion Needs")}
              name="canNeed"
              options={inclusionNeedsLookups.map(lookupToOptionsTranslation(t))}
              cb={(value) => setShowDetailsField(value)}
            />

            {!!showDetailsField && (
              <TextArea label={t("Details")} name="needsText" />
            )}

            <Checkbox name="costAgreedInd" label={t("Course Cost Agreed")} />
          </Form>
        </FormProvider>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="orange"
          onClick={() => {
            onClose();
            setShowDetailsField(false);
            methods.reset();
          }}
        >
          <Icon name="remove circle" />
          {t("Close")}
        </Button>

        <UserAccessComponent requires="PPORT_TR_CM_APP_SUB">
          <Button
            color="green"
            onClick={methods.handleSubmit(onSubmit)}
            disabled={submitting}
          >
            <Icon name="check circle" />
            {t("Submit Application")}
          </Button>
        </UserAccessComponent>
      </Modal.Actions>
    </Modal>
  );
}

export default CourseApplicationModal;
