import {Button, Form, Grid, Icon, Menu, Modal, Popup,} from "semantic-ui-react";
import {DefaultFilter, Grid as AddressGrid,} from "@caci/react-grid";

import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import {useSession} from "../consumer/SessionConsumer";
import UserAccessComponent from "./UserAccessComponent";


function LinkAddressSearchModal({
  isAddressSearchModalOpen,
  closeAddressSerchModal,
  setAddressId,
}) {
  const { t } = useTranslation();
  const schema = yup.object({});
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const { reset } = methods;
  const [rows, setRows] = useState([]);
  const { selectedProvider: address } = useSession();
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const transformData = (rows) => {
    return rows.map((row) => ({
      addressId: row.addressId,
      areaCode: row.areaCode,
      leaNo: row.leaNo,
      unitNo: row.unitNo,
      unitName: row.unitName,
      houseNo: row.houseNo,
      street: row.street,
      mainRoad: row.mainRoad,
      district: row.district,
      town: row.town,
      county: row.county,
      postcode: row.postcode,
      addressText:
        (row.unitNo ? row.unitNo + ", " : "") +
        (row.unitName ? row.unitName + ", " : "") +
        (row.houseNo ? row.houseNo + " " : "") +
        (row.street ? row.street : "") +
        (row.mainRoad ? ", " + row.mainRoad : "") +
        (row.district ? ", " + row.district : "") +
        (row.town ? ", " + row.town : "") +
        (row.county ? ", " + row.county : "") +
        (row.postcode ? ", " + row.postcode : ""),
    }));
  };

  const setquickSearch = () => {
    axios
      .get(`/api/search/pport-address/?postCode=${searchTerm}`)
      .then(({ data: rows }) => {
        setRows(rows);
      })

      .then(console.log);
  };

  useEffect(() => {

  }, [address.addressId, address.postCode]);
  return (
    <div>
      <Modal
        open={isAddressSearchModalOpen}
        size="large"
        centered={false}
        className="dark-modal"
      >
        <Modal.Header className={"modal-header"}>
          {t("Address Details")}
        </Modal.Header>
        <Modal.Content className={"modal-content"} style={{padding:'0.1rem'}}>
        <Menu
            attached="top"
            className={"border-bottom padding-left-20"}
            style={{ borderTopWidth: "0px" }}
        >
          <Menu.Item header className={"active"}>
            {t("Address Search")}
          </Menu.Item>
        </Menu>
        </Modal.Content>
        <Modal.Content className={"modal-content"}>
          <Grid.Row className={"padding-bottom-0"}>
            <Grid.Column width={3}>
              <Form>
                <Form>
                  <Form.Group widths={1}>
                    <Form.Field>
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleChange}
                      />
                      <small
                        className={"text-white-color"}
                        style={{ color: "#555" }}
                      >
                        {t("Please enter a postcode")}
                      </small>
                    </Form.Field>

                    <Popup
                      trigger={
                        <Form.Button
                          icon={
                            <Icon>
                              <i className={"fas fa-search"} />
                            </Icon>
                          }
                          onClick={setquickSearch}
                          type="button"
                        />
                      }
                      content={t("Search")}
                    />
                    <Popup
                      trigger={
                        <Form.Button
                          icon={
                            <Icon>
                              <i className={"fas fa-search-plus"} />
                            </Icon>
                          }
                          type="button"
                        />
                      }
                      content={t("Advanced Search")}
                    />
                  </Form.Group>
                </Form>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <AddressGrid
            actionsCell={({ row }) => (
              <div style={{ textAlign: "center" }}>
                <UserAccessComponent requires="PPORT_ADDRESS_LNK">
                <Popup
                  trigger={
                    <Icon
                      className={"light-blue-border"}
                      circular
                      link
                      name="linkify"
                      onClick={() => setAddressId(row.addressId)}
                    />
                  }
                  content={t("Link Address")}
                />
                </UserAccessComponent>
              </div>
            )}
            columns={[
              {
                key: "addressId",
                name: t("Address ID"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "leaNo",
                name: t("LA"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "areaCode",
                name: t("Area"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "addressText",
                name: t("Address"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
                resizable: true,
                width: 550,
              },
              {
                key: "postcode",
                name: t("Postcode"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
            ]}
            rows={transformData(rows)}
          />
          <br />
          <Button onClick={() => closeAddressSerchModal()} color="orange">
            <Icon name="arrow circle left" />
            {t("Back")}
          </Button>
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default LinkAddressSearchModal;
