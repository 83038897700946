import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import ProviderSelector from "../components/ProviderSelector";
import LoadingMessage from "../components/LoadingMessage";
import { useSession } from "../consumer/SessionConsumer";

function Home({ homeViewCount, setViewCount }) {
  const { providers, loadingProviders, setSelectedProvider, fetchProviders } = useSession();

  useEffect(() => {
    setSelectedProvider({});
    if (homeViewCount > 0) {
      fetchProviders();
    }
  }, [setSelectedProvider, fetchProviders]);

  useEffect(() => {
    setViewCount({ homeViewCount: homeViewCount + 1 });
  }, [setViewCount]);

  if (loadingProviders) {
    return (
      <Container>
        <LoadingMessage />
      </Container>
    );
  }

  return (
    <ProviderSelector providers={providers} setProvider={setSelectedProvider} />
  );
}

export default Home;
