import { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Menu, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import useGridRows from "../hooks/useGridRows";
import useLookup from "../consumer/LookupConsumer";
import { lookupCodeToValue, setValueHelper } from "../utils";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "@caci/react-form-fields";
import axios from "axios";
import UserAccessComponent from "./UserAccessComponent";

function WellcommAssessmentMovement({
  wellcomAssessId,
  isNewRecord,
  dateCompleted
}) {
  const { rows, setRows } = useGridRows();
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { lookups: moveTypeLookups } = useLookup(6128);
  const { addToast } = useToasts();

  const schema = yup.object({
    assessmentMoves: yup.array().of(
      yup.object({
        value: yup
          .string()
          .isNumeric(t("This value must be numeric"))
          .nullable()
      })
    )
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ assessmentMoves }) => {
    // Filtered Moves
    const postData = assessmentMoves.filter(am => {
      const foundScore = rows.find(r => r.id === am.id);
      if (!!foundScore) {
        const value = am.value ? parseInt(am.value) : am.value;
        return value !== foundScore.value;
      }
      return false;
    });

    console.log(assessmentMoves, postData);

    setSaving(true);
    axios
      .post("/api/wellcomm-assessment-move", postData)
      .then(({ data }) => {
        addToast(
          `${t("WellComm Assessment Movements")} ${t("has been updated")}`,
          {
            appearance: "success"
          }
        );
        setRows(
          rows.map(row => {
            const foundScore = data.find(d => d.id === row.id);
            if (!!foundScore) return foundScore;
            return row;
          })
        );
        setSaving(false);
      })
      .catch(err => {
        setSaving(false);
        addToast(
          `${t("WellComm Assessment Movements")} ${t("has not been updated")}`,
          {
            appearance: "error"
          }
        );
      });
  };

  const { fields } = useFieldArray({
    control: methods.control,
    name: "assessmentMoves"
  });

  useEffect(() => {
    if (wellcomAssessId && !isNewRecord) {
      axios
        .get(`/api/wellcomm-assessment-move-list/${wellcomAssessId}`)
        .then(({ data }) => {
          setRows(data);
          setValueHelper(
            {
              assessmentMoves: data.sort((a, b) => {
                const aValue = lookupCodeToValue(moveTypeLookups, a.moveType);
                const bValue = lookupCodeToValue(moveTypeLookups, b.moveType);
                return aValue === bValue ? 0 : aValue < bValue ? -1 : 1;
              })
            },
            methods.setValue
          );
        })
        .catch(console.log);
    }
  }, [wellcomAssessId]);

  useEffect(() => {
    if (rows.length > 0) {
      setValueHelper(
        {
          assessmentMoves: rows.sort((a, b) => {
            const aValue = lookupCodeToValue(moveTypeLookups, a.moveType);
            const bValue = lookupCodeToValue(moveTypeLookups, b.moveType);
            return aValue === bValue ? 0 : aValue < bValue ? -1 : 1;
          })
        },
        methods.setValue
      );
    }
  }, [moveTypeLookups]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className="active">
          {t("Assessment Movement")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            {fields.map((row, index) => (
              <Grid stackable>
                <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                  <Grid.Column width={2} className={"padding-left-0"}>
                    <p style={{ paddingTop: "0.75rem" }}>
                      <b className={"text-white-color"}>
                        {t(lookupCodeToValue(moveTypeLookups, row.moveType))}
                      </b>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={3} className={"padding-left-0"}>
                    <Form.Field
                      control={Input}
                      name={`assessmentMoves.${index}.value`}
                      defaultValue={row.value}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ))}
            <UserAccessComponent requires="PPORT_PM_WC_D_SUB">
              <Button
                className={"green"}
                type="submit"
                disabled={saving || isNewRecord || !!dateCompleted}
                onClick={methods.handleSubmit(onSubmit)}
              >
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Segment>
    </>
  );
}

export default WellcommAssessmentMovement;
