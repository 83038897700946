import {useEffect, useState} from "react";
import {Icon, Menu, Popup, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {DefaultFilter, Grid as ServicesProvidedGrid, LookupFilter, LookupFormatter} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import axios from "axios";
import useLookup from "../consumer/LookupConsumer";
import SafeguardingDetails from "./SafeguardingDetails";


function SafeguardingScreen(visitId) {
    const { t } = useTranslation();
    const [showDetail, setShowDetail] = useState(false);
    const [rows, setRows] = useState([]);
    const [row, setRow] = useState({});
    const { lookups: noteOfVisitSafeguarding } = useLookup(6115);

    const fetchSafeguarding = () => {
        axios
            .get(`/api/early-visit-note-safe-list/${visitId.visitId}`)
            .then(({ data: rows }) => {
                setRows(rows);
            })
            .then(console.log);
    };
    useEffect(() => {
        fetchSafeguarding();
    }, []);

    return (
        <>
            <Menu attached="top" className={"border-bottom"}>
                <Menu.Item header className={"active"}>
                    {t("Safeguarding")}
                </Menu.Item>
            </Menu>
            <Segment attached={"bottom"}>
                    {!showDetail ? (
                    <>
                        <ServicesProvidedGrid
                            actionsCell={({ row }) => (
                                <div style={{ textAlign: "center" }}>
                                    <UserAccessComponent requires="PPORT_PM_NOV_SG_L_EDIT">
                                        <Popup
                                            content={t("Edit")}
                                            trigger={
                                                <Icon
                                                    circular
                                                    name="eye"
                                                    className={"dark-blue-border"}
                                                    color="black"
                                                    onClick={() => {
                                                        setRow(row);
                                                        setShowDetail(true);
                                                    }}
                                                />
                                            }
                                        />
                                    </UserAccessComponent>
                                </div>
                            )}
                            columns={[
                                {
                                    key: "safeguardCode",
                                    name: t("Status"),
                                    filterable: true,
                                    sortable: true,
                                    filterRenderer: LookupFilter,
                                    formatter: LookupFormatter,
                                    lookups: noteOfVisitSafeguarding
                                },
                                {
                                    key: "comments",
                                    name: t("Comments"),
                                    filterable: true,
                                    sortable: true,
                                    filterRenderer: DefaultFilter
                                }
                            ]}
                            rows={rows}
                        />
                    </>
                ) : (
                    <SafeguardingDetails
                        row={row}
                        onBack={() => {
                            setShowDetail(false);
                            setRow({});
                        }}
                    />
                )}
            </Segment>
        </>
    );
}

export default SafeguardingScreen;
