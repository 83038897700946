import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Form, Icon } from "semantic-ui-react";
import UserAccessComponent from "./UserAccessComponent";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { useToasts } from "react-toast-notifications";
import { Dropdown } from "@caci/react-form-fields";
import { getValidationFlag, lookupToOptionsTranslation, setValueHelper } from "../utils";
import useLookup from "../consumer/LookupConsumer";

function ServicesProvidedDetails({ row = {}, onBack, typeNameValid = [] }) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { selectedProvider: provider } = useSession();
  const { addToast } = useToasts();
  const { lookups: typeNameLookups } = useLookup(880);

  const schema = yup.object({
    typeName: yup
      .string()
      .required(t("Please enter a value"))
      .unique({
        message: t("Already in use"),
        existing: typeNameValid
      })
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ typeName, id, validationFlag }) => {
    const postData = {
      validationFlag: "I",
      providerId: provider.providerId,
      typeName
    };

    const putData = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag)
      },
      { op: "add", path: "/typeName", value: typeName }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    };

    setSaving(true);

    if (id) {
      axios
        .put(`/api/pport-early-provisions/${id}`, putData, putObject)
        .then(() => {
          addToast(
            `${t("Service Provided")} ${t("has been successfully updated")}`,
            {
              appearance: "success"
            }
          );
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(`${t("Service Provided")} ${t("has not been updated")}`, {
            appearance: "error"
          });
          setSaving(false);
        });
    } else {
      axios
        .post(`/api/pport-early-provisions`, postData)
        .then(({ data: { id } }) => {
          addToast(
            `${t("Service Provided")} ${t("has been successfully created")}`,
            {
              appearance: "success"
            }
          );
          methods.setValue("id", id);
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(`${t("Service Provided")} ${t("has not been created")}`, {
            appearance: "error"
          });
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    return () => {
      methods.reset({
        typeName: "",
        providerId: "",
        id: ""
      });
    };
  }, []);

  useEffect(() => {
    setValueHelper(row, methods.setValue);
  }, [row]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Dropdown
          label={t("Service Provided")}
          options={typeNameLookups.map(lookupToOptionsTranslation(t))}
          name="typeName"
          disabled={row.id}
        />
        <br />
        <Button onClick={() => onBack(false)} color="orange">
          <Icon name="arrow circle left" />
          {t("Back")}
        </Button>
        <UserAccessComponent requires="PPORT_PM_DT_SER_D_SUB">
          <Button className={"green"} type="submit" disabled={saving || !!row.id}>
            <Icon name="check circle" />
            {t("Submit")}
          </Button>
        </UserAccessComponent>
      </Form>
    </FormProvider>
  );
}

export default ServicesProvidedDetails;
