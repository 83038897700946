import { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Menu, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import useGridRows from "../hooks/useGridRows";
import useLookup from "../consumer/LookupConsumer";
import { lookupCodeToValue, setValueHelper } from "../utils";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input } from "@caci/react-form-fields";
import axios from "axios";
import UserAccessComponent from "./UserAccessComponent";

function WellcommAssessmentScoreScreen({
  wellcomAssessId,
  isNewRecord,
  dateCompleted
}) {
  const { rows, setRows } = useGridRows();
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const { lookups: ageRangeLookups } = useLookup(6127);
  const { addToast } = useToasts();

  const schema = yup.object({
    assessmentScores: yup.array().of(
      yup.object({
        green: yup
          .string()
          .isNumeric(t("This value must be numeric"))
          .nullable(),
        red: yup
          .string()
          .isNumeric(t("This value must be numeric"))
          .nullable(),
        amber: yup
          .string()
          .isNumeric(t("This value must be numeric"))
          .nullable()
      })
    )
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ assessmentScores }) => {
    // Filtered Scores
    const postData = assessmentScores.filter(as => {
      const foundScore = rows.find(r => r.id === as.id);
      if (!!foundScore) {
        const green = as.green ? parseInt(as.green) : as.green;
        const amber = as.amber ? parseInt(as.amber) : as.amber;
        const red = as.red ? parseInt(as.red) : as.red;
        return (
          green !== foundScore.green ||
          amber !== foundScore.amber ||
          red !== foundScore.red
        );
      }
      return false;
    });

    setSaving(true);
    axios
      .post("/api/wellcomm-assessment-score", postData)
      .then(({ data }) => {
        addToast(
          `${t("WellComm Assessment Scores")} ${t("has been updated")}`,
          {
            appearance: "success"
          }
        );
        setRows(
          rows.map(row => {
            const foundScore = data.find(d => d.id === row.id);
            if (!!foundScore) return foundScore;
            return row;
          })
        );
        setSaving(false);
      })
      .catch(err => {
        setSaving(false);
        addToast(
          `${t("WellComm Assessment Scores")} ${t("has not been updated")}`,
          {
            appearance: "error"
          }
        );
      });
  };

  const { fields } = useFieldArray({
    control: methods.control,
    name: "assessmentScores"
  });

  useEffect(() => {
    if (wellcomAssessId && !isNewRecord) {
      axios
        .get(`/api/wellcomm-assessment-score-list/${wellcomAssessId}`)
        .then(({ data }) => {
          setRows(data);
          setValueHelper(
            {
              assessmentScores: data.sort((a, b) => {
                const aValue = lookupCodeToValue(ageRangeLookups, a.ageRange);
                const bValue = lookupCodeToValue(ageRangeLookups, b.ageRange);
                return aValue === bValue ? 0 : aValue < bValue ? -1 : 1;
              })
            },
            methods.setValue
          );
        })
        .catch(console.log);
    }
  }, [wellcomAssessId]);

  useEffect(() => {
    if (rows.length > 0) {
      setValueHelper(
        {
          assessmentScores: rows.sort((a, b) => {
            const aValue = lookupCodeToValue(ageRangeLookups, a.ageRange);
            const bValue = lookupCodeToValue(ageRangeLookups, b.ageRange);
            return aValue === bValue ? 0 : aValue < bValue ? -1 : 1;
          })
        },
        methods.setValue
      );
    }
  }, [ageRangeLookups]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className="active">
          {t("Assessment Scores")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column
                  width={2}
                  className={"padding-left-0"}
                ></Grid.Column>
                <Grid.Column
                  width={3}
                  className={"padding-left-0"}
                  align="center"
                >
                  <label className={"text-white-color"}>{t("Red")}</label>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  className={"padding-left-0"}
                  align="center"
                >
                  <label className={"text-white-color"}>{t("Amber")}</label>
                </Grid.Column>
                <Grid.Column
                  width={3}
                  className={"padding-left-0"}
                  align="center"
                >
                  <label className={"text-white-color"}>{t("Green")}</label>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            {fields.map((row, index) => (
              <Grid stackable key={index}>
                <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                  <Grid.Column width={2} className={"padding-left-0"}>
                    <p style={{ paddingTop: "0.75rem" }}>
                      <b className={"text-white-color"}>
                        {t(lookupCodeToValue(ageRangeLookups, row.ageRange))}
                      </b>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={3} className={"padding-left-0"}>
                    <Input
                      name={`assessmentScores[${index}].red`}
                      defaultValue={row.red}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} className={"padding-left-0"}>
                    <Input
                      name={`assessmentScores[${index}].amber`}
                      defaultValue={row.amber}
                    />
                  </Grid.Column>

                  <Grid.Column width={3} className={"padding-left-0"}>
                    <Input
                      name={`assessmentScores[${index}].green`}
                      defaultValue={row.green}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ))}
            <UserAccessComponent requires="PPORT_PM_WC_D_SUB">
              <Button
                className={"green"}
                type="submit"
                disabled={saving || isNewRecord || !!dateCompleted}
                onClick={methods.handleSubmit(onSubmit)}
              >
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Segment>
    </>
  );
}

export default WellcommAssessmentScoreScreen;
