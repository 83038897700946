import React, { Component } from "react";
import { Button, Form, Icon, Message } from "semantic-ui-react";
import UserAccessComponent from "../components/UserAccessComponent";
import ProfileMatrixDetails from "./ProfileMatrixDetails";
import { isEmpty } from "ramda";
import axios from "axios";
import Translate from "../translation/Translate";
import withToast from "./Toast";

class CourseEvaluationProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      isSaving: false,
      helpRows: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(name, value, index) {
    const { rows } = this.state;
    rows[index][name] = value;
    this.setState({ rows });
  }

  handleSubmit() {
    this.setState({ saving: true });

    axios
      .put(`/api/course-evaluation-value`, this.state.rows)
      .then(() => {
        this.getProfileMatrix();
        this.setState({ saving: false });
        this.props.addToast(
          <Translate text="Course Evaluation Profile has been successfully updated" />,
          { appearance: "success" }
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ saving: false });
      });
  }

  getProfileMatrix() {
    if (this.props.profileId !== 0)
      axios
        .get(
          `/api/course-evaluation-profile-matrix/${this.props.recordId}/${this.props.providerId}/${this.props.profileId}`
        )
        .then(({ data: rows }) => {
          this.setState({ rows });
        })
        .catch((error) => {
          console.log(error);
        });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.recordId !== this.props.recordId) {
      this.getProfileMatrix();
    }
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.recordId !== prevProps.recordId) {
      return { recordId: nextProps.recordId };
    } else return null;
  }

  componentDidMount() {
    if (!this.props.isNew && this.props.recordId) {
      this.getProfileMatrix();
    }
  }

  render() {
    return (
      <div>
        <Form>
          {!isEmpty(this.state.rows) ? (
            this.state.rows.map((row, index) => (
              <ProfileMatrixDetails
                key={index}
                rowIndex={index}
                onChange={this.handleChange}
                {...row}
              />
            ))
          ) : (
            <Message className={"dark-bg"}>
              <Message.Header className={"text-white-color"}>
                  <Translate text="No Evaluations Found" />
              </Message.Header>
              <p className={"text-white-color"}>
                  <Translate text="There are currently no Evaluations associated with this Course." />
              </p>
            </Message>
          )}
        </Form>
        <br /> <br />
        <Button color="orange" onClick={this.props.onClose}>
          <Icon name="remove circle" />
          <Translate text="Close" />
        </Button>
        <UserAccessComponent requires="COURSE_CE_D_SUB">
          <Button
            onClick={this.handleSubmit}
            disabled={
              this.props.agreedDate ||
              isEmpty(this.state.rows) ||
              this.state.saving
            }
            loading={this.state.saving}
            className={"green"}
          >
            <Icon name="check circle" />
            <Translate text="Submit" />
          </Button>
        </UserAccessComponent>
      </div>
    );
  }
}

export default withToast(CourseEvaluationProfile);
