import React from "react";
import styled from "styled-components";

const SliderContainer = styled.div`
  width: ${(props) => props.width || "70%"};
  height: 30px;
  margin: 0 0.9375rem;
`;

const InputSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 30px;
  background: ${(props) => props.color || "green"};
  outline: none;
  opacity: 1;
  border-radius: 3px;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 45px;
    height: 45px;
    background: #fff;
    border: 1px solid ${(props) => props.color || "green"};
    border-radius: 50%;
    cursor: pointer;
  }
  &::-moz-range-thumb {
    width: 45px;
    height: 45px;
    background: #fff;
    cursor: pointer;
    border: 1px solid ${(props) => props.color || "green"};
    border-radius: 50%;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;

const Range = styled.div`
  position: relative;
  float: left;
  width: 100%;
`;

const SliderText = styled.span`
  position: absolute;
  top: 0.4em;
  width: 45px;
  height: 0.1em;
  text-align: center;
  color: white;
  font-weight: bold;
  white-space: nowrap;
  pointer-events: none;
`;

const RangeSliderText = styled(SliderText)`
  left: ${(props) => props.left || "0px"};
  color: ${(props) => props.color || "green"};
`;

const MinSliderText = styled(SliderText)`
  left: 0px;
`;

const MaxSliderText = styled(SliderText)`
  right: 0px;
`;

const MatrixSlider = ({
  minBand,
  maxBand,
  onChange,
  sliderColor,
  band,
  width,
  inputRef,
  name,
}) => {
  let rangeLeft,
    setWidth = false,
    widthIn;
  if (band != null && inputRef.current) {
    widthIn = inputRef.current.clientWidth;
    const eleWidth = widthIn - 45;
    const bandDiff = maxBand - band;
    rangeLeft = bandDiff ? `${(eleWidth * band) / maxBand}px` : `${eleWidth}px`;

    // Set width in px to avoid movement in range thumb and range inner text.
    setWidth = true;
    widthIn = `${widthIn}px`;
  }

  return (
    <SliderContainer width={setWidth ? widthIn : width}>
      <Range>
        <MinSliderText>{minBand}</MinSliderText>
        <InputSlider
          ref={inputRef}
          type="range"
          min={minBand}
          max={maxBand}
          name={name}
          value={band}
          onChange={onChange}
          color={sliderColor}
        />
        <MaxSliderText>{maxBand}</MaxSliderText>
        <RangeSliderText color={sliderColor} left={rangeLeft}>
          {band}
        </RangeSliderText>
      </Range>
    </SliderContainer>
  );
};

export default MatrixSlider;
