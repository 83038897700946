import { useEffect } from "react";
import { Grid, Menu, Segment } from "semantic-ui-react";
import FipDetails from "./FipDetails";
import AssociatedFiles from "./AssociatedFiles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { isEmpty } from "ramda";
import axios from "axios";
import { fipRowMapper } from "../private/FipScreen";

function FipDetailsScreen({ row, setRefetch, setRow }) {
  const { t } = useTranslation();
  const { fipId } = useParams();

  useEffect(() => {
    return () => setRefetch(refetch => !refetch);
  }, []);

  useEffect(() => {
    if (isEmpty(row) && fipId) {
      axios
        .get(`/api/early-provider-action/${fipId}`)
        .then(({ data }) => setRow(fipRowMapper(data)))
        .catch(console.log);
    }
  }, [fipId]);

  return (
    <Grid stackable>
      <Grid.Row className={"padding-top-0"}>
        <Grid.Column width={4} className={"padding-left-0 padding-right-10"}>
          <FipDetails row={row} />
        </Grid.Column>
        <Grid.Column width={12} className={"padding-left-0 padding-right-3"}>
          <AssociatedFiles
            getApi={
              !!row.id
                ? `/api/early-provider-action-document-list/${row.id}`
                : ""
            }
            downloadAccessCode={"PPORT_PM_FIP_F_L_DOWN"}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default FipDetailsScreen;
