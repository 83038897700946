import { Segment, Grid, Icon, Button, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

function UserNotifications(props) {
  const { t } = useTranslation();

  return (
    <div>
      <Segment style={{ minHeight: "440px", padding: 0 }}>
        {props.notifications.map((row, index) => (
          <Segment
            key={index}
            style={{
              marginBottom:
                props.notifications.length === index + 1 ? "0" : "0.5rem"
            }}
          >
            <Grid stackable>
              <Grid.Row style={{ background: row.readDate ? "#fff" : "#eee" }}>
                <Grid.Column width={14}>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <Icon size={"large"}>
                      <i
                        className={
                          row.readDate ? "fal fa-folder-open" : "fal fa-folder"
                        }
                        style={{ color: "blue" }}
                      />
                    </Icon>
                    <label style={{ fontWeight: "bold" }}>
                      By {row.createdUser}
                    </label>
                    <label>
                      {" "}
                      &nbsp;{moment(row.createdDate).format("DD/MM/YYYY")}
                    </label>
                  </div>
                  <div>
                    <label>{row.text}</label>
                  </div>
                </Grid.Column>

                <Grid.Column width={2}>
                  <Dropdown
                    icon="ellipsis horizontal"
                    item
                    simple
                    direction={"left"}
                    style={{ marginTop: "0.75rem" }}
                  >
                    <Dropdown.Menu style={{ height: '75px' }}>
                      <UserAccessComponent requires="USR_NOT_L_EDIT">
                        <Dropdown.Item onClick={() => props.onMarkAsRead(row)}>
                          <i
                            className={
                              row.readDate
                                ? "fal fa-folder"
                                : "fal fa-folder-open"
                            }
                            style={{ color: "blue", paddingRight: "0.5rem" }}
                          />
                          <label>
                            {row.readDate
                              ? t("Mark as Unread")
                              : t("Mark as Read")}
                          </label>
                        </Dropdown.Item>
                      </UserAccessComponent>
                      <UserAccessComponent requires="USR_NOT_L_DEL">
                        <Dropdown.Item>
                          <DeleteConfirmationModal
                              message={t("Are you sure you want to delete this Notification?")}
                              onDelete={() => props.onDelete(row.id)}
                              title={t("Delete")}
                              iconName="trash alternate outline"
                              color="red"
                              confirmTitle={t('Confirm Delete')}
                          />
                        </Dropdown.Item>
                      </UserAccessComponent>
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))}
      </Segment>

      <Button
        className="orange"
        onClick={() => {
          props.handleClose();
        }}
      >
        <Icon name="remove circle" /> {t("Back")}
      </Button>
    </div>
  );
}

export default UserNotifications;
