import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Segment,
  Message,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { lookupCodeToValue } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import { withRouter } from "react-router-dom";
import moment from "moment";

const putObject = {
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};

function ExitDetails({ rows, match }) {
  const [editData, setEditData] = useState([]);
  const [exits, setExit] = useState([]);
  const { lookups: ofstedOutcome } = useLookup(6116);
  const { lookups: exitStrategySupport } = useLookup(6117);
  const { lookups: earlyYearsOutcomeArea } = useLookup(6109);
  const { t } = useTranslation();

  const fetchAssessmentExit = () => {
    axios
      .get(`/api/early-assessment-exit-supp-list/${match.params.id}`)
      .then(({ data: exit }) => {
        setExit(exit);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchAssessmentExit();
  }, []);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Exit")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <Fragment>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Form.Input
                    label={t("Inspection Date")}
                    value={
                      rows.exitInspectionDate
                        ? moment(rows.exitInspectionDate).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                  <Form.Input
                    label={t("Inspection Outcome")}
                    value={lookupCodeToValue(
                      ofstedOutcome,
                      rows.exitInspectionOutcome
                    )}
                  />
                  <Form.Input
                    label={t("Exit Strategy Support")}
                    value={lookupCodeToValue(
                      exitStrategySupport,
                      rows.exitSupport
                    )}
                  />
                </Grid.Column>
                <Grid.Column width={12}>
                  <Menu attached="top" className={"border-bottom"}>
                    <Menu.Item header className={"active"}>
                      {t("Outline of exit support")}
                    </Menu.Item>
                  </Menu>
                  <Segment attached={"bottom"}>
                    {exits && exits.length > 0 ? (
                      exits.map((exit, index) => (
                        <div key={index} className="padding-bottom-10">
                          <b>
                            <p className="custom-label-color padding-bottom-5">
                              {lookupCodeToValue(
                                earlyYearsOutcomeArea,
                                exit.outcomeArea
                              )}
                            </p>
                          </b>
                          <p>
                            <b>{"Exit Support: "}</b>
                            <span>
                              {exit.exitSupport.replace(/<\/?[^>]+(>|$)/g, "")}
                            </span>
                          </p>
                        </div>
                      ))
                    ) : (
                      <Message info icon color="purple">
                        <Icon name="info" />
                        <Message.Header>
                          {t("No records have been found")}
                        </Message.Header>
                      </Message>
                    )}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Fragment>
      </Segment>
    </>
  );
}

export default withRouter(ExitDetails);
