import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Popup,
  Segment
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as PickupDropOffGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import RejectCommentsModal from "./RejectCommentsModal";
import {
  getValidationFlag,
  lookupToOptionsTranslation,
  setValueHelper
} from "../utils";
import useLookup from "../consumer/LookupConsumer";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown, Input } from "@caci/react-form-fields";
import useGridRows from "../hooks/useGridRows";

const validationFlagsList = ["1", "2", "3"];

const putObject = {
  headers: {
    "Content-Type": "application/json-patch+json"
  }
};

function QualificationScreen({
  setValidationFlag,
  urlSlug,
  personId,
  isNewRecord
}) {
  const { t } = useTranslation();
  const {
    rows,
    showDetails,
    refetch,
    setRows,
    setShowDetails,
    setRefetch
  } = useGridRows();
  const [showExclamation, setShowExclamation] = useState(false);
  const { lookups: typeLookups } = useLookup(171);
  const { lookups: levelsLookups } = useLookup(891);
  const { lookups: statusLookups } = useLookup(911);
  const { addToast } = useToasts();
  const [nameLookupCodeType, setNameLookupCodeType] = useState("");
  const [saving, setSaving] = useState(false);

  const { lookups: nameLookups } = useLookup(nameLookupCodeType);

  const schema = yup.object({
    lookupCodeType: yup.string().required(t("Please enter a value")),
    qualification: yup.string().required(t("Please enter a value"))
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const handleDelete = row => {
    axios
      .put(
        `/api/delete-pport-qualification`,
        {},
        {
          params: {
            personId: row.personId,
            qualification: row.qualification,
            levels: row.levels
          }
        }
      )
      .then(() => {
        addToast(
          `${t("Qualification")} ${t("has been successfully removed")}`,
          {
            appearance: "success"
          }
        );
        setRefetch(!refetch);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Qualification")} ${t("has not been removed")}`, {
          appearance: "error"
        });
      });
  };

  const onSubmit = ({
    qualification,
    levels,
    status,
    validationFlag,
    id,
    lookupCodeType,
    renewalDate,
    dateAchieved
  }) => {
    const postData = {
      id: id
        ? id
        : {
            personId,
            qualification,
            levels
          },
      qualification,
      levels,
      status,
      lookupCodeType,
      renewalDate,
      dateAchieved,
      validationFlag: id ? getValidationFlag(validationFlag) : "I",
      personId: personId
    };

    setSaving(true);
    const message = id ? "updated" : "created";
    axios
      .post(`/api/pport-qualification`, postData)
      .then(({ data: { id } }) => {
        addToast(
          `${t("Qualification")} ${t(`has been successfully ${message}`)}`,
          {
            appearance: "success"
          }
        );
        methods.setValue("id", id);
        setSaving(false);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Qualification")} ${t(`has not been ${message}`)}`, {
          appearance: "error"
        });
        setSaving(false);
      });
  };

  useEffect(() => {
    const fetchPickups = () => {
      axios
        .get(`/api/pport-qualification-list/${personId}`)
        .then(({ data: rows }) => {
          setRows(rows);
          const validationFlagIndicator = rows.some(row =>
            validationFlagsList.includes(row.validationFlag)
          );
          setValidationFlag(urlSlug, {
            name: "qualification",
            value: validationFlagIndicator
          });
          setShowExclamation(validationFlagIndicator);
        })
        .catch(console.log);
    };
    if (personId) fetchPickups();
  }, [personId, refetch]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Qualifications")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetails ? (
          <>
            <PickupDropOffGrid
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <UserAccessComponent requires="PPORT_PM_ST_QUAL_L_EDIT">
                    <Popup
                      content={t("Edit")}
                      trigger={
                        <Icon
                          circular
                          name="pencil"
                          className={"dark-blue-border"}
                          color="black"
                          onClick={() => {
                            setShowDetails(true);
                            setValueHelper(
                              {
                                ...row,
                                lookupCodeType: row.lookupCodeType
                                  ? row.lookupCodeType.toString()
                                  : ""
                              },
                              methods.setValue
                            );
                            setNameLookupCodeType(row.lookupCodeType);
                          }}
                        />
                      }
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_ST_QUAL_L_EDIT">
                    <RejectCommentsModal
                      rejectComments={row.rejectComments}
                      rejectedDate={row.rejectedDate}
                      rejectedBy={row.rejectedBy}
                      disabled={
                        !validationFlagsList.includes(row.validationFlag)
                      }
                      circular
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_ST_QUAL_L_DEL">
                    <DeleteConfirmationModal
                      message={t(
                        "Are you sure you want to delete this Qualification?"
                      )}
                      onDelete={() => handleDelete(row)}
                      iconName="trash alternate outline"
                      color="red-border"
                      circular
                    />
                  </UserAccessComponent>
                </div>
              )}
              columns={[
                {
                  key: "lookupCodeType",
                  name: t("Type"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: typeLookups
                },
                {
                  key: "qualificationDetails",
                  name: t("Details"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter
                },
                {
                  key: "levels",
                  name: t("Level"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: levelsLookups
                },
                {
                  key: "status",
                  name: t("Status"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: statusLookups
                },
                {
                  key: "dateAchieved",
                  name: t("Date Achieved"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter
                },
                {
                  key: "renewalDate",
                  name: t("Renewal Date"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter
                }
              ]}
              rows={rows}
            />
            <div className={"gap-btw-grid-buttons"}>
              <UserAccessComponent requires="PPORT_PM_ST_QUAL_L_ADD">
                <Button
                  onClick={() => setShowDetails(true)}
                  className={"purple"}
                  disabled={isNewRecord || !personId}
                >
                  <Icon>
                    <i className="fas fa-file-certificate" />
                  </Icon>
                  {t("New Qualification")}
                </Button>
              </UserAccessComponent>
            </div>
          </>
        ) : (
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid stackable>
                <Grid.Row className={"padding-top-0"}>
                  <Grid.Column
                    width={8}
                    className={"padding-left-0 padding-right-10"}
                  >
                    <Dropdown
                      label={t("Type")}
                      options={typeLookups.map(lookupToOptionsTranslation(t))}
                      name="lookupCodeType"
                      cb={val => {
                        setNameLookupCodeType(val);
                        methods.setValue("qualification", "");
                      }}
                    />
                    <Dropdown
                      label={t("Qualification")}
                      options={nameLookups.map(lookupToOptionsTranslation(t))}
                      name="qualification"
                    />
                    <Dropdown
                      label={t("Level")}
                      options={levelsLookups.map(lookupToOptionsTranslation(t))}
                      name="levels"
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={8}
                    className={"padding-left-0 padding-right-0"}
                  >
                    <Dropdown
                      label={t("Status")}
                      options={statusLookups.map(lookupToOptionsTranslation(t))}
                      name="status"
                    />
                    <Input
                      label={t("Date Achieved")}
                      name="dateAchieved"
                      type="date"
                    />
                    <Input
                      label={t("Renewal Date")}
                      name="renewalDate"
                      type="date"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button
                onClick={() => {
                  setRefetch(!refetch);
                  setShowDetails(false);
                  methods.reset();
                }}
                color="orange"
              >
                <Icon name="arrow circle left" />
                {t("Back")}
              </Button>
              <UserAccessComponent requires="PPORT_PM_ST_QUAL_D_SUB">
                <Button className={"green"} type="submit" disabled={saving}>
                  <Icon name="check circle" />
                  {t("Submit")}
                </Button>
              </UserAccessComponent>
            </Form>
          </FormProvider>
        )}
      </Segment>
    </>
  );
}

export default QualificationScreen;
