import * as yup from "yup";

function validations() {
  // unique validation
  yup.addMethod(yup.string, "unique", function({
    message = "Already in use",
    existing = []
  }) {
    return this.test("unique-validate", message, function(value) {
      const { path, createError } = this;
      if (!!existing) {
        return (
          (Array.isArray(existing)
            ? !existing.includes(value)
            : existing !== value) || createError({ path, message })
        );
      }
    });
  });

  // unique combination validation
  yup.addMethod(yup.string, "uniqueCombination", function({
     message,
     combinationKeyValues = {},
     existing = []
   }) {
    return this.test("unique-combination-validate", message, function(value) {
      const { path, createError } = this;
      if (!!existing && !!combinationKeyValues) {
        if(Array.isArray(existing)) {
          return !existing.find(rec =>
            Object.entries(combinationKeyValues).every(([key, value]) => rec[key] === value)
          ) || createError({ path, message });
        }
        return createError({ path, message });
      }
    });
  });

  // Numeric Validation
  yup.addMethod(yup.mixed, "isNumeric", function(message) {
    return this.test("isNumeric-validate", message, function(value) {
      const { path, createError } = this;
      const regex = /^[-+]?[0-9\s]+$/;
      if (value && !regex.test(value)) {
        return createError({ path, message });
      }
      return true;
    });
  });

  // Numeric Decimal Validation
  yup.addMethod(yup.mixed, "isDecimal", function(message) {
    return this.test("isDecimal-validate", message, function(value) {
      const { path, createError } = this;
      let n = parseFloat(value),
        num = Number(value);
      if (value && isNaN(num - n)) {
        return createError({ path, message });
      }
      return true;
    });
  });

  // Numeric Decimal Max Length validation
  yup.addMethod(yup.mixed, "maxDecimal", function({
    messageFunc,
    lenInt,
    lenFloat
  }) {
    return this.test("maxDecimal-validate", messageFunc, function(value) {
      const { path, createError } = this;
      if (value) {
        if (value.includes(".") && value.length > lenFloat) {
          return createError({
            path,
            message: !!messageFunc
              ? messageFunc(lenFloat)
              : `${lenFloat} character limit exceeded`
          });
        } else if (!value.includes(".") && value.length > lenInt) {
          return createError({
            path,
            message: !!messageFunc
              ? messageFunc(lenInt)
              : `${lenInt} character limit exceeded`
          });
        }
      }
      return true;
    });
  });
}

export default validations;
