import React, { useState } from "react";
import axios from "axios";
import { Button, Container, Form, Icon, Message } from "semantic-ui-react";
import { useForm, FormProvider } from "react-hook-form";
import { Input } from "@caci/react-form-fields";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validations = yup.object().shape({
  email: yup.string().required("Required").email("Please enter a valid email"),
});

function RequestPasswordResetScreen() {
  const [status, setStatus] = useState(3);
  const form = useForm({
    resolver: yupResolver(validations),
  });

  return (
    <Container>
      {(status === 0 || status === 3) && (
        <FormProvider {...form}>
          <Form
            onSubmit={form.handleSubmit(({ email }) => {
              setStatus(0);
              axios
                .get(`/api/reset/${email}`)
                .then(() => setStatus(1))
                .catch(() => setStatus(-1));
            })}
          >
            <Input label="Email" name="email" />
            <Button disabled={status === 0} loading={status === 0}>
              Submit
            </Button>
            <Button as={Link} to="/public/login" disabled={status === 0}>
              Cancel
            </Button>
          </Form>
        </FormProvider>
      )}
      {status !== 0 && status !== 3 && (
        <Message
          icon
          error={status === -1}
          success={status === 1}
          as={status === 1 ? Link : Message}
          to="/public/login"
        >
          {status === -1 && <Icon name="warning" />}
          {status === 1 && <Icon name="check" />}
          <Message.Content>
            <Message.Header>
              {status === -1 && "Request Failed"}
              {status === 1 && "Account Reset"}
            </Message.Header>
            {status === -1 && "Please contact your administrator."}
            {status === 1 &&
              "Please click here to go back to the login screen."}
          </Message.Content>
        </Message>
      )}
    </Container>
  );
}

export default RequestPasswordResetScreen;
