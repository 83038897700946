import { useState } from "react";
import { Button, Form, Icon, Message, Segment } from "semantic-ui-react";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as CourseGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import { Dropdown, Input } from "@caci/react-form-fields";
import { useTranslation } from "react-i18next";
import useGridRows from "../hooks/useGridRows";
import useLookup from "../consumer/LookupConsumer";
import { FormProvider, useForm } from "react-hook-form";
import { lookupToOptionsTranslation } from "../utils";
import axios from "axios";
import UserAccessComponent from "../components/UserAccessComponent";
import CourseDetailsModal from "../components/CourseDetailsModal";

const inputStyles = {
  width: "33.33%",
  paddingRight: "10px"
};

const messageStyles = { marginTop: "6px" };

function CourseSearch() {
  const { rows, setRows, row, setRow } = useGridRows();
  const [loading, setLoading] = useState(false);
  const [courseDetailsModalOpen, setCourseDetailsModalOpen] = useState(false);
  const { t } = useTranslation();
  const { lookups: subjectCodeLookups } = useLookup(603);
  const { lookups: levelLookups } = useLookup(6132);
  const { lookups: districtLookups } = useLookup(6134);

  const methods = useForm();

  const onSearchSubmit = searchData => {
    setLoading(true);
    axios
      .post("/api/search/course", searchData)
      .then(({ data }) => setRows(data))
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Segment className={"margin-bottom-0"} style={{ background: "#EBAE11" }}>
        <FormProvider {...methods}>
          <Form>
            <Form.Group>
              <Form.Field style={inputStyles}>
                <Input name="courseDesc" label={t("Course Name")} />
              </Form.Field>

              <Form.Field style={inputStyles}>
                <Input
                  name="startDate"
                  label={t("Starts on or After")}
                  type="date"
                />
              </Form.Field>

              <Form.Field style={inputStyles}>
                <Dropdown
                  name="subjectCode"
                  label={t("Course Area")}
                  options={subjectCodeLookups.map(
                    lookupToOptionsTranslation(t)
                  )}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field style={inputStyles}>
                <Dropdown
                  name="level"
                  label={t("Course Level")}
                  options={levelLookups.map(lookupToOptionsTranslation(t))}
                />
              </Form.Field>

              <Form.Field style={inputStyles}>
                <Dropdown
                  name="district"
                  label={t("District")}
                  options={districtLookups.map(lookupToOptionsTranslation(t))}
                />
              </Form.Field>

              <Form.Field style={{ ...inputStyles, marginTop: "23px" }}>
                <Button
                  className="blue"
                  type="submit"
                  onClick={methods.handleSubmit(onSearchSubmit)}
                >
                  <Icon>
                    <i className={"fa fa-search"} />
                  </Icon>
                  {t("Search")}
                </Button>

                <Button
                  className="red"
                  onClick={() => {
                    setRows([]);
                    methods.reset({
                      courseDesc: "",
                      startDate: "",
                      subjectCode: "",
                      level: "",
                      district: ""
                    });
                  }}
                >
                  <Icon>
                    <i className={"fa fa-times"} />
                  </Icon>
                  {t("Clear Search")}
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </FormProvider>
      </Segment>
      {loading ? (
        <Message icon style={messageStyles}>
          <Icon name="circle notched" loading />
          <Message.Content>{t("Searching")}...</Message.Content>
        </Message>
      ) : rows.length > 0 ? (
        <Segment className={"grid-container-550"}>
          <CourseDetailsModal
            row={row}
            isModalOpen={courseDetailsModalOpen}
            onClose={() => setCourseDetailsModalOpen(false)}
            levelLookups={levelLookups}
            districtLookups={districtLookups}
            subjectCodeLookups={subjectCodeLookups}
          />
          <CourseGrid
            actionCellWidth={170}
            actionsCell={({ row }) => (
              <div style={{ textAlign: "center" }}>
                <UserAccessComponent requires="PPORT_TR_CM_DET">
                  <Button
                    color="blue"
                    onClick={() => {
                      setRow(row);
                      setCourseDetailsModalOpen(true);
                    }}
                    style={{ padding: "0.6rem 1.5rem" }}
                  >
                    <Icon>
                      <i className="fal fa-chalkboard-teacher" />
                    </Icon>
                    {t("Course Details")}
                  </Button>
                </UserAccessComponent>
              </div>
            )}
            columns={[
              {
                key: "courseCode",
                name: t("Course Code"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "courseDesc",
                name: t("Course Name"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "subjectCode",
                name: t("Course Area"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: subjectCodeLookups
              },
              {
                key: "level",
                name: t("Course Level"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: levelLookups
              },
              {
                key: "name",
                name: t("Course Organiser"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "startDate",
                name: t("Start Date"),
                filterable: true,
                sortable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter
              },
              {
                key: "duration",
                name: t("Duration"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "maxAttend",
                name: t("Max Attendees"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              }
            ]}
            rows={rows}
          />
        </Segment>
      ) : (
        <Message style={messageStyles}>
          <Message.Header className={"text-white-color"}>
            {t("No Courses Returned")}
          </Message.Header>
          <p className={"text-white-color"}>
            {t("Please use the Course Search options to find Courses")}
          </p>
        </Message>
      )}
    </>
  );
}

export default CourseSearch;
