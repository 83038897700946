import { useTranslation } from "react-i18next";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { addZeroes, lookupCodeToValue } from "../utils";
import { useSession } from "../consumer/SessionConsumer";
import { setPersonName } from "./CourseApplicationModal";
import useLookup from "../consumer/LookupConsumer";

function CourseApplicationStatusModal({
  isModalOpen,
  onClose,
  course,
  courseCandidate
}) {
  const { t } = useTranslation();
  const { person } = useSession();
  const { lookups: statusLookups } = useLookup(614);

  return (
    <Modal open={isModalOpen} onClose={onClose} size="medium">
      <Modal.Header>{t("Course Application Status")}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            fluid
            label={t("Course Name")}
            value={course.courseDesc}
            readOnly
          />

          <Form.Group>
            <Form.Input
              fluid
              label={t("Cost for LA School\\Setting")}
              value={course.laCost ? addZeroes(course.laCost, 2) : "0.00"}
              width={8}
              readOnly
            />

            <Form.Input
              fluid
              label={t("Cost for Other School\\Setting")}
              value={course.othCost ? addZeroes(course.othCost, 2) : "0.00"}
              width={8}
              readOnly
            />
          </Form.Group>

          <Form.Input
            label={t("Attendee Name")}
            value={setPersonName(person)}
            readOnly
          />

          <Form.Input
            label={t("Status")}
            value={t(lookupCodeToValue(statusLookups, courseCandidate.status))}
          />

          <Form.Input
            label={t("Place Agreed")}
            value={courseCandidate.placeInd === "Y" ? t("Yes") : t("No")}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} color={"orange"}>
          <Icon name="remove circle" />
          {t("Close")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CourseApplicationStatusModal;
