import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Icon, Message } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";

function ActivateScreen() {
  const [status, setStatus] = useState(0);
  const { key } = useParams();

  useEffect(() => {
    axios
      .post("/api/activate", { key })
      .then(() => setStatus(1))
      .catch(() => setStatus(-1));
  }, [key]);

  return (
    <Container>
      <Message
        icon
        error={status === -1}
        info={status === 0}
        success={status === 1}
        as={status === 1 ? Link : Message}
        to="/public/login"
      >
        {status === -1 && <Icon name="warning" />}
        {status === 0 && <Icon name="circle notched" loading />}
        {status === 1 && <Icon name="check" />}
        <Message.Content>
          <Message.Header>
            {status === -1 && "Activation Failed"}
            {status === 0 && "Activating Account"}
            {status === 1 && "Account Activated"}
          </Message.Header>
          {status === -1 && "We failed to activate your account."}
          {status === 0 && "Please wait while we activate your account."}
          {status === 1 && "Please click here to return to the login screen."}
        </Message.Content>
      </Message>
    </Container>
  );
}

export default ActivateScreen;
