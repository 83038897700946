import { Segment } from "semantic-ui-react";

function Estimates() {
  return (
    <Segment className={"grid-container-550"}>
      <p>Estimates</p>
    </Segment>
  );
}

export default Estimates;
