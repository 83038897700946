import { useEffect, useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { getValidationFlag, setValueHelper } from "../utils";
import UserAccessComponent from "./UserAccessComponent";
import axios from "axios";
import { validationFlagsList } from "../private/StaffScreen";
import { FormProvider, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { isEmpty } from "ramda";
import { Input } from "@caci/react-form-fields";
import RejectCommentsModal from "./RejectCommentsModal";
import styled from "styled-components";

function LinkAddressModal({
  isAddressModalOpen,
  closePersonModal,
  addressId,
  cb,
}) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);
  const [address, setAddress] = useState({});
  const methods = useForm();
  const ModalHeaderWrapper = styled(Modal.Header)`
    display: flex !important;
  `;
  const FlexItems = styled.div`
    flex: 1;
  `;

  const FlexItemsWrapper = styled(FlexItems)`
    display: flex;
    justify-content: flex-end;
  `;
  const onSubmit = ({
    addressId,
    leaNo,
    unitNo,
    unitName,
    houseNo,
    street,
    mainRoad,
    district,
    town,
    county,
    country,
    organisationName,
    postcode,
    validationFlag,
  }) => {
    const putData = [
      { op: "add", path: "/addressId", value: addressId },
      { op: "add", path: "/organisationName", value: organisationName },
      { op: "add", path: "/leaNo", value: leaNo },
      { op: "add", path: "/unitNo", value: unitNo },
      { op: "add", path: "/unitName", value: unitName },
      { op: "add", path: "/houseNo", value: houseNo },
      { op: "add", path: "/street", value: street },
      { op: "add", path: "/mainRoad", value: mainRoad },
      { op: "add", path: "/district", value: district },
      { op: "add", path: "/town", value: town },
      { op: "add", path: "/county", value: county },
      { op: "add", path: "/country", value: country },
      { op: "add", path: "/postcode", value: postcode },
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag),
      },
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };
    const message = addressId ? "updated" : "created";

    axios
      .put(`/api/pport-address/${addressId}`, putData, putObject)
      .then(({ data }) => {
        methods.setValue("addressId", data?.addressId);
        setSaving(false);
        addToast(`${t("Address")} ${t(`has been successfully ${message}`)}`, {
          appearance: "success",
        });
        getAddressDetails(addressId);
        if (!!cb) cb(data);
        setAddress(data);
        if (validationFlagsList.includes(data.validationFlag))
          setShowExclamation(true);
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Address")} ${t(`has not been ${message}`)}`, {
          appearance: "error",
        });
        setSaving(false);
      });
  };

  const getAddressDetails = (addressId) => {
    axios
      .get(`/api/pport-address/${addressId}`)
      .then(({ data }) => {
        if (!isEmpty(data)) {
          setValueHelper(data, methods.setValue);
          setValueHelper(address, methods.setValue);
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (addressId) {
      getAddressDetails(addressId);
    }
  }, [addressId]);

  return (
    <Modal
      open={isAddressModalOpen}
      size="tiny"
      centered={false}
      className="dark-modal"
    >
      <ModalHeaderWrapper className={"modal-header"}>
        <FlexItems>{t("Address Details")}</FlexItems>
        <FlexItemsWrapper>
          {showExclamation && (
            <>
              <UserAccessComponent requires="PPORT_PERSON_EDIT">
                <RejectCommentsModal
                  rejectComments={address.rejectComments}
                  rejectedDate={address.rejectedDate}
                  rejectedBy={address.rejectedBy}
                  color={"orange"}
                />
              </UserAccessComponent>
              <Icon color={"red"} style={{ paddingLeft: 5 }}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </>
          )}
        </FlexItemsWrapper>
      </ModalHeaderWrapper>
      <Modal.Content className={"modal-content"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input label={t("Address ID")} name="addressId" readOnly />
            <Input label={t("Organisation Name")} name="organisationName" />
            <Input label={t("Unit Number / Sub Building")} name="unitNo" />
            <Input label={t("Unit/Building Name")} name="unitName" />
            <Input label={t("House Number")} name="houseNo" />
            <Input label={t("Street")} name="street" />
            <Input label={t("Main Road")} name="mainRoad" />
            <Input label={t("District")} name="district" />
            <Input label={t("Town")} name="town" />
            <Input label={t("County")} name="county" />
            <Input label={t("Country")} name="country" />
            <Input label={t("Postcode")} name="postcode" />
            <br />
            <Button onClick={() => closePersonModal()} color="orange">
              <Icon name="arrow circle left" />
              {t("Back")}
            </Button>

            <UserAccessComponent requires="PPORT_PERSON_SUB">
              <Button className={"green"} type="submit" disabled={saving}>
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Modal.Content>
    </Modal>
  );
}

export default LinkAddressModal;
