import { Fragment, useEffect, useState } from "react";
import {
  DefaultFilter,
  Grid as ChargeGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import axios from "axios";
import { Button, Form, Icon, Menu, Popup, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useLookup from "../consumer/LookupConsumer";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { Dropdown, Input } from "@caci/react-form-fields";
import { useToasts } from "react-toast-notifications";
import { useSession } from "../consumer/SessionConsumer";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import moment from "moment";
import { lookupToOptionsTranslation } from "../utils";
import RejectCommentsModal from "./RejectCommentsModal";

const validationFlagsList = ["1", "2", "3"];

const putObject = {
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};

function ChargesScreen({ holidayId, setValidationFlag, urlSlug }) {
  const [rows, setRows] = useState([]);
  const [saving, setSaving] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [avail, setAvail] = useState([]);
  const { lookups: earlyChargeDuration } = useLookup(895);
  const { selectedProvider: provider } = useSession();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);
  const [lastModifiedDate, setLastModifiedDate] = useState();
  const [financialYear, setFinancialYear] = useState("");

  const schema = yup.object({
    year: yup.string().required(t("Please enter a value")),
    duration: yup
      .string()
      .required(t("Please enter a value"))
      .max(30, "30 character limit exceeded"),
    rateFrom: yup
      .string()
      .required(t("Please enter a value"))
      .isDecimal(t("This value must be numeric"))
      .maxDecimal({
        messageFunc: (len) => `${len} character limit exceeded`,
        lenInt: 6,
        lenFloat: 9,
      }),
    rateTo: yup
      .string()
      .required(t("Please enter a value"))
      .isDecimal(t("This value must be numeric"))
      .maxDecimal({
        messageFunc: (len) => `${len} character limit exceeded`,
        lenInt: 6,
        lenFloat: 9,
      }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleDelete = (id) => {
    const data = [{ op: "add", path: "/validationFlag", value: "D" }];
    axios
      .put(`/api/pport-early-charge/${id}`, data, putObject)
      .then(() => {
        addToast(`${t("Charge")} ${t("has been successfully removed")}`, {
          appearance: "success",
        });
        fetchEarlyCharges();
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Charge")} ${t("has been successfully removed")}`, {
          appearance: "error",
        });
      });
  };

  const onSubmit = ({ id, duration, year, rateFrom, rateTo }) => {
    const data = {
      duration: duration,
      year: year,
      rateFrom: rateFrom,
      rateTo: rateTo,
      holidayId: holidayId.holidayId,
      validationFlag: "I",
    };

    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/duration", value: duration },
      { op: "add", path: "/year", value: year },
      { op: "add", path: "/rateFrom", value: rateFrom },
      { op: "add", path: "/rateTo", value: rateTo },
    ];
      setSaving(true);
    if (id) {
      axios
        .put(`/api/pport-early-charge/${id}`, put, putObject)
        .then(() => {
          addToast(`${t("Charge")} ${t("has been successfully updated")}`, {
            appearance: "success",
          });
          fetchEarlyCharges();
            setSaving(false);
        })
        .catch(console.log);
    } else {
      axios
        .post(`/api/pport-early-charge`, data)
        .then(({ data: { id } }) => {
          methods.setValue("id", id);
          addToast(`${t("Charge")} ${t("has been successfully created")}`, {
            appearance: "success",
          });
          fetchEarlyCharges();
            setSaving(false);
        })
        .catch(console.log);
    }
  };

  const fetchEarlyCharges = () => {
    if (holidayId) {
      axios
        .get(`/api/pport-early-charge-list/${holidayId.holidayId}`)
        .then(({ data: rows }) => {
          const validationFlagIndicator = rows.some((row) =>
            validationFlagsList.includes(row.validationFlag)
          );
          setValidationFlag(urlSlug, {
            name: "charge",
            value: validationFlagIndicator,
          });
          setShowExclamation(validationFlagIndicator);
          if (rows.length > 0) setRows(rows);
          const modifiedDates = rows.map((row) => moment(row.modifiedDate));
          let lastModifiedDate;
          if (modifiedDates.length > 0)
            lastModifiedDate = moment.max(modifiedDates);
          setLastModifiedDate((lastModifiedDate) => {});
        })
        .catch(console.log);
    }
  };

  const getFinancialYears = () => {
    const today = moment();
    let financialYear = today.year();
    const financialEndDate = moment().set({
      month: 2,
      date: 31,
      year: financialYear,
    }); // March 31st of current year

    if (today.isBefore(financialEndDate)) {
      // before March 31st
      financialYear = financialYear - 1;
    }

    // Array of 5 years with current year
    const financialYearOptions = new Array(5).fill(0).map((_, i) => ({
      text: financialYear - 2 + i,
      value: financialYear - 2 + i,
    }));

    setAvail(financialYearOptions);
    setFinancialYear(financialYear);
  };

  const { reset } = methods;

  useEffect(() => {
    if (holidayId) {
      fetchEarlyCharges();
      getFinancialYears();
    }
    return () => {
      reset({
        id: "",
        duration: "",
        rateFrom: "",
        rateTo: "",
        year: "",
      });
    };
  }, [provider.providerId, holidayId]);

  const rowsData = rows.filter((row) => row.year === financialYear);
  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Charges")}
        </Menu.Item>
        {showExclamation && (
          <Menu.Menu position="right">
              <Menu.Item>
                  <Icon color={"red"}>
                      <i className="fas fa-exclamation-triangle" />
                  </Icon>
              </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <Fragment>
            <span className={"text-white-color"}>
              <b>{t("Financial Year")}</b>
            </span>
            <Form.Dropdown
              search
              selection
              name="financialYear"
              value={financialYear}
              options={avail}
              onChange={(_, { value }) => setFinancialYear(value)}
            />
            <div>
              <ChargeGrid
                actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <UserAccessComponent requires="PPORT_PM_HCP_CHARGE_L_EDIT">
                      <Popup
                        content={t("Edit")}
                        trigger={
                          <Icon
                            circular
                            name="pencil"
                            className={"dark-blue-border"}
                            color="black"
                            onClick={() => {
                              setShowDetail(true);
                              methods.setValue(`id`, row.id);
                              methods.setValue(`duration`, row.duration);
                              methods.setValue(`rateFrom`, row.rateFrom);
                              methods.setValue(`rateTo`, row.rateTo);
                              methods.setValue(`year`, row.year);
                            }}
                          />
                        }
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_CHARGE_L_EDIT">
                      <RejectCommentsModal
                        rejectComments={row.rejectComments}
                        rejectedDate={row.rejectedDate}
                        rejectedBy={row.rejectedBy}
                        disabled={
                          !validationFlagsList.includes(row.validationFlag)
                        }
                        circular
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_CHARGE_L_DEL">
                      <DeleteConfirmationModal
                        message={t(
                          "Are you sure you want to delete this Charge?"
                        )}
                        onDelete={() => handleDelete(row.id)}
                        iconName="trash alternate outline"
                        color="red-border"
                        circular
                      />
                    </UserAccessComponent>
                  </div>
                )}
                columns={[
                  {
                    key: "duration",
                    name: t("Duration"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: LookupFilter,
                    formatter: LookupFormatter,
                    lookups: earlyChargeDuration,
                  },
                  {
                    key: "rateFrom",
                    name: t("Rate From"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "rateTo",
                    name: t("Rate To"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                ]}
                rows={rowsData}
              />
            </div>
            <div>
              <div className={"gap-btw-grid-buttons"}>
                <UserAccessComponent requires="PPORT_PM_HCP_CHARGE_L_ADD">
                  <Button
                    onClick={() => {
                      setShowDetail(true);
                      methods.setValue(`year`, financialYear);
                    }}
                    className={"purple"}
                  >
                    <Icon>
                      <i className="far fa-coins" />
                    </Icon>
                    {t("New Charge")}
                  </Button>
                </UserAccessComponent>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Dropdown
                  search
                  selection
                  label={"Financial Year"}
                  name={"year"}
                  options={avail}
                />
                <Dropdown
                  search
                  selection
                  label={"Duration"}
                  name={"duration"}
                  options={earlyChargeDuration.map(
                    lookupToOptionsTranslation(t)
                  )}
                />
                <Input label={t("Rate From")} name="rateFrom" />
                <Input label={t("Rate To")} name="rateTo" />
                <br />
                <Button
                  onClick={() => {
                    setShowDetail(false);
                    reset();
                  }}
                  color="orange"
                >
                  <Icon name="arrow circle left" />
                  {t("Back")}
                </Button>
                <UserAccessComponent requires="PPORT_PM_HCP_CHARGE_D_SUB">
                  <Button className={"green"} type="submit" disabled={saving}>
                    <Icon name="check circle" />
                    {t("Submit")}
                  </Button>
                </UserAccessComponent>
              </Form>
            </FormProvider>
          </Fragment>
        )}
      </Segment>
    </>
  );
}

export default ChargesScreen;
