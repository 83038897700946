import { useState, useEffect } from "react";
import { Menu, Segment, } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
    Grid as PurposeVisitGrid,
    LookupFilter,
    LookupFormatter,
    DefaultFilter,
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import axios from "axios";
import useLookup from "../consumer/LookupConsumer";


function PurposeVisitScreen(visitId) {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const { lookups: earlyYearsOutcomeArea } = useLookup(6109);

    const fetchPurposeVisit = () => {
        axios
            .get(`/api/early-visit-note-purpose-list/${visitId.visitId}`)
            .then(({ data: rows }) => {
                setRows(rows);
            })
            .then(console.log);
    };

    useEffect(() => {
       fetchPurposeVisit();
    },[]);

    return (
        <>
            <Menu attached="top" className={"border-bottom"}>
                <Menu.Item header className={"active"}>
                    {t("Purpose Of Visit")}
                </Menu.Item>
            </Menu>
            <Segment attached={"bottom"}>
                    <>
                        <PurposeVisitGrid
                            columns={[
                                {
                                    key: "purposeCode",
                                    name: t("Provider Name"),
                                    filterable: true,
                                    sortable: true,
                                    filterRenderer: LookupFilter,
                                    formatter: LookupFormatter,
                                    lookups: earlyYearsOutcomeArea,
                                }
                            ]}
                            rows={rows}
                        />
                    </>
            </Segment>
        </>
    );
}

export default PurposeVisitScreen;
