import React, { useEffect, useState } from "react";
import { Form, Menu, Segment, Header, Button, Icon } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getValidationFlag, setValueHelper } from "../utils";
import { Dropdown, Input, TextArea } from "@caci/react-form-fields";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "./UserAccessComponent";
import { useHistory, useParams } from "react-router-dom";
import PersonModal from "./PersonModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSession } from "../consumer/SessionConsumer";
import { isEmpty } from "ramda";
import moment from "moment";

function StaffDetails({ row, setRow }) {
  const [personModalOpen, setPersonModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const { selectedProvider: provider } = useSession();
  const { staffId } = useParams();

  const schema = yup.object({
    personId: yup.string().required(t("Please enter a value")),
    reasonForLeave: yup
      .string()
      .max(100, t("100 character limit exceeded"))
      .nullable(),
    hourlyRate: yup
      .string()
      .isDecimal(t("This value must be numeric"))
      .maxDecimal({
        messageFunc: (len) => `${len} character limit exceeded`,
        lenInt: 6,
        lenFloat: 9,
      })
      .nullable(),
    hoursPerWeek: yup
      .string()
      .isNumeric(t("This value must be numeric"))
      .max(2, t("2 character limit exceeded"))
      .nullable(),
    hoursPerYear: yup
      .string()
      .isNumeric(t("This value must be numeric"))
      .max(5, t("5 character limit exceeded"))
      .nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const yesNoOptions = [
    { value: "Y", text: t("Yes") },
    { value: "N", text: t("No") },
  ];

  const onSubmit = ({
    id,
    personId,
    startDate,
    agency,
    workWith34Olds,
    managementRole,
    endDate,
    reasonForLeave,
    hourlyRate,
    hoursPerWeek,
    hoursPerYear,
  }) => {
    const postData = {
      personId,
      startDate,
      agency,
      workWith34Olds,
      managementRole,
      endDate,
      reasonForLeave,
      hourlyRate,
      hoursPerWeek,
      hoursPerYear,
      validationFlag: "I",
      providerId: provider.providerId,
    };
    const putData = [
      { op: "add", path: "/personId", value: personId },
      { op: "add", path: "/startDate", value: startDate },
      { op: "add", path: "/agency", value: agency },
      { op: "add", path: "/workWith34Olds", value: workWith34Olds },
      { op: "add", path: "/managementRole", value: managementRole },
      { op: "add", path: "/endDate", value: endDate },
      { op: "add", path: "/reasonForLeave", value: reasonForLeave },
      { op: "add", path: "/hourlyRate", value: hourlyRate },
      { op: "add", path: "/hoursPerWeek", value: hoursPerWeek },
      { op: "add", path: "/hoursPerYear", value: hoursPerYear },
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(row.validationFlag),
      },
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    setSaving(true);

    if (id) {
      axios
        .put(`/api/pport-early-staff/${id}`, putData, putObject)
        .then(({ data }) => {
          addToast(`${t("Staff")} ${t("has been successfully updated")}`, {
            appearance: "success",
          });
          setRow(data);
          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
          addToast(`${t("Staff")} ${t("has not been updated")}`, {
            appearance: "error",
          });
          setSaving(false);
        });
    } else {
      axios
        .post(`/api/pport-early-staff`, postData)
        .then(({ data }) => {
          addToast(`${t("Staff")} ${t("has been successfully created")}`, {
            appearance: "success",
          });
          methods.setValue("id", data.id);
          setRow(data);
          setSaving(false);
          history.push(
            `/portal/${provider.providerId}/provider/provider-staff/${data.id}`
          );
        })
        .catch((error) => {
          console.log(error);
          addToast(`${t("Staff")} ${t("has not been created")}`, {
            appearance: "error",
          });
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    methods.setValue(`id`, row.id);
    methods.setValue(`personId`, row.personId);
    methods.setValue(`agency`, row.agency);
    methods.setValue(`workWith34Olds`, row.workWith34Olds);
    methods.setValue(`managementRole`, row.managementRole);
    methods.setValue(`reasonForLeave`, row.reasonForLeave);
    methods.setValue(`hourlyRate`, row.hourlyRate);
    methods.setValue(`hoursPerWeek`, row.hoursPerWeek);
    methods.setValue(`hoursPerYear`, row.hoursPerYear);
    methods.setValue(
      `endDate`,
      row.id ? moment(row.endDate).format("YYYY-MM-DD") : null
    );
    methods.setValue(
      `startDate`,
      row.id ? moment(row.startDate).format("YYYY-MM-DD") : null
    );
  }, []);

  useEffect(() => {
    if (isEmpty(row) && staffId !== "new") {
      axios
        .get(`/api/pport-early-staff/${staffId}`)
        .then(({ data }) => setRow(data))
        .catch(console.log);
    }
  }, [staffId]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Staff Details")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form>
            <Segment style={{ backgroundColor: "#eee" }}>
              <Header as="h5" textAlign="center">
                {t("Staff Member")}
              </Header>
              <Input label={t("Impulse ID")} name="personId" readOnly />
              <Input label={t("First Name")} name="forename" readOnly />
              <Input label={t("Surname")} name="surname" readOnly />
              <Button
                className={"light-blue"}
                onClick={() => {
                  setIsNew(true);
                  setPersonModalOpen(true);
                }}
              >
                <Icon name="fas fa-male" />
                {t("New Person")}
              </Button>
              <Button
                className={"light-blue"}
                onClick={() => {
                  setIsNew(false);
                  setPersonModalOpen(true);
                }}
              >
                <Icon name="th" />
                {t("View")}
              </Button>
            </Segment>
            <Input label={t("Start Date")} name="startDate" type="date" />
            <Dropdown
              label={t("Working with 2 Year Olds (Agency)")}
              name="agency"
              options={yesNoOptions}
            />
            <Dropdown
              label={t("Working with 3/4 Year Olds")}
              name="workWith34Olds"
              options={yesNoOptions}
            />
            <Dropdown
              label={t("Management")}
              name="managementRole"
              options={yesNoOptions}
            />
            <Input label={t("End Date")} name="endDate" type="date" />
            <TextArea label={t("Reason for Leaving")} name="reasonForLeave" />
            <Input label={t("Hourly Rate")} name="hourlyRate" />
            <Input label={t("Hours Per Week")} name="hoursPerWeek" />
            <Input label={t("Hours Per Year")} name="hoursPerYear" />
            <Button
              onClick={() =>
                history.push(
                  `/portal/${provider.providerId}/provider/provider-staff`
                )
              }
              color="orange"
            >
              <Icon name="arrow circle left" />
              {t("Back")}
            </Button>
            <UserAccessComponent requires="PPORT_PM_ST_D_SUB">
              <Button
                className={"green"}
                type="submit"
                disabled={saving}
                onClick={methods.handleSubmit(onSubmit)}
              >
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
        <PersonModal
          isPersonModalOpen={personModalOpen}
          closePersonModal={() => setPersonModalOpen(false)}
          personId={row.personId}
          isNew={isNew}
          cb={({ personId, forename, surname }) =>
            setValueHelper({ personId, forename, surname }, methods.setValue, {
              shouldValidate: true,
            })
          }
        />
      </Segment>
    </>
  );
}

export default StaffDetails;
