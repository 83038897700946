import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function ProfileModal({
  isProfileModalOpen,
  closeProfileModal,
  userDetails,
  person
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={isProfileModalOpen}
        size="tiny"
        centered={false}
        className="dark-modal"
      >
        <Modal.Header className={"modal-header"}>{t("Profile")}</Modal.Header>
        <Modal.Content className={"modal-content"}>
          <Form>
            <Form.Input
              label={t("Impulse ID")}
              value={userDetails.personId}
              readOnly
            />
            <Form.Input
              label={t("First Name")}
              value={person.forename}
              readOnly
            />
            <Form.Input label={t("Surname")} value={person.surname} readOnly />
            <Form.Input
              label={t("Email Address")}
              value={userDetails.email}
              readOnly
            />
          </Form>
          <br />
          <Button onClick={() => closeProfileModal()} color="orange">
            <Icon name="arrow circle left" />
            {t("Back")}
          </Button>
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default ProfileModal;
