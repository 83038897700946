import { useContext, useEffect } from "react";
import UserAccessComponent from "../components/UserAccessComponent";
import { Icon, Menu } from "semantic-ui-react";
import {
  NavLink,
  Route,
  Switch,
  useParams,
  useRouteMatch,
  useLocation,
  useHistory
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserAccessContext } from "../context";
import CourseSearch from "./CourseSearch";
import MyCourses from "./MyCourses";

const tabs = [
  {
    userAccessCode: "PPORT_TR_CM",
    tabName: "Search",
    tabIcon: "fas fa-info-circle",
    endPath: "courses-search"
  },
  {
    userAccessCode: "PPORT_TR_MC",
    tabName: "My Courses",
    tabIcon: "fas fa-th",
    endPath: "my-courses"
  }
];

function TrainingTabs() {
  const { t } = useTranslation();
  const { providerId } = useParams();
  const getPath = endPath =>
    `/portal/${providerId}/training${endPath ? "/" + endPath : ""}`;
  const { path } = useRouteMatch();
  const { userGroupAccess } = useContext(UserAccessContext);
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const ugCode = userGroupAccess.map(uga => uga.code);
    let pathName = pathname;
    if (pathname.charAt(0) === "/") {
      pathName = pathName.slice(1);
    }
    if (pathName.split("/").length === 3) {
      for (const tab of tabs) {
        if (ugCode.includes(tab.userAccessCode)) {
          history.push(getPath(tab.endPath));
          break;
        }
      }
    }
  }, []);

  return (
    <>
      <Menu className={"margin-top-3 border-bottom"}>
        {tabs.map((tab, index) => (
          <UserAccessComponent requires={tab.userAccessCode} key={index}>
            <Menu.Item
              as={NavLink}
              to={getPath(tab.endPath)}
              activeClassName={"active"}
              exact={!tab.endPath}
            >
              <Icon>
                <i className={tab.tabIcon} />
              </Icon>
              {t(tab.tabName)}
            </Menu.Item>
          </UserAccessComponent>
        ))}
      </Menu>
      <Switch>
        <Route path={`${path}/courses-search`}>
          <UserAccessComponent requires={"PPORT_TR_CM"}>
            <CourseSearch />
          </UserAccessComponent>
        </Route>
        <Route path={`${path}/my-courses`}>
          <UserAccessComponent requires={"PPORT_TR_MC"}>
            <MyCourses />
          </UserAccessComponent>
        </Route>
      </Switch>
    </>
  );
}

export default TrainingTabs;
