import { useSession } from "../consumer/SessionConsumer";
import { Route, Switch, useHistory } from "react-router-dom";
import TimelineDetailsScreen from "./TimelineDetailsScreen";

const addSpaceAtEnd = str => (str ? str + " " : "");

export const wellcommAssessmentRowMapper = ({ person, ...rest }) => ({
  completedBy: person
    ? `${addSpaceAtEnd(person?.forename)}${addSpaceAtEnd(person?.surname)} (${
        person?.personId
      })`
    : "",
  ...rest
});

function TimelineScreen() {

  const history = useHistory();
  const { selectedProvider: provider } = useSession();
  return (
    <Switch>
      <Route>
        <TimelineDetailsScreen
          providerId = {provider.providerId}
          history = {history}
        />
      </Route>
    </Switch>
  );
}

export default TimelineScreen;
