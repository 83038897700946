import { useContext } from "react";
import { UserAccessContext } from "../context";

function UserAccessComponent({ requires = '', requiresIn = [], children }) {
    const { userGroupAccess } = useContext(UserAccessContext);
    if (requires.length > 0 && requiresIn.length > 0) {
        return new Error('Can only use requires or requiresIn and not both at the same time.')
    }
    const userGroupAccessCodes = userGroupAccess.map((group) => group.code);

    if (requires.length > 0) {
        if (!userGroupAccessCodes.includes(requires)) return null;
        return children;
    }

    if (requiresIn.length > 0) {
        if (!requiresIn.find((userGroupAccessCode) => userGroupAccessCodes.inludes(userGroupAccessCode))) return null;
        return children;
    }
}

export default UserAccessComponent;