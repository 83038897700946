import React from "react";
import {Dropdown, Icon, Label, Segment} from "semantic-ui-react";

function DropdownWithSegments({
  placeholder,
  disabled,
  selected,
  isDisabled,
  toggled,
  toggle,
  disableDelete,
  removeIcon = () => {},
  onRemove,
  onChange,
  cb = () => {},
  options = [],
  props = () => {},
}) {
  const selectedOptions = () => {
    const selectedValues = selected.map(({ value }) => value);

    return options.filter(({ value }) => !selectedValues.includes(value));
  };

  const handleRemove = ({ value }) => {
    onRemove(value);
  };

  const getRemoveIcon = (item) => {
    if (removeIcon && typeof removeIcon === "function") {
      return removeIcon({ option: item, disabled: disableDelete });
    }

    return (
      <Icon
        name="delete"
        style={{ float: "right" }}
        link
        onClick={() => handleRemove(item)}
        disabled={disableDelete}
      />
    );
  };

  return (
    <div>
      <Dropdown
        selection
        search
        fluid
        placeholder={placeholder}
        options={selectedOptions()}
        onChange={(_, { value }) => {
          onChange(value);
          cb(value);
        }}
        selectOnBlur={false}
        value={null}
        disabled={disabled}
      />
      {selected.map((item) => (
        <Segment.Group key={item.value} horizontal>
          <Segment
            raised
            color={
              isDisabled
                ? isDisabled(item.value)
                  ? "red"
                  : undefined
                : undefined
            }
            style={{ minWidth: toggle && toggled ? "75%" : 0, paddingTop: 17 }}
          >
            {item.text}
            {getRemoveIcon(item)}
          </Segment>
          {toggle && toggled && (
            <Segment raised textAlign="center">
              <Label
                onClick={() => toggle(item)}
                as="a"
                color={toggled(item) ? "green" : "grey"}
              >
                {toggled(item) ? "Attended" : "Invited"}
              </Label>
            </Segment>
          )}
        </Segment.Group>
      ))}
    </div>
  );
}

export default DropdownWithSegments;
