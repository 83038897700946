import { useEffect, useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import EarlyAssessmentDetails from "../components/EarlyAssessmentDetails";
import EarlyAssessmentDetailsTabs from "../components/EarlyAssessmentDetailsTabs";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EarlyAssessmentReviewScreen from "../components/EarlyAssessmentReviewScreen";
import ExitDetails from "../components/ExitDetails";
import EarlyAssessmentVisitNote from "../components/EarlyAssessmentVisitNote";
import FocusedImprovementScreen from "../components/FocusedImprovementScreeen";
import ProfileOutput from "../components/ProfileOutput";
import useGridRows from "../hooks/useGridRows";

function EarlyAssessmentDetailsScreen({ match, location }) {
  const { row, setRow } = useGridRows();
  const [focus, setFocus] = useState([]);
  const [outputTemplatesListOptions, setOutputTemplatesListOptions] = useState(
      []
  );

  const fetchAssessmentDetails = () => {
    axios
        .get(`/api/early-assessment/${match.params.id}`)
        .then(({ data: row }) => {
            setRow(row);
        })
        .catch(console.log);
  };

  const generateOutput = (templateCode) => {
    const payload = {
      id: match.params.id,
    };
    axios
        .post(`/api/outputs/generate/html/${templateCode}`, payload)
        .then((response) => {
          let newWindow = window.open();
          newWindow.document.body.innerHTML = response.data;
        })
        .catch(console.log);
  };

  const getOutputList = () => {
    axios
        .get(`/api/outputs/options/${location.pathname[2].path}`)
        .then((response) => {
          setOutputTemplatesListOptions(
              response.data.map((t) => ({
                value: t.code,
                text: t.description,
              }))
          );
        })
        .catch(console.log);
  };

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  return (
      <>
        <Container fluid>
          <Grid stackable>
            <Grid.Row className={"padding-top-0"}>
              <Grid.Column
                  width={4}
                  className={"padding-left-0 padding-right-10"}
              >
                <EarlyAssessmentDetails rows={row} />
              </Grid.Column>
              <Grid.Column
                  width={12}
                  className={"padding-left-0 padding-right-10"}
              >
                <EarlyAssessmentDetailsTabs
                    rows={row}
                    fetchAssessmentDetails={fetchAssessmentDetails}
                />

                <FocusedImprovementScreen />

                <EarlyAssessmentVisitNote />
                <EarlyAssessmentReviewScreen />
                <ExitDetails rows={row} />
                <ProfileOutput
                    title="Assessment Output"
                    generateUserAccessCode="PPORT_PM_AS_OUT_D_GEN"
                    generateOutput={generateOutput}
                    getOutputList={getOutputList}
                    outputTemplatesListOptions={outputTemplatesListOptions}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </>
  );
}

export default withRouter(EarlyAssessmentDetailsScreen);
