import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Popup,
  Segment
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  Grid as PickupDropOffGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import RejectCommentsModal from "./RejectCommentsModal";
import {
  getValidationFlag,
  lookupToOptionsTranslation,
  setValueHelper
} from "../utils";
import useLookup from "../consumer/LookupConsumer";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown, TextArea } from "@caci/react-form-fields";
import useGridRows from "../hooks/useGridRows";

const validationFlagsList = ["1", "2", "3"];

function ExperienceScreen({
  setValidationFlag,
  urlSlug,
  personId,
  isNewRecord
}) {
  const { t } = useTranslation();
  const {
    rows,
    showDetails,
    refetch,
    setRows,
    setShowDetails,
    setRefetch
  } = useGridRows();
  const [showExclamation, setShowExclamation] = useState(false);
  const { lookups: categoryLookups } = useLookup(1053);
  const { lookups: roleLookups } = useLookup(907);
  const { lookups: periodLookups } = useLookup(1054);
  const { addToast } = useToasts();
  const [saving, setSaving] = useState(false);

  const schema = yup.object({
    category: yup.string().required(t("Please enter a value")),
    role: yup.string().required(t("Please enter a value")),
    period: yup.string().required(t("Please enter a value")),
    description: yup.string().max(500, t("500 character limit exceeded"))
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const yesNoOptions = [
    { value: "Y", text: t("Yes") },
    { value: "N", text: t("No") }
  ];

  const handleDelete = row => {
    axios
      .put(
        `/api/delete-pport-early-experience`,
        {},
        {
          params: {
            personId: row.personId,
            category: row.category,
            role: row.role,
            period: row.period
          }
        }
      )
      .then(() => {
        addToast(`${t("Experience")} ${t("has been successfully removed")}`, {
          appearance: "success"
        });
        setRefetch(!refetch);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Experience")} ${t("has not been removed")}`, {
          appearance: "error"
        });
      });
  };

  const onSubmit = ({
    category,
    role,
    period,
    validationFlag,
    id,
    teachStaff,
    work3yr4yr,
    description
  }) => {
    const postData = {
      id: id
        ? id
        : {
            personId,
            category,
            role,
            period
          },
      category,
      role,
      period,
      teachStaff,
      work3yr4yr,
      description,
      validationFlag: id ? getValidationFlag(validationFlag) : "I",
      personId: personId
    };

    setSaving(true);
    const message = id ? "updated" : "created";
    axios
      .post(`/api/pport-early-experience`, postData)
      .then(({ data: { id } }) => {
        addToast(
          `${t("Experience")} ${t(`has been successfully ${message}`)}`,
          {
            appearance: "success"
          }
        );
        methods.setValue("id", id);
        setSaving(false);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Experience")} ${t(`has not been ${message}`)}`, {
          appearance: "error"
        });
        setSaving(false);
      });
  };

  useEffect(() => {
    const fetchPickups = () => {
      axios
        .get(`/api/pport-early-experience-list/${personId}`)
        .then(({ data: rows }) => {
          setRows(rows);
          const validationFlagIndicator = rows.some(row =>
            validationFlagsList.includes(row.validationFlag)
          );
          setValidationFlag(urlSlug, {
            name: "experience",
            value: validationFlagIndicator
          });
          setShowExclamation(validationFlagIndicator);
        })
        .catch(console.log);
    };
    if (personId) fetchPickups();
  }, [personId, refetch]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Experience")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetails ? (
          <>
            <PickupDropOffGrid
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <UserAccessComponent requires="PPORT_PM_ST_EXP_L_EDIT">
                    <Popup
                      content={t("Edit")}
                      trigger={
                        <Icon
                          circular
                          name="pencil"
                          className={"dark-blue-border"}
                          color="black"
                          onClick={() => {
                            setShowDetails(true);
                            setValueHelper(row, methods.setValue);
                          }}
                        />
                      }
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_ST_EXP_L_EDIT">
                    <RejectCommentsModal
                      rejectComments={row.rejectComments}
                      rejectedDate={row.rejectedDate}
                      rejectedBy={row.rejectedBy}
                      disabled={
                        !validationFlagsList.includes(row.validationFlag)
                      }
                      circular
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_ST_EXP_L_DEL">
                    <DeleteConfirmationModal
                      message={t(
                        "Are you sure you want to delete this Experience?"
                      )}
                      onDelete={() => handleDelete(row)}
                      iconName="trash alternate outline"
                      color="red-border"
                      circular
                    />
                  </UserAccessComponent>
                </div>
              )}
              columns={[
                {
                  key: "category",
                  name: t("Category"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: categoryLookups
                },
                {
                  key: "role",
                  name: t("Role"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: roleLookups
                },
                {
                  key: "period",
                  name: t("Period"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: periodLookups
                }
              ]}
              rows={rows}
            />
            <div className={"gap-btw-grid-buttons"}>
              <UserAccessComponent requires="PPORT_PM_ST_EXP_L_ADD">
                <Button
                  onClick={() => setShowDetails(true)}
                  className={"purple"}
                  disabled={isNewRecord || !personId}
                >
                  <Icon>
                    <i className="far fa-user-clock" />
                  </Icon>
                  {t("New Experience")}
                </Button>
              </UserAccessComponent>
            </div>
          </>
        ) : (
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid stackable>
                <Grid.Row className={"padding-top-0"}>
                  <Grid.Column
                    width={8}
                    className={"padding-left-0 padding-right-10"}
                  >
                    <Dropdown
                      label={t("Category")}
                      options={categoryLookups.map(
                        lookupToOptionsTranslation(t)
                      )}
                      name="category"
                    />
                    <Dropdown
                      label={t("Role")}
                      options={roleLookups.map(lookupToOptionsTranslation(t))}
                      name="role"
                    />
                    <Dropdown
                      label={t("Period")}
                      options={periodLookups.map(lookupToOptionsTranslation(t))}
                      name="period"
                    />

                    <Dropdown
                      label={t("Teaching Staff")}
                      options={yesNoOptions}
                      name="teachStaff"
                    />
                    <Dropdown
                      label={t("Working with 3,4 year olds")}
                      options={yesNoOptions}
                      name="work3yr4yr"
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={8}
                    className={"padding-left-0 padding-right-0"}
                  >
                    <TextArea label={t("Description")} name="description" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button
                onClick={() => {
                  setRefetch(!refetch);
                  setShowDetails(false);
                  methods.reset();
                }}
                color="orange"
              >
                <Icon name="arrow circle left" />
                {t("Back")}
              </Button>
              <UserAccessComponent requires="PPORT_PM_ST_EXP_D_SUB">
                <Button className={"green"} type="submit" disabled={saving}>
                  <Icon name="check circle" />
                  {t("Submit")}
                </Button>
              </UserAccessComponent>
            </Form>
          </FormProvider>
        )}
      </Segment>
    </>
  );
}

export default ExperienceScreen;
