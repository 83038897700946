import React, { useEffect, useState } from "react";
import { Form, Icon, Popup, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import useLookup from "../../consumer/LookupConsumer";
import axios from "axios";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  FlagFormatter,
  Grid as DataGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import { concat, insert, insertAll } from "ramda";
import UserAccessComponent from "../../components/UserAccessComponent";
import { ButtonNewChild } from "@caci/react-form-fields";

const LiveRegister = () => {
  const { t } = useTranslation();
  const { providerId } = useParams();
  const { lookups: languages } = useLookup(102);
  const { lookups: ethnicities } = useLookup(101);
  const { lookups: leaCodes } = useLookup(301);
  const history = useHistory();

  const [includeChildren, setIncludeChildren] = useState("HPC");
  const [sectionView, setSectionView] = useState("FV");
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (providerId) {
      getEarlyAssessmentList();
    }
  }, [providerId]);

  useEffect(() => {
    getEarlyAssessmentList();
  }, []);

  const getEarlyAssessmentList = () => {
    axios
      .get(`/api/eyp-child/live-register/${providerId}`)
      .then(({ data: earlyAssessmentList }) => setRows(earlyAssessmentList))
      .catch(console.log);
  };

  const handleDropdownChange = (event, { value, name }) => {
    if (name === "sectionView") {
      setSectionView(value);
    } else if (name === "includeChildren") {
      setIncludeChildren(value);
    }
  };

  const transformRows = (rows) => {

    const filteredData = rows.filter((child) => {
      if (includeChildren === "HPC") {
        return (
          child.childEndDate === null &&
          child.childNeverAttendInd !== 'Y'
        );
      } else if (includeChildren === 'IPC') {
        return child.childNeverAttendInd !== 'Y';
      }
    });

    filteredData.map((child) => {
      //childName
      const forename = child.forename || '';
      const middleName = child.middleName ? ` ${child.middleName}` : '';
      const surname = child.surname || '';
      child['childName'] = `${forename}${middleName} ${surname}`.trim();

      //responsibleLa
      const leaDescription = leaCodes.find((y) => y.codeValue === child.areaCodeValue)?.description;
      child['responsibleLa'] = leaDescription ? `${leaDescription}(${child.areaCodeValue})` : '';

      //parentCarerName
      const parentForename = child.parentForename || '';
      const parentSurname = child.parentSurname || '';
      const parentId = child.parentId || '';

      if (parentId) {
        child['parentCarerName'] = `${parentForename} ${parentSurname}(${parentId})`.trim();
      } else {
        child['parentCarerName'] = '';
      }
    });
    return filteredData;
  };

  const getColumns = () => {
    const addressCol = {
      key: "address",
      name: t("Address"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      allowHide: true,
      width: 200,
    };

    const fundingTypeCol = {
      key: "fundingType",
      name: t("Flags"),
      filterable: true,
      sortable: true,
      filterRenderer: LookupFilter,
      lookupcodes: ["EYPP", "DAF", "EAL"],
      formatter: FlagFormatter,
    };

    const columns = [
      {
        key: "pupilId",
        name: t("Impulse ID"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        width: 75,
      },
      {
        key: "childId",
        name: t("EYC ID"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        width: 75,
      },
      {
        key: "childName",
        name: t("Child Name"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        width: 150,
      },
      {
        key: "dateOfBirth",
        name: t("Birthdate"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        formatter: DateFormatter,
        width: 100,
      },
      {
        key: "fundingAge",
        name: t("Funding Age"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        width: 100,
      },

      {
        key: "childStartDate",
        name: t("Start Date"),
        filterable: true,
        sortable: true,
        filterRenderer: DateFilter,
        formatter: DateFormatter,
        width: 100,
      },
      {
        key: "childEndDate",
        name: t("End Date"),
        filterable: true,
        sortable: true,
        filterRenderer: DateFilter,
        formatter: DateFormatter,
        width: 100,
      },
    ];

    const masterViewCols = [
      {
        key: "ethnicity",
        name: t("Ethnicity"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        lookupcodes: ethnicities,
        formatter: LookupFormatter,
        allowHide: true,
        width: 100,
      },
      {
        key: "language",
        name: t("Language"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        lookupcodes: languages,
        formatter: LookupFormatter,
        allowHide: true,
        width: 100,
      },
      {
        key: "deprivationIndex",
        name: t("Deprivation Index"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        allowHide: true,
        width: 100,
      },
      {
        key: "responsibleLa",
        name: t("Responsible LA"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 150,
      },
    ];

    const contactViewCols = [
      {
        key: "parentCarerName",
        name: t("Parent or Carer Name (ID)"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 150,
      },
      {
        key: "personId",
        name: t("Person ID"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 100,
      },
      {
        key: "contactNo",
        name: t("Parent or Carer Contact Number"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 120,
      },
      {
        key: "email",
        name: t("Parent or Carer Email"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 150,
      },
    ];

    const fundingViewCols = [
      {
        key: "stretchedInd",
        name: t("Stretched claim"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 100,
      },
      {
        key: "expHrsPerWeek",
        name: t("Expanded Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 100,
      },
      {
        key: "uniHrsPerWeek",
        name: t("Universal Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 100,
      },
      {
        key: "extHrsPerWeek",
        name: t("Extended Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 100,
      },
      {
        key: "fundedWeeks",
        name: t("Weeks"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 100,
      },
      {
        key: "totalFundingHours",
        name: t("Total Funded Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 100,
      },
    ];

    if (sectionView === "CV") {
      const cvCols = insert(0, addressCol, contactViewCols);
      return insertAll(5, cvCols, columns);
    } else if (sectionView === "FV") {
      const fVCols = insertAll(5, [...fundingViewCols], columns);
      return concat(fVCols, [fundingTypeCol]);
    } else {
      const mvCols = insert(2, addressCol, masterViewCols);
      return insertAll(
        5,
        [...mvCols, ...fundingViewCols, ...contactViewCols, fundingTypeCol],
        columns
      );
    }
  };

  return (
    <div>
      <Form>
        <Form.Group widths={6}>
          <Form.Dropdown
            style={{ maxWidth: 300 }}
            fluid
            selection
            search
            onChange={handleDropdownChange}
            value={sectionView}
            name="sectionView"
            options={[
              { text: t("Master View"), value: "MV" },
              { text: t("Funding View"), value: "FV" },
              { text: t("Contact View"), value: "CV" },
            ]}
          />
          <Form.Dropdown
            style={{ maxWidth: 300 }}
            fluid
            selection
            search
            onChange={handleDropdownChange}
            value={includeChildren}
            name="includeChildren"
            options={[
              { text: t("Hide previous children"), value: "HPC" },
              { text: t("Include previous children"), value: "IPC" },
            ]}
          />
        </Form.Group>
      </Form>

      <Segment attached="bottom">
        <DataGrid
          id="_LiveRegisterGrid"
          actionsCell={({ row }) => (
            <div style={{ textAlign: "center" }}>
              <UserAccessComponent requires="PPORT_EYF_LR_CHILD_EDIT">
                <Popup
                  trigger={
                    <Icon
                      circular
                      link
                      name="pencil"
                      className={"dark-blue-border"}
                      onClick={() =>
                        history.push(
                          `/portal/${providerId}/provider/provider-funding/live-register/${row.childId}`
                        )
                      }
                    />
                  }
                  content={t("Edit")}
                />
              </UserAccessComponent>
              <Popup
                trigger={
                  <Icon
                    className={"light-green-border"}
                    circular
                    link
                    name="child"
                    onClick={() =>
                      history.push(
                        `/portal/${providerId}/provider/provider-funding/eyp-child-view/${row.id}`
                      )
                    }
                  />
                }
                content={t("View")}
              />
            </div>
          )}
          columns={getColumns()}
          minHeight={550}
          filterable
          rows={transformRows(rows)}
        />
        <br />
        <UserAccessComponent requires="PPORT_EYF_LR_CHILD_ADD">
          <ButtonNewChild
            id={"newChildButton"}
            as={Link}
            to={`/portal/${providerId}/provider/provider-funding/live-register/new`}
            useForm={false}
          />
        </UserAccessComponent>
      </Segment>
    </div>
  );
};

export default LiveRegister;
