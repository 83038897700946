import { Grid, List } from 'semantic-ui-react';

const ProviderHeaderNoAddress = (props) => (
    <Grid stackable stretched className={'no-border'}>
        <Grid.Column width={2} verticalAlign="middle" textAlign="center" className="padding-bottom-14">
            {props.icon}
        </Grid.Column>

        <Grid.Column width={14} verticalAlign="middle" className="padding-bottom-14">
            <List size="small">
                <List.Item>
                    <List.Content>
                        <List.Header>{props.display}</List.Header>
                    </List.Content>
                </List.Item>
            </List>
        </Grid.Column>
    </Grid>
);

export default ProviderHeaderNoAddress;