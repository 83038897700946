import { useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import UserAccessComponent from "./UserAccessComponent";
import { useTranslation } from "react-i18next";
import { useSession } from "../consumer/SessionConsumer";
import { useToasts } from "react-toast-notifications";
import { lookupCodeToValue } from "../utils";

import useLookup from "../consumer/LookupConsumer";

function SafeguardingDetails({ row = {}, onBack, safeguardCodeValid = [] }) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { lookups: noteOfVisitSafeguarding } = useLookup(6115);

  return (
    <Form>
      <Form.Input
        name="safeguardCode"
        value={t(lookupCodeToValue(noteOfVisitSafeguarding, row.safeguardCode))}
        label={t("Status")}
        readOnly
      />
      <Form.TextArea label="Comments" value={row.comments} readOnly />
      <br />
      <Button onClick={() => onBack(false)} color="orange">
        <Icon name="arrow circle left" />
        {t("Back")}
      </Button>
    </Form>
  );
}

export default SafeguardingDetails;
