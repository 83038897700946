import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import CourseEvaluationProfile from "./CourseEvaluationProfile";
import { useSession } from "../consumer/SessionConsumer";

function CourseEvaluationProfileModal({ isModalOpen, onClose, course }) {
  const { t } = useTranslation();
  const { selectedProvider: provider } = useSession();

  return (
    <Modal size="large" centered={false} open={isModalOpen} onClose={onClose}>
      <Modal.Header>{t("Course Evaluation")}</Modal.Header>
      <Modal.Content>
        <CourseEvaluationProfile
          recordId={course.courseDatesId}
          profileId={course.profileId}
          providerId={provider.providerId}
          isNew={false}
          onClose={onClose}
        />
      </Modal.Content>
    </Modal>
  );
}

export default CourseEvaluationProfileModal;
