import { useReducer } from "react";
import { LookupContext } from "../context";
import axios from "axios";

function lookupReducer(state = {}, action) {
  switch (action.type) {
    case "SET_LOOKUP":
      return { ...state, ...action.lookup };
    case "SET_LOADING":
      return { ...state, ...action.loading };
    default:
      return state;
  }
}

export const getLoadingKey = codeType => `loading_${codeType}`;

function LookupProvider({ children }) {
  const [state, dispatch] = useReducer(lookupReducer, {});

  const setLoadingObj = (codeType, loading) => ({
    type: "SET_LOADING",
    loading: { [getLoadingKey(codeType)]: loading }
  });

  const fetchLookup = codeType => {
    if (codeType && !(codeType in state) && !state[getLoadingKey(codeType)]) {
      dispatch(setLoadingObj(codeType, true));
      axios
        .get(`/api/lookup-codes/${codeType}`)
        .then(({ data: lookup }) => {
          dispatch({ type: "SET_LOOKUP", lookup: { [codeType]: lookup } });
          dispatch(setLoadingObj(codeType, false));
        })
        .catch(error => {
          console.log(error);
          dispatch(setLoadingObj(codeType, false));
        });
    }
  };

  const value = { ...state, fetchLookup };

  return (
    <LookupContext.Provider value={value}>{children}</LookupContext.Provider>
  );
}

export default LookupProvider;
