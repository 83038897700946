import UserAccessComponent from "../components/UserAccessComponent";
import { Button, Icon, Popup, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
    DateFilter,
    DateFormatter,
    DefaultFilter,
    Grid as StaffGrid,
    LookupFilter,
    LookupFormatter
} from "@caci/react-grid";
import { useHistory, useLocation } from "react-router-dom";
import DeleteConfirmationModal from "../components/DeleteConfirmationModal";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import useLookup from "../consumer/LookupConsumer";

function WellcommAssessmentGridScreen({ rows, setRow, refetch, setRefetch }) {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const { addToast } = useToasts();
    const { lookups: termLookups } = useLookup(6113);

    const handleDelete = row => {
        axios
            .delete(`/api/wellcomm-assessment/${row.id}`)
            .then(() => {
                addToast(`${t("WellComm Assessment")} ${t("has been successfully removed")}`, {
                    appearance: "success"
                });
                if (!!setRefetch) setRefetch(!refetch);
            })
            .catch(error => {
                console.log(error);
                addToast(`${t("WellComm Assessment")} ${t("has not been removed")}`, {
                    appearance: "error"
                });
            });
    };

    return (
        <Segment className="grid-container-550">
            <StaffGrid
                actionsCell={({ row }) => (
                    <div style={{ textAlign: "center" }}>
                        <UserAccessComponent requires="PPORT_PM_WC_L_EDIT">
                            <Popup
                                content={t("Edit")}
                                trigger={
                                    <Icon
                                        circular
                                        name="pencil"
                                        className={"dark-blue-border"}
                                        color="black"
                                        onClick={() => {
                                            setRow(row);
                                            history.push(`${pathname}/${row.id}`);
                                        }}
                                    />
                                }
                            />
                        </UserAccessComponent>

                        <UserAccessComponent requires="PPORT_PM_WC_L_DEL">
                            <DeleteConfirmationModal
                                message={t(
                                    "Are you sure you want to delete this WellComm Assessment?"
                                )}
                                onDelete={() => handleDelete(row)}
                                iconName="trash alternate outline"
                                color="red-border"
                                circular
                            />
                        </UserAccessComponent>
                    </div>
                )}
                columns={[
                    {
                        key: "id",
                        name: t("Assessment ID"),
                        filterable: true,
                        sortable: true,
                        filterRenderer: DefaultFilter
                    },
                    {
                        key: "termCode",
                        name: t("Term"),
                        filterable: true,
                        sortable: true,
                        lookups: termLookups,
                        formatter: LookupFormatter,
                        filterRenderer: LookupFilter
                    },
                    {
                        key: "completedBy",
                        name: t("Completed By"),
                        filterable: true,
                        sortable: true,
                        filterRenderer: DefaultFilter
                    },
                    {
                        key: "dateCompleted",
                        name: t("Date Completed"),
                        filterable: true,
                        sortable: true,
                        formatter: DateFormatter,
                        filterRenderer: DateFilter
                    }
                ]}
                rows={rows}
            />
            <div className={"gap-btw-grid-buttons"}>
                <UserAccessComponent requires="PPORT_PM_WC_L_ADD">
                    <Button
                        onClick={() => history.push(`${pathname}/new`)}
                        className={"purple"}
                    >
                        <Icon>
                            <i className="fas fa-traffic-light" />
                        </Icon>
                        {t("New Assessment")}
                    </Button>
                </UserAccessComponent>
            </div>
        </Segment>
    );
}

export default WellcommAssessmentGridScreen;