import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Segment,
  Header,
} from "semantic-ui-react";
import { Input, Dropdown, TextArea } from "@caci/react-form-fields";
import {
  lookupToOptionsTranslation,
  setValueHelper,
  yesNoOptions,
} from "../utils";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import React from "react";
import RejectCommentsModal from "./RejectCommentsModal";
import useLookup from "../consumer/LookupConsumer";
import { useTranslation } from "react-i18next";

const validationFlagsList = ["1", "2", "3"];

function ProviderDetailsScreen({ setValidationFlag, urlSlug }) {
  const { selectedProvider: provider, updateSelectedProvider } = useSession();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);
  const { lookups: providerPartnershipWorkings } = useLookup(6090);

  const string100Validation = yup
    .string()
    .max(100, t("100 character limit exceeded"))
    .nullable();

  const string400Validation = yup
    .string()
    .max(400, t("400 character limit exceeded"))
    .nullable();

  const numeric10Validation = yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(10, "10 character limit exceeded");

  const schema = yup.object({
    websiteAdd: string100Validation,
    admissionPolicy: string400Validation,
    yearFrom: numeric10Validation,
    monthFrom: numeric10Validation,
    yearTo: numeric10Validation,
    monthTo: numeric10Validation,
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({
    websiteAdd,
    fund2yrInd,
    prov30hFunding,
    caterForOverEights,
    admissionPolicy,
    byPhone,
    infoAvalInter,
    mailingList,
    uploadInternet,
    toThridParty,
    taxFreeMemberInd,
    taxFreeSignUpInd,
    partWorkInd,
    partWorkInterest,
    yearFrom,
    monthFrom,
    yearTo,
    monthTo,
  }) => {
    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/websiteAdd", value: websiteAdd },
      { op: "add", path: "/fund2yrInd", value: fund2yrInd },
      { op: "add", path: "/prov30hFunding", value: prov30hFunding },
      { op: "add", path: "/caterForOverEights", value: caterForOverEights },
      { op: "add", path: "/admissionPolicy", value: admissionPolicy },
      { op: "add", path: "/byPhone", value: byPhone },
      { op: "add", path: "/infoAvalInter", value: infoAvalInter },
      { op: "add", path: "/mailingList", value: mailingList },
      { op: "add", path: "/uploadInternet", value: uploadInternet },
      { op: "add", path: "/toThridParty", value: toThridParty },
      { op: "add", path: "/taxFreeMemberInd", value: taxFreeMemberInd },
      { op: "add", path: "/taxFreeSignUpInd", value: taxFreeSignUpInd },
      { op: "add", path: "/partWorkInd", value: partWorkInd },
      { op: "add", path: "/partWorkInterest", value: partWorkInterest },
      { op: "add", path: "/yearFrom", value: yearFrom },
      { op: "add", path: "/monthFrom", value: monthFrom },
      { op: "add", path: "/yearTo", value: yearTo },
      { op: "add", path: "/monthTo", value: monthTo },
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    axios
      .put(`/api/pport-early-provider/${provider.providerId}`, put, putObject)
      .then(({ data }) => {
        addToast(
          `${t("Provider Details")} ${t("has been successfully updated")}`,
          {
            appearance: t("success"),
          }
        );
        if (data && Object.keys(data).length > 0) updateSelectedProvider(data);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (provider && Object.keys(provider).length > 0) {
        const validationFlagIndicator = validationFlagsList.includes(
            provider.validationFlag
        );
        setValidationFlag(urlSlug, {
            name: "providerDetails",
            value: validationFlagIndicator
        });
        setShowExclamation(validationFlagIndicator);
    }
    setValueHelper(provider, methods.setValue);
  }, [provider]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Provider Details")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <>
              <Menu.Item>
                <UserAccessComponent requires="PPORT_PM_PR_D_EDIT">
                  <RejectCommentsModal
                    rejectComments={provider.rejectComments}
                    rejectedDate={provider.rejectedDate}
                    rejectedBy={provider.rejectedBy}
                    color={"orange"}
                  />
                </UserAccessComponent>
              </Menu.Item>
              <Menu.Item>
                <Icon color={"red"}>
                  <i className="fas fa-exclamation-triangle" />
                </Icon>
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid stackable>
              <Grid.Row
                className={"padding-top-0 padding-bottom-0"}
                style={{ paddingBottom: "3px" }}
              >
                <Grid.Column
                  width={16}
                  className={"padding-left-0 padding-right-10"}
                >
                  <Input label={t("Website")} name="websiteAdd" />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("2-Year-Old Funding")}
                    name="fund2yrInd"
                    options={yesNoOptions}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Extended Funding")}
                    name="prov30hFunding"
                    options={yesNoOptions}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Cater for over eights")}
                    name="caterForOverEights"
                    options={yesNoOptions}
                  />

                  <TextArea
                    name="admissionPolicy"
                    label={t("Description (Admissions Policy)")}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("By Telephone")}
                    name="byPhone"
                    options={yesNoOptions}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("On the Internet")}
                    name="infoAvalInter"
                    options={yesNoOptions}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("In Writing")}
                    name="mailingList"
                    options={yesNoOptions}
                  />
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Upload Telephone on the Internet")}
                    name="uploadInternet"
                    options={yesNoOptions}
                  />

                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("To Third Parties")}
                    name="toThridParty"
                    options={yesNoOptions}
                  />

                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Tax Free Childcare Scheme Member?")}
                    name="taxFreeMemberInd"
                    options={yesNoOptions}
                  />

                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Do you intend on signing up next term?")}
                    name="taxFreeSignUpInd"
                    options={yesNoOptions}
                  />

                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Currently Partnership working?")}
                    name="partWorkInd"
                    options={yesNoOptions}
                  />

                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Interested in Partnership working?")}
                    name="partWorkInterest"
                    options={providerPartnershipWorkings.map(
                      lookupToOptionsTranslation(t)
                    )}
                  />

                  <Segment>
                    <Grid.Row>
                      <Grid.Column
                        width={16}
                        className={"padding-left-0  padding-bottom-10"}
                      >
                        <Header
                          as="h5"
                          textAlign="center"
                          className={"text-white-color"}
                        >
                          {t('Age Ranges')}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={16} className={"padding-left-0"}>
                        <Grid.Column
                          width={8}
                          className={"padding-left-0 padding-bottom-10"}
                        >
                          <Input
                            label={t("From Year / Month")}
                            name="yearFrom"
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          className={"padding-left-0  padding-bottom-10"}
                        >
                          <Input name="monthFrom" />
                        </Grid.Column>
                      </Grid.Column>
                      <Grid.Column width={16} className={"padding-left-0"}>
                        <Grid.Column
                          width={8}
                          className={"padding-left-0 padding-bottom-10"}
                        >
                          <Input label={t("To Year / Month")} name="yearTo" />
                        </Grid.Column>
                        <Grid.Column
                          width={8}
                          className={"padding-left-0  padding-bottom-10"}
                        >
                          <Input name="monthTo" />
                        </Grid.Column>
                      </Grid.Column>
                    </Grid.Row>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <UserAccessComponent requires="PPORT_PM_PR_D_SUB">
              <Button className={"green"} type="submit">
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Segment>
    </>
  );
}

export default ProviderDetailsScreen;
