import { Container, Grid, Icon, Menu } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import EYFChildDetails from "./EYFChildDetails";
import EYFParentDetails from "./EYFParentDetails";
import EYFChildDocs from "./EYFChildDocs";

const EYFChildDetailsScreen = () => {
  const { t } = useTranslation();

  const [activeItem, setActiveItem] = useState("parents");

  return (
    <div>
      <Container fluid>
        <Grid stackable>
          <Grid.Row className={"padding-top-0"}>
            <Grid.Column
              width={4}
              className={"padding-left-0 padding-right-10"}
            >
              <EYFChildDetails />
            </Grid.Column>
            <Grid.Column
              width={12}
              className={"padding-left-0 padding-right-10"}
            >
              <Menu attached="top" className={"border-bottom"}>
                <Menu.Item
                  id={"eyfParentTab"}
                  active={activeItem === "parents"}
                  onClick={() => setActiveItem("parents")}
                >
                  <Icon>
                    <i className="fa-solid fa-person"></i>
                  </Icon>
                  {t("Parent/Carer Details")}
                </Menu.Item>
                <Menu.Item
                  id={"eyfDocumentsTab"}
                  active={activeItem === "documents"}
                  onClick={() => setActiveItem("documents")}
                >
                  <Icon>
                    <i className="fa-regular fa-file-magnifying-glass"></i>
                  </Icon>
                  {t("Documents")}
                </Menu.Item>
              </Menu>
              {activeItem === "parents" && <EYFParentDetails />}
              {activeItem === "documents" && <EYFChildDocs />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default EYFChildDetailsScreen;
