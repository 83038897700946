import React, { useEffect, useState } from "react";
import fileDownload from "react-file-download";
import UserAccessComponent from "./UserAccessComponent";
import { DefaultFilter, Grid as FilesGrid } from "@caci/react-grid";
import { Button, Icon, Menu, Popup, Segment, Message } from "semantic-ui-react";
import useGridRows from "../hooks/useGridRows";
import axios from "axios";
import { useTranslation } from "react-i18next";
import FileUploadModal from "./FileUploadModal";

function AssociatedFiles({
  getApi,
  downloadAccessCode,
  showNewButton,
  newUserAccessCode,
  postApi,
  tableId,
  appendedName,
  providerId,
  hideMenu,
  ...portalContent
}) {
  const { rows, setRows } = useGridRows();
  const { t } = useTranslation();
  const [uploadError, setUploadError] = useState();
  const [refetch, setRefetch] = useState(false);

  const handleDownload = (id, filename) => {
    axios
      .get(`/api/files/download-file/${id}`, {
        responseType: "arraybuffer"
      })
      .then(response => {
        fileDownload(response.data, filename, response.headers["content-type"]);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    const fetchAssociatedFiles = () => {
      if (getApi)
        axios
          .get(getApi)
          .then(({ data }) => setRows(data))
          .catch(console.log);
    };
    fetchAssociatedFiles();
  }, [getApi, refetch]);

  return (
    <>
        <FilesGrid
          actionsCell={({ row }) => (
            <div style={{ textAlign: "center" }}>
              <UserAccessComponent requires={downloadAccessCode}>
                <Popup
                  content={t("Download")}
                  trigger={
                    <Icon
                      circular
                      name="download"
                      className={"dark-blue-border"}
                      color="black"
                      onClick={() => handleDownload(row.id, row.filename)}
                    />
                  }
                />
              </UserAccessComponent>
            </div>
          )}
          columns={[
            {
              key: "id",
              name: t("File ID"),
              sortable: true,
              filterable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "filename",
              name: t("File Name"),
              sortable: true,
              filterable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "application",
              name: t("File Type"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "docSize",
              name: t("File Size"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter
            }
          ]}
          rows={rows}
        />
        <Message
            hidden={!uploadError}
            onDismiss={() => setUploadError("")}
            header={t("Upload Failed")}
            content={uploadError}
            negative
        />
      <br/>
        {showNewButton && (
          <UserAccessComponent requires={newUserAccessCode}>
            <FileUploadModal
              {...portalContent}
              name={"attach"}
              title={"New Associated Files"}
              postApi={postApi}
              appendedName={appendedName}
              providerId={providerId}
              setError={setUploadError}
              setRefetch={() => setRefetch(!refetch)}
              tableId={tableId}
            />
          </UserAccessComponent>
        )}
    </>
  );
}

export default AssociatedFiles;
