import { Icon, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function LoadingMessage() {
    const { t } = useTranslation();
    return (
        <Message icon info>
            <Icon name="circle notched" loading />
            <Message.Content>
                <Message.Header>{t('Just one second')}</Message.Header>
                {t('We are fetching that content for you.')}
            </Message.Content>
        </Message>
    );
}

export default LoadingMessage;