import { Segment } from "semantic-ui-react";

function Headcount() {
  return (
    <Segment className={"grid-container-550"}>
      <p>Headcount</p>
    </Segment>
  );
}

export default Headcount;
