import { useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import WellcommAssessmentDetails from "../components/WellcommAssessmentDetails";
import WellcommAssessmentScoreScreen from "../components/WellcommAssessmentScoreScreen";
import WellcommAssessmentMovement from "../components/WellCommAssessmentMovement";
import NewAssessments from "../components/NewAssessments";

function WellcommAssessmentDetailsScreen({
  setRefetch,
  refetch,
  row = {},
  setRow,
  rows
}) {
  const { wellcomAssessId } = useParams();

  const isNewRecord = () => wellcomAssessId === "new";

  useEffect(() => {
    return () => {
      setRefetch(!refetch);
      setRow({});
    };
  }, []);

  return (
    <Grid stackable>
      <Grid.Row className={"padding-top-0"}>
        <Grid.Column width={4} className={"padding-left-0 padding-right-10"}>
          <WellcommAssessmentDetails
            row={row}
            setRow={setRow}
            isNewRecord={isNewRecord()}
            rows={rows}
          />
        </Grid.Column>
        <Grid.Column width={12} className={"padding-left-0 padding-right-3"}>
          <WellcommAssessmentScoreScreen
            wellcomAssessId={wellcomAssessId}
            isNewRecord={isNewRecord()}
            dateCompleted={row.dateCompleted}
          />

          <WellcommAssessmentMovement
            wellcomAssessId={wellcomAssessId}
            isNewRecord={isNewRecord()}
            dateCompleted={row.dateCompleted}
          />

          <NewAssessments
            row={row}
            wellcomAssessId={wellcomAssessId}
            isNewRecord={isNewRecord()}
            dateCompleted={row.dateCompleted}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default WellcommAssessmentDetailsScreen;
