import React, { useEffect, useState } from "react";
import { Container, Grid, Menu, Segment } from "semantic-ui-react";
import VisitDetails from "../components/VisitDetails";
import PurposeVisitScreen from "../components/PurposeVisitScreen";
import SafeguardingScreen from "../components/SafeguardingScreen";
import ActionListScreen from "../components/ActionListScreen";

import axios from "axios";
import { withRouter } from "react-router-dom";
import AssociatedFiles from "../components/AssociatedFiles";
import ProfileOutput from "../components/ProfileOutput";

function NotesOfVisitDetailsScreen({ match, location }) {
  const [rows, setRows] = useState([]);
  const [visit, setVisit] = useState([]);

  const fetchVisitDetails = () => {
    axios
      .get(`/api/early-visit-Note/${match.params.visitId}`)
      .then(({ data: rows }) => {
        setVisit(rows);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchVisitDetails();
  }, []);

  return (
    <>
      <Container fluid>
        <Grid stackable>
          <Grid.Row className={"padding-top-0"}>
            <Grid.Column
              width={4}
              className={"padding-left-0 padding-right-10"}
            >
              <VisitDetails rows={visit} />
            </Grid.Column>
            <Grid.Column width={12} className={"padding-left-right-0"}>
              <Grid>
                <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                  <Grid.Column
                    width={8}
                    className={"padding-left-0 padding-right-10"}
                  >
                    <PurposeVisitScreen visitId={match.params.visitId} />
                  </Grid.Column>
                  <Grid.Column width={8} className={"padding-left-right-0"}>
                    <SafeguardingScreen visitId={match.params.visitId} />
                  </Grid.Column>
                  <Grid.Column width={16} className={"padding-left-right-0"}>
                    <ActionListScreen visitId={match.params.visitId} />
                  </Grid.Column>
                  <Grid.Column width={16} className={"padding-left-right-0"}>
                      <Menu attached="top" className={"border-bottom"}>
                        <Menu.Item header className={"active"}>
                          {"Associated Files"}
                        </Menu.Item>
                      </Menu>
                    <Segment attached="bottom">
                      <AssociatedFiles
                        showNewButton
                        getApi={
                          !!match.params.visitId
                            ? `/api/early-visit-note-document/${match.params.visitId}`
                            : ""
                        }
                        tableId={match.params.visitId}
                        appendedName="visitNoteId"
                        postApi={"/api/early-visit-note-document/upload"}
                        downloadAccessCode={"PPORT_PM_NOV_F_L_DOWN"}
                        newUserAccessCode={"PPORT_PM_NOV_F_L_ADD"}
                        headerContentModule="Assessment"
                        headerContentArea="Notes of Visit Attachment"
                        headerContentItem="Attach Files Disclaimer Header"
                        bodyContentModule="Assessment"
                        bodyContentArea="Notes of Visit Attachment"
                        bodyContentItem="Attach Files Disclaimer Text"
                    />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={16} className={"padding-left-right-0"}>
                    <ProfileOutput
                      title="Visit Notes Output"
                      generateUserAccessCode="PPORT_PM_NOV_OUT_D_GEN"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
}

export default withRouter(NotesOfVisitDetailsScreen);
