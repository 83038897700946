import {Fragment, useEffect, useState} from "react";
import {Button, Form, Grid, Icon, Menu, Modal, Popup,} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {DateFilter, DateFormatter, DefaultFilter, Grid as NoticeGrid,} from "@caci/react-grid";
import {Input} from "@caci/react-form-fields";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import axios from "axios";
import {useToasts} from "react-toast-notifications";
import {useSession} from "../consumer/SessionConsumer";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import RejectCommentsModal from "./RejectCommentsModal";
import {setValueHelper} from "../utils";

const schema = yup.object({
  name: yup.string().required("Required"),
});

const putObject = {
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};

function PeriodManagementModal({
  isPeriodModalOpen,
  closePeriodModal,
  rows,
  props,
  fetchEarlyHolidays,
  validationFlagsList,
  showExclamation,
}) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [showActionDetails, setShowActionDetails] = useState(false);
  const { selectedProvider: provider } = useSession();
  const { addToast } = useToasts();

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleDelete = (id) => {
    const data = [{ op: "add", path: "/validationFlag", value: "D" }];
    axios
      .put(`/api/pport-early-holiday/${id}`, data, putObject)
      .then(() => {
        addToast(`${t("Period")} ${t("has been successfully removed")}`, {
          appearance: ("success"),
        });
        fetchEarlyHolidays();
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Period")} ${t("has been successfully removed")}`, {
          appearance: ("error"),
        });
      });
  };

  const onSubmit = ({ name, holidayId, dateFrom, dateTo }) => {
    const data = {
      providerId: provider.providerId,
      name: name,
      dateFrom: dateFrom,
      dateTo: dateTo,
      validationFlag: "I",
      overnightCcInd: 'N',
    };

    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/name", value: name },
      { op: "add", path: "/dateFrom", value: dateFrom },
      { op: "add", path: "/dateTo", value: dateTo },
    ];

    if (holidayId) {
      axios
        .put(`/api/pport-early-holiday/${holidayId}`, put, putObject)
        .then(() => {
          addToast(`${t("Period")} ${t("has been successfully updated")}`, {
            appearance: ("success"),
          });
          fetchEarlyHolidays();
        })
        .catch(console.log);
    } else {
      axios
        .post(`/api/pport-early-holiday`, data)
        .then(() => {
          addToast(`${t("Period")} ${t("has been successfully created")}`, {
            appearance: ("success"),
          });
          fetchEarlyHolidays();
        })
        .catch(console.log);
    }
  };
  const { reset } = methods;

  useEffect(() => {
    return () => {
      reset({
        name: "",
        id: "",
      });
    };
  }, [provider.providerId]);
  return (
    <div>
      <Modal
        open={isPeriodModalOpen}
        size="small"
        centered={false}
        className="dark-modal"
      >
        <Modal.Header className={"modal-header"}>
          {!showActionDetails
            ? t("Hours, Charges & Places - Periods")
            : t("Period Details")}
          {!showActionDetails && showExclamation && (
            <Icon color={"red"} style={{ float: "right" }}>
              <i className="fas fa-exclamation-triangle" />
            </Icon>
          )}
        </Modal.Header>
        <Modal.Content className={"modal-content"}>
          {!showDetail ? (
            <Fragment>
              <div>
                <NoticeGrid
                  actionsCell={({ row }) => (
                    <div style={{ textAlign: "center" }}>
                      <UserAccessComponent requires="PPORT_PM_HCP_PER_L_EDIT">
                        <Popup
                          trigger={
                            <Icon
                              circular
                              link
                              name="pencil"
                              className={"dark-blue-border"}
                              onClick={() => {
                                setValueHelper(row, methods.setValue);
                                setShowDetail(true);
                              }}
                              disabled={row.name === "Default Period"}
                            />
                          }
                          content={t("Edit")}
                        />
                      </UserAccessComponent>
                      <UserAccessComponent requires="PPORT_PM_HCP_PER_L_EDIT">
                      <RejectCommentsModal
                        rejectComments={row.rejectComments}
                        rejectedDate={row.rejectedDate}
                        rejectedBy={row.rejectedBy}
                        disabled={
                          !validationFlagsList.includes(row.validationFlag)
                        }
                        circular
                      />
                      </UserAccessComponent>
                      <UserAccessComponent requires="PPORT_PM_HCP_PER_L_DEL">
                        <DeleteConfirmationModal
                          message={t(
                            "Are you sure you want to delete this Period ?"
                          )}
                          onDelete={() => handleDelete(row.holidayId)}
                          iconName="trash alternate outline"
                          color="red-border"
                          circular
                        />
                      </UserAccessComponent>
                    </div>
                  )}
                  columns={[
                    {
                      key: "holidayId",
                      name: t("ID"),
                      filterable: true,
                      sortable: true,
                      filterRenderer: DefaultFilter,
                    },
                    {
                      key: "name",
                      name: t("Period Name"),
                      filterable: true,
                      sortable: true,
                      filterRenderer: DefaultFilter,
                    },
                    {
                      key: "dateFrom",
                      name: t("Date From"),
                      filterable: true,
                      sortable: true,
                      filterRenderer: DateFilter,
                      formatter: DateFormatter,
                    },
                    {
                      key: "dateTo",
                      name: t("Date To"),
                      filterable: true,
                      sortable: true,
                      filterRenderer: DateFilter,
                      formatter: DateFormatter,
                    },
                  ]}
                  rows={rows}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Grid stackable>
                    <Grid.Row
                      className={"padding-top-0"}
                      style={{ paddingBottom: "3px" }}
                    >
                      <Grid.Column
                        width={8}
                        className={"padding-left-0 padding-right-10"}
                      >
                        <Input
                          label={t("Period ID")}
                          name="holidayId"
                          readOnly
                        />
                        <Input label={t("Name")} name={"name"} />

                        <Input
                          name="dateFrom"
                          label={t("Date From")}
                          type="date"
                        />

                        <Input name="dateTo" label={t("Date To")} type="date" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <br />
                  <Button
                    onClick={() => {
                      setShowDetail(false);
                      reset();
                    }}
                    className={"orange"}
                    type="button"
                  >
                    <Icon name="arrow circle left" />
                    {t("Back")}
                  </Button>
                  <UserAccessComponent requires="PPORT_PM_HCP_PER_D_SUB">
                    <Button type="submit" className={"green"}>
                      <Icon name="check circle" />
                      {t("Submit")}
                    </Button>
                  </UserAccessComponent>
                </Form>
              </FormProvider>
            </Fragment>
          )}

          <br />
          {!showDetail && (
            <Fragment>
              <Button onClick={() => closePeriodModal()} color="orange">
                <Icon name="arrow circle left" />
                {t("Back")}
              </Button>
              <UserAccessComponent requires="PPORT_PM_HCP_PER_L_ADD">
                <Button
                  onClick={() => setShowDetail(true)}
                  className={"purple"}
                >
                  <Icon name="calendar plus" />
                  {t("New Period")}
                </Button>
              </UserAccessComponent>
            </Fragment>
          )}
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default PeriodManagementModal;
