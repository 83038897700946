import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const DEFAULT_LANG = "en";
export const DEFAULT_NS = "default";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: false,
        resources: {},
        lng: DEFAULT_LANG,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },

        ns: [DEFAULT_NS],

        defaultNS: DEFAULT_NS,
    });

export default i18n;
