import { useState, useEffect } from "react";
import {
  DateFormatter,
  DateFilter,
  DefaultFilter,
  Grid as NoticeGrid
} from "@caci/react-grid";
import axios from "axios";
import { Segment, Popup, Icon, Header, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const transformData = rows => {
  return rows.map(row => ({
    ...row,
    notice: row.noticeText
      ? row.noticeText.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, "")
      : ""
  }));
};

function NoticeScreen() {
  const [rows, setRows] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [notice, setNotice] = useState({});

  const { t } = useTranslation();

  const fetchNotices = () => {
    axios
      .get("/api/portal-notice-list")
      .then(({ data }) => {
        if (data.length > 0) setRows(data);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  return (
    <>
      <Header attached={"top"}>{t("Notices")}</Header>
      <Segment attached={"bottom"}>
        {!showDetail && (
          <NoticeGrid
            actionsCell={({ row }) => (
              <div style={{ textAlign: "center" }}>
                <Popup
                  content={t("View")}
                  trigger={
                    <Icon
                      circular
                      name="eye"
                      className={"dark-blue-border"}
                      color="black"
                      onClick={() => {
                        setShowDetail(true);
                        setNotice(row);
                      }}
                    />
                  }
                />
              </div>
            )}
            columns={[
              {
                key: "noticeDate",
                name: t("Date"),
                filterable: true,
                sortable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
                width: 180
              },
              {
                key: "noticeTitle",
                name: t("Title"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
                width: 300
              },
              {
                key: "notice",
                name: t("Notice"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              }
            ]}
            rows={transformData(rows)}
          />
        )}
        {showDetail && (
          <>
            <Header attached={"top"}>{t(notice.noticeTitle)}</Header>
            <Segment attached={"bottom"}>
              <div
                dangerouslySetInnerHTML={{ __html: notice.noticeText || "" }}
                style={{ width: '100%', minHeight: '250px' }}
              ></div>

              <Button
                className="orange"
                onClick={() => {
                  setShowDetail(false);
                }}
              >
                <Icon name="arrow left circle" /> {t("Back")}
              </Button>
            </Segment>
          </>
        )}
      </Segment>
    </>
  );
}

export default NoticeScreen;
