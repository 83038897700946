import { useEffect, useState } from "react";
import { Grid, Icon } from "semantic-ui-react";
import ProviderDetails from "./ProviderDetails";
import { useSession } from "../consumer/SessionConsumer";
import axios from "axios";
import ProviderHeaderAddress from "./ProviderHeaderAddress";
import ProviderHeaderNoAddress from "./ProviderHeaderNoAddress";
import { useTranslation } from "react-i18next";
import useLookup from "../consumer/LookupConsumer";
import { lookupCodeToValue } from "../utils";

function ProviderHeader() {
  const { t } = useTranslation();
  const { selectedProvider: provider } = useSession();
  const [site, setSite] = useState({});
  const [registeredPerson, setRegisteredPerson] = useState({});
  const [registeredAddress, setRegisteredAddress] = useState({});
  const [contactPerson, setContactPerson] = useState({});
  const [contactAddress, setContactAddress] = useState({});
  const { lookups: titles } = useLookup(103);

  const registeredPersonName =
    Object.keys(registeredPerson).length > 0
      ? `${
          registeredPerson.title
            ? lookupCodeToValue(titles, registeredPerson.title) + " "
            : ""
        }${registeredPerson.forename} ${registeredPerson.surname} (${
          registeredPerson.personId
        })`
      : "";
  const contactPersonName =
    Object.keys(contactPerson).length > 0
      ? `${
          contactPerson.title
            ? lookupCodeToValue(titles, contactPerson.title) + " "
            : ""
        }${contactPerson.forename} ${contactPerson.surname} (${
          contactPerson.personId
        })`
      : "";

  const siteDisplayIcon = (
    <Icon size="large" name="map marker alternate" color="green" />
  );
  const registeredDisplayIcon = (
    <Icon size="large" name="registered outline" color="purple" />
  );
  const contactDisplayIcon = (
    <Icon size="large" name="address book" color="blue" />
  );

  const fetchEarlySites = () => {
    if (provider.siteId)
      axios
        .get(`/api/pport-early-sites/${provider.siteId}`)
        .then(({ data }) => {
          setSite(data || {});
        })
        .catch(console.log);
  };

  const fetchRegisteredAddress = () => {
    if (provider.addressId)
      axios
        .get(`/api/pport-address/${provider.addressId}`)
        .then(({ data }) => {
          setRegisteredAddress(data || {});
        })
        .catch(console.log);
  };

  const fetchRegisteredPerson = () => {
    if (provider.personId)
      axios
        .get(`/api/pport-person/${provider.personId}`)
        .then(({ data }) => {
          setRegisteredPerson(data || {});
        })
        .catch(console.log);
  };

  const fetchContactAddress = () => {
    if (provider.addressId2)
      axios
        .get(`/api/pport-address/${provider.addressId2}`)
        .then(({ data }) => {
          setContactAddress(data || {});
        })
        .catch(console.log);
  };

  const fetchContactPerson = () => {
    if (provider.personId2)
      axios
        .get(`/api/pport-person/${provider.personId2}`)
        .then(({ data }) => {
          setContactPerson(data || {});
        })
        .catch(console.log);
  };

  useEffect(() => {
    fetchEarlySites();
  }, [provider.providerId, provider.siteId]);

  useEffect(() => {
    fetchRegisteredAddress();
  }, [provider.providerId, provider.addressId]);

  useEffect(() => {
    fetchRegisteredPerson();
  }, [provider.providerId, provider.personId]);

  useEffect(() => {
    fetchContactAddress();
  }, [provider.providerId, provider.addressId2]);

  useEffect(() => {
    fetchContactPerson();
  }, [provider.providerId, provider.personId2]);

  return (
    <Grid divided stretched columns="equal" className={"provider-header"}>
      <Grid.Column className={"sticky-grid"} style={{ width: "130px" }}>
        <ProviderDetails />
      </Grid.Column>
      <Grid.Column only="tablet computer" className={"sticky-grid"}>
        {Object.keys(site).length === 0 ? (
          <ProviderHeaderNoAddress
            icon={siteDisplayIcon}
            display={t("No site details")}
          />
        ) : (
          <ProviderHeaderAddress
            t={t}
            name={`${site.name} (${site.siteId})`}
            houseNo={site.pportAddress?.houseNo}
            street={site.pportAddress?.street}
            district={site.pportAddress?.district}
            town={site.pportAddress?.town}
            postcode={site.pportAddress?.postcode}
            icon={siteDisplayIcon}
          />
        )}
      </Grid.Column>
      <Grid.Column only="tablet computer" className={"sticky-grid"}>
        <ProviderHeaderAddress
          t={t}
          name={registeredPersonName}
          houseNo={registeredAddress?.houseNo}
          street={registeredAddress?.street}
          district={registeredAddress?.district}
          town={registeredAddress?.town}
          postcode={registeredAddress?.postcode}
          icon={registeredDisplayIcon}
          noAddressDisplay={"No registered address details"}
          noPersonDisplay={"No registered person"}
        />
      </Grid.Column>
      <Grid.Column only="tablet computer" className={"sticky-grid"}>
        <ProviderHeaderAddress
          t={t}
          name={contactPersonName}
          houseNo={contactAddress?.houseNo}
          street={contactAddress?.street}
          district={contactAddress?.district}
          town={contactAddress?.town}
          postcode={contactAddress?.postcode}
          icon={contactDisplayIcon}
          noAddressDisplay={"No contact address details"}
          noPersonDisplay={"No contact person"}
        />
      </Grid.Column>
    </Grid>
  );
}

export default ProviderHeader;
