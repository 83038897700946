import React from "react";
import { Form, Button, Grid, Message } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePostQuery } from "../hooks/request";

function LoginScreen() {
  const validations = yup.object().shape({
    login: yup.string().required("Required"),
    password: yup.string().required("Required"),
  });

  const form = useForm({
    resolver: yupResolver(validations),
  });

  const history = useHistory();

  const [loginQuery, { error, loading: loginInProgress }] = usePostQuery("/api/login", {
    onSuccess: () => {
      history.push("/")
    }
  });

  const handleLogin = ({ login, password }) => {
    loginQuery({ login, password });
  };

  return (
    <Grid>
      {!!error && (
        <Grid.Row>
          <Grid.Column>
            <Message error>
              <Message.Content>
                Incorrect login and/or password.
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(handleLogin)}>
              <Input name="login" label="Login" />
              <Input name="password" label="Password" type="password" />
              <Button disabled={loginInProgress} loading={loginInProgress}>Login</Button>
              <Button as={Link} to="/public/register" disabled={loginInProgress}>
                Register
              </Button>
            </Form>
          </FormProvider>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Link to="/public/reset">Forgot your password?</Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default LoginScreen;
