import React, { Component } from 'react';
import { Modal, Popup } from 'semantic-ui-react';

const GenericModal = props => {
    return (
        <Modal
            trigger={
                <Popup
                    trigger={props.buttonContent}
                    content={props.toolTip}
                />
            }
            open={props.open}
            onClose={props.onClose}
            size={props.size}
            className={'dark-modal'}
        >
            <Modal.Header className={'modal-header'}>{props.HeaderTitle}</Modal.Header>
            <Modal.Content className={'modal-content'}>
                    {props.content}
            </Modal.Content>
            <Modal.Actions className={'modal-actions'}>
                {props.footer}
            </Modal.Actions>
        </Modal>
    );
}

export default GenericModal;