import { useEffect, useState } from "react";
import axios from "axios";
import useGridRows from "../hooks/useGridRows";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as CourseGrid
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import moment from "moment";
import useLookup from "../consumer/LookupConsumer";
import { lookupCodeToValue } from "../utils";
import RichTextEditor from "./RichTextEditor";

function CourseSessionsModal({
  courseDatesId,
  isModalOpen,
  onClose,
  placeInd
}) {
  const { rows, setRows, row, setRow } = useGridRows();
  const { t } = useTranslation();
  const { lookups: venueTypeLookups } = useLookup(6135);
  const [venueDetailsModalOpen, setVenueDetailsModalOpen] = useState(false);
  const [connectionDetailsModalOpen, setConnectionDetailsModalOpen] = useState(
    false
  );

  const transformData = row =>
    rows.map(r => ({
      ...r,
      startTimeHHmm: r.startTime ? moment(r.startTime).format("HH:mm") : "",
      endTimeHHmm: r.endTime ? moment(r.endTime).format("HH:mm") : "",
      sessionDate:r.sessionDate ? moment(r.sessionDate).format('YYYY/MM/DD'):null,
      venue:
        r.venueType === "VENUE"
          ? `${r.venueName} (${r.venueId})`
          : lookupCodeToValue(venueTypeLookups, r.venueType),
      room: r.roomName && r.roomId ? `${r.roomName} (${r.roomId})` : "",
    }));

  useEffect(() => {
    if (courseDatesId) {
      axios
        .get(`/api/course-venue-list/${courseDatesId}`)
        .then(({ data }) => setRows(data))
        .catch(console.log);
    }
  }, [courseDatesId]);

  return (
    <>
      <Modal
        open={
          isModalOpen && !venueDetailsModalOpen && !connectionDetailsModalOpen
        }
        size="large"
        onClose={onClose}
      >
        <Modal.Header>{t("Course Sessions")}</Modal.Header>
        <Modal.Content>
          <CourseGrid
            actionCellWidth={200}
            actionsCell={({ row }) => (
              <div style={{ textAlign: "center" }}>
                {row.venueType === "VENUE" && (
                  <UserAccessComponent requires="PPORT_TR_CM_SESS_VEN">
                    <Button
                      color="blue"
                      onClick={() => {
                        setRow(row);
                        setVenueDetailsModalOpen(true);
                      }}
                      style={{ padding: "0.6rem 1.5rem" }}
                    >
                      <Icon>
                        <i className="fal fa-chalkboard-teacher" />
                      </Icon>
                      {t("Venue Details")}
                    </Button>
                  </UserAccessComponent>
                )}

                {row.venueType !== "VENUE" && placeInd === "Y" && (
                  <UserAccessComponent requires="PPORT_TR_MC_CON">
                    <Button
                      color="blue"
                      onClick={() => {
                        setRow(row);
                        setConnectionDetailsModalOpen(true);
                      }}
                      style={{ padding: "0.6rem 1.5rem" }}
                    >
                      <Icon>
                        <i className="fal fa-chalkboard-teacher" />
                      </Icon>
                      {t("Connection Details")}
                    </Button>
                  </UserAccessComponent>
                )}
              </div>
            )}
            columns={[
              {
                key: "id",
                name: t("Session Number"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "sessionDate",
                name: t("Date"),
                filterable: true,
                sortable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter
              },
              {
                key: "startTimeHHmm",
                name: t("Start Time"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "endTimeHHmm",
                name: t("Finish Time"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "venue",
                name: t("Venue"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              },
              {
                key: "room",
                name: t("Room"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter
              }
            ]}
            rows={transformData(rows)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="orange" onClick={onClose}>
            <Icon name="remove circle" />
            {t("Close")}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={venueDetailsModalOpen}
        onClose={() => setVenueDetailsModalOpen(false)}
        size="medium"
      >
        <Modal.Header>{t("Venue Details")}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>{t("Venue Details")}</label>
              <RichTextEditor value={row.venueDetails} readOnly />
            </Form.Field>
            <Form.Field>
              <label>{t("Additional Venue Details")}</label>
              <a
                href={row.venueUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                {row.venueUrl}
              </a>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="orange"
            onClick={() => setVenueDetailsModalOpen(false)}
          >
            <Icon name="remove circle" />
            {t("Close")}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={connectionDetailsModalOpen}
        onClose={() => setConnectionDetailsModalOpen(false)}
        size="medium"
      >
        <Modal.Header>{t("Connection Details")}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>{t("Additional Details")}</label>
              <RichTextEditor value={row.virtualText} readOnly />
            </Form.Field>
            <Form.Field>
              <label>{t("Session URL")}</label>
              <a
                href={row.virtualUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                {row.virtualUrl}
              </a>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="orange"
            onClick={() => setConnectionDetailsModalOpen(false)}
          >
            <Icon name="remove circle" />
            {t("Close")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default CourseSessionsModal;
