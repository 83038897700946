import { useState } from "react";
import {Modal, Button, Icon, Popup} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function DeleteConfirmationModal(props) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <Modal
      trigger={
        <Popup
          content={t(props.content ? props.content : "Delete")}
          trigger={
            props.buttonTitle ? (
              <Button
                type="button"
                color={props.buttonColor}
                onClick={!props.disabled ? () => setModalOpen(true) : null}
                disabled={props.disabled}
              >
                <Icon
                  name={props.iconName}
                  color={props.color ? props.color : "black"}
                />
                {props.buttonTitle}
              </Button>
            ) : (
              <span onClick={!props.disabled ? () => setModalOpen(true) : null}>
                <Icon
                  circular={props.circular}
                  link
                  name={props.iconName}
                  className={props.color ? props.color : "red-border"}
                />
                {props.title}
              </span>
            )
          }
        />
      }
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      className={"dark-modal"}
    >
      <Modal.Header className={"modal-header"}>
        {props.confirmTitle}
      </Modal.Header>
      <Modal.Content image className={"modal-content"}>
        <Modal.Description>
          <p className={"text-white-color"}>{props.message}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className={"modal-actions"}>
        <Button
          color="green"
          onClick={() => {
            props.onDelete();
            setModalOpen(false);
          }}
        >
          <Icon name="checkmark" /> {props.yes ? props.yes : t("OK")}
        </Button>
        <Button
          color="red"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <Icon name="remove" /> {props.no ? props.no : t("Cancel")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteConfirmationModal;