import { useState } from "react";
import {Modal, Button, Icon, Form, TextArea, Input, Popup} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../utils";

function RejectCommentsModal(props) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Modal
      trigger={
        <Popup
            content={t("Comment")}
          trigger={
            <span onClick={!props.disabled ? () => setModalOpen(true) : null}>
              <Icon
                circular={props.circular}
                link
                name={props.iconName || "comment alternate"}
                className={props.color || "orange-border"}
                disabled={props.disabled}
              />
              {props.title}
            </span>
          }
        />
      }
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      className={"dark-modal"}
    >
      <Modal.Header className={"modal-header"}>
        {props.Title || t("Reject Comments")}
      </Modal.Header>
      <Modal.Content image className={"modal-content"}>
        <Modal.Description>
          <Form>
            <Form.Field>
              <label>{t("Reject Comment")}</label>
              <TextArea value={props.rejectComments} readOnly />
            </Form.Field>
            <Form.Field>
              <label>{t("Reject Date")}</label>
              <Input
                value={props.rejectedDate ? formatDate(props.rejectedDate) : ""}
                readOnly
              />
            </Form.Field>
            <Form.Field>
              <label>{t("Rejected By")}</label>
              <Input value={props.rejectedBy} readOnly />
            </Form.Field>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className={"modal-actions"} style={{ textAlign: "left" }}>
        <Button
          color="orange"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          <Icon name="circle arrow left" /> {t("Back")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default RejectCommentsModal;
