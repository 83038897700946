import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { applyGroupFilter } from "./FipGridScreen";
import {
  HeaderCountsContainer,
  CountBlock,
  StatisticCountAndLabel,
  CountClearIcon
} from "./HeaderCounts";

const group1Width = 330;
const widthOfEachCharacter = 8.45;
const minimumWidthOfEachStatisticContainer = 100;
const eachBlockPadding = 30;

function FipHeaderCounts({
  rows,
  groups,
  setGroups,
  actionTypes = [],
  actionAreas = []
}) {
  const { t } = useTranslation();
  const [group2Width, setGroup2Width] = useState(group1Width);
  const [group3Width, setGroup3Width] = useState(0);
  const [absGroup2Width, setAbsGroup2Width] = useState(0);
  const [absGroup3Width, setAbsGroup3Width] = useState(0);
  const [areaStatistic, setAreaStatistic] = useState([]);
  const [typeStatistic, setTypeStatistic] = useState([]);
  const [clientWidth, setClientWidth] = useState(
    document.documentElement.clientWidth
  );

  const setSelected = (groupKey, value) =>
    setGroups(g => ({ ...g, [groupKey]: { ...g[groupKey], selected: value } }));

  const {
    open: openStatisticValue,
    completed: completedStatisticValue
  } = rows.reduce(
    (acc, r) => {
      if (!r.completedDate && !r.obsoleteDate) acc.open += 1;
      if (!!r.completedDate || !!r.obsoleteDate) acc.completed += 1;
      return acc;
    },
    { open: 0, completed: 0 }
  );

  useEffect(() => {
    const setWidthCallback = () =>
      setClientWidth(document.documentElement.clientWidth);
    window.addEventListener("resize", setWidthCallback);
    return () => window.removeEventListener("resize", setWidthCallback);
  }, []);

  useEffect(() => {
    const lookupWidthCalc = (lookups, containerWidth, groupKey = "") => {
      let widthCalc = eachBlockPadding; // padding of each block
      const { group1, [groupKey]: group } = groups;
      return {
        actionStatistic: lookups.map((action, index, actions) => {
          const count = actions.length;
          const minimumWidth =
            containerWidth / count > minimumWidthOfEachStatisticContainer
              ? containerWidth / count
              : minimumWidthOfEachStatisticContainer;
          const { codeDescription, codeValue } = action;
          const descriptionWidth = calcStaticWidth(codeDescription);
          let statisticWidth =
            codeDescription && descriptionWidth > minimumWidth
              ? descriptionWidth
              : minimumWidth;
          widthCalc += statisticWidth;
          return {
            text: codeDescription,
            codeValue,
            width: Math.round(statisticWidth),
            className: group.selected === codeValue ? "active" : "",
            value: applyGroupFilter(rows, {
              group1,
              group2: { filterFunc: group.filterFunc, selected: codeValue }
            }).length
          };
        }),
        widthCalc
      };
    };

    const calcStaticWidth = text => {
      let width = text.length * widthOfEachCharacter;
      if (width > 200) width = width * 0.66; // make bigger text to two lines
      return width;
    };

    const calculateGroups = () => {
      // avgSecondGroupWidth calculated 44% of remaining client width and 10px clearance;
      let avgSecondGroupWidth =
        Math.round((clientWidth - group1Width) * 0.44) - 10;
      let avgThirdGroupWidth = clientWidth - avgSecondGroupWidth - group1Width;

      const { actionStatistic: area, widthCalc: areaWidth } = lookupWidthCalc(
        actionAreas,
        avgSecondGroupWidth - eachBlockPadding,
        "group2"
      );
      const { actionStatistic: type, widthCalc: typeWidth } = lookupWidthCalc(
        actionTypes,
        avgSecondGroupWidth - eachBlockPadding,
        "group3"
      );

      setGroup2Width(Math.round(avgSecondGroupWidth));
      setAbsGroup2Width(Math.round(areaWidth));
      setGroup3Width(Math.round(avgThirdGroupWidth));
      setAbsGroup3Width(Math.round(typeWidth));

      setAreaStatistic(area);
      setTypeStatistic(type);
    };
    if (clientWidth > 0 && actionTypes && actionAreas) calculateGroups();
  }, [clientWidth, actionTypes, actionAreas, rows, groups]);

  return (
    <HeaderCountsContainer>
      {/* group 1 */}
      <CountBlock style={{ minWidth: group1Width }}>
        <StatisticCountAndLabel
          color="green"
          style={{ width: minimumWidthOfEachStatisticContainer }}
          onClick={() => setSelected("group1", "open")}
          labelClassName={groups.group1.selected === "open" ? "active" : ""}
          value={openStatisticValue}
          label={t("Open")}
        />

        <StatisticCountAndLabel
          style={{
            width: minimumWidthOfEachStatisticContainer,
            color: "darkgray"
          }}
          onClick={() => setSelected("group1", "completed")}
          labelClassName={
            groups.group1.selected === "completed" ? "active" : ""
          }
          value={completedStatisticValue}
          label={t("Completed")}
        />

        <StatisticCountAndLabel
          color="blue"
          style={{ width: minimumWidthOfEachStatisticContainer }}
          onClick={() => setSelected("group1", "all")}
          labelClassName={groups.group1.selected === "all" ? "active" : ""}
          value={rows.length}
          label={t("All")}
        />
      </CountBlock>

      {/* group 2 */}
      <CountBlock
        style={{
          minWidth: group2Width,
          maxWidth: group2Width,
          overflowX: absGroup2Width > group2Width ? "scroll" : "hidden"
        }}
      >
        {areaStatistic.map((as, i) => (
          <StatisticCountAndLabel
            key={i}
            color={!as.className ? "orange" : "red"}
            style={{
              minWidth: as.width,
              cursor: as.value > 0 ? "pointer" : "default"
            }}
            onClick={() => {
              if (as.value > 0) setSelected("group2", as.codeValue);
            }}
            className={as.className}
            value={as.value}
            label={t(as.text)}
          />
        ))}
      </CountBlock>

      {/* group 3 */}
      <CountBlock
        style={{
          width: group3Width,
          overflowX: absGroup3Width > group3Width ? "scroll" : "hidden"
        }}
      >
        {typeStatistic.map((ts, i) => (
          <StatisticCountAndLabel
            key={i}
            color={!ts.className ? "orange" : "red"}
            style={{
              minWidth: ts.width,
              cursor: ts.value > 0 ? "pointer" : "default"
            }}
            onClick={() => {
              if (ts.value > 0) setSelected("group3", ts.codeValue);
            }}
            className={ts.className}
            value={ts.value}
            label={t(ts.text)}
          />
        ))}
      </CountBlock>

      {/* group 2 clear icon */}
      {!!groups?.group2.selected && (
        <CountClearIcon
          style={{
            left: group1Width + 18
          }}
          onClick={() => setSelected("group2", "")}
        />
      )}

      {/* group 3 clear icon */}
      {!!groups?.group3.selected && (
        <CountClearIcon
          style={{
            left: group1Width + group2Width + 26
          }}
          onClick={() => setSelected("group3", "")}
        />
      )}
    </HeaderCountsContainer>
  );
}

export default FipHeaderCounts;
