import styled from "styled-components";
import { Icon, Statistic } from "semantic-ui-react";

const Block = styled.div`
  height: 130px;
  margin-right: 8px;
  background-color: white;
  display: flex;
  padding: 0 15px;
  overflow-y: hidden;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  & .ui.statistic {
    margin-top: 12px !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    cursor: pointer;

    .value {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .label {
      color: gray;
      text-transform: capitalize;
    }

    .label.active {
      color: black;
    }
  }

  & .ui.statistic.active .label {
    color: black;
  }
`;

export const HeaderCountsContainer = styled.div`
  display: flex;
  margin-top: 3px;
  position: relative;
`;

export const CountBlock = styled(Block)(
  ({ style = {} }) => `
    width: ${style.width || "auto"};
    min-width: ${style.minWidth || "auto"};
    max-width: ${style.maxWidth || "auto"};
    overflow-x: ${style.overflowX || "hidden"};
`
);

const IconWrapper = styled(Icon)(
  ({ style = {} }) => `
  position: absolute;
  top: 5px;
  left: ${style.left || "5px"};
  color: red;
  cursor: pointer;
`
);

export const StatisticCountAndLabel = ({
  value,
  label,
  color,
  size = "small",
  style,
  onClick = () => {},
  className = "",
  labelClassName = ""
}) => (
  <Statistic
    color={color}
    size={size}
    style={style}
    onClick={onClick}
    className={className}
  >
    <Statistic.Value>{value}</Statistic.Value>
    <Statistic.Label className={labelClassName}>{label}</Statistic.Label>
  </Statistic>
);

export const CountClearIcon = ({
  style,
  className = "far fa-times-circle",
  onClick = () => {}
}) => (
  <IconWrapper style={style}>
    <i className={className} onClick={onClick} />
  </IconWrapper>
);
