import { Segment } from "semantic-ui-react";
import axios from "axios";
import { Timeline } from "@caci/react-timeline";
import { useEffect, useState } from "react";

const initTimelineData = [];
function TimelineDetailsScreen({ providerId, history }) {
    const [timelineData, setTimelineData] = useState(initTimelineData);

    useEffect(() => {
        if (providerId) {
            axios
              .get(`/api/timeline/${providerId}`)
              .then(({ data }) => {
                  setTimelineData(data);
              })
              .catch(console.log);
        } else {
            setTimelineData(initTimelineData)
        }
    }, [providerId]);

    const navigateToEvent = (timelineRecord) => {
        if (!timelineRecord || !history) {
          return;
        }

        const { url_slug, related_context_id } = timelineRecord;
        let urlSlug = `/portal/${providerId}/provider`;

        if (url_slug === 'provider-view') {
          urlSlug += '/provider-details-ext';
        } else if (url_slug === 'provider-visit-note') {
          urlSlug += `/provider-assessment`;
        } else {
          urlSlug += `/${url_slug}/${related_context_id}`;
        }

        history.push(urlSlug);
      };


  return (
        <Segment className="grid-container-550">
            <Timeline data={timelineData} navigateFunction={navigateToEvent}/>
        </Segment>
    );
}

export default TimelineDetailsScreen;