import { Icon, Menu } from "semantic-ui-react";
import { useContext, useEffect } from "react";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserAccessContext } from "../../context";
import UserAccessComponent from "../../components/UserAccessComponent";
import Estimates from "./Estimates";
import LiveRegister from "./LiveRegister";
import Headcount from "./Headcount";
import EYFChildDetailsScreen from "./EYFChildDetailsScreen";

const tabs = [
  {
    tabName: "Live Register",
    userAccessCode: "PPORT_EYF_FUND_LR",
    urlSlug: "live-register",
    exclamation: "N",
    icon: "fas fa-users-class",
  },
  {
    tabName: "Estimates",
    userAccessCode: "PPORT_EYF_FUND_EST",
    urlSlug: "estimates",
    exclamation: "N",
    icon: "fa-regular fa-calculator-simple",
  },
  {
    tabName: "Headcount",
    userAccessCode: "PPORT_EYF_FUND_HC",
    urlSlug: "headcount",
    exclamation: "N",
    icon: "fa-solid fa-user-group",
  },
];

function EYFundingView({ location, match, history }) {
  const { t } = useTranslation();
  const { userGroupAccess } = useContext(UserAccessContext);

  useEffect(() => {
    let urlContainsSlug = false;
    const slugs = [];
    const ugCodes = userGroupAccess.map((ug) => ug.code);

    tabs.forEach((tab) => {
      if (ugCodes.includes(tab.userAccessCode)) slugs.push(tab.urlSlug);
    });

    for (let i = 0; i < slugs.length; i++) {
      if (location.pathname.includes(tabs[i].urlSlug)) {
        urlContainsSlug = true;
      }
    }

    if (!urlContainsSlug) {
      history.push(`${location.pathname}/${slugs.length > 0 ? slugs[0] : ""}`);
    }
  }, []);

  return (
    <>
      <Menu className={"tabs-menu"}>
        {tabs.map((tab, index) => (
          <UserAccessComponent requires={tab.userAccessCode} key={index}>
            <Menu.Item
              key={tab.id}
              as={NavLink}
              activeClassName="active"
              to={`/portal/${match.params.providerId}/provider/provider-funding/${tab.urlSlug}`}
            >
              <Icon>
                <i className={tab.icon} />
              </Icon>
              {t(tab.tabName)}
            </Menu.Item>
          </UserAccessComponent>
        ))}
      </Menu>
      <Switch>
        <Route path={`${match.path}/live-register`} exact>
          <LiveRegister />
        </Route>
        <Route path={`${match.path}/live-register/:eypChildId`} >
          <EYFChildDetailsScreen />
        </Route>
        <Route path={`${match.path}/estimates`}>
          <Estimates />
        </Route>
        <Route path={`${match.path}/headcount`} exact>
          <Headcount />
        </Route>
      </Switch>
    </>
  );
}

export default withRouter(EYFundingView);
