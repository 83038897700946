import { useReducer, useEffect, useCallback } from "react";
import { SessionContext } from "../context";
import sessionReducer, {
  initialValue,
  receivePerson,
  receiveProviders,
  receiveSelectedProvider,
  requestPerson,
  requestProviders,
  requestUserDetails,
  receiveUserDetails,
  updateProvider,
} from "../reducer/sessionReducer";
import axios from "axios";

function SessionProvider({ children }) {
  const [state, dispatch] = useReducer(sessionReducer, initialValue);

  const setSelectedProvider = useCallback(
    (provider) => dispatch({ type: receiveSelectedProvider, provider }),
    []
  );

  const updateSelectedProvider = useCallback(
    (provider) => dispatch({ type: updateProvider, provider }),
    []
  );

  const fetchProviders = useCallback(() => {
    dispatch({ type: requestProviders });
    axios
      .get(`/api/pport-early-providers/current`)
      .then(({ data: providers }) => {
        dispatch({ type: receiveProviders, providers });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: receiveProviders, providers: [] });
      });
  }, []);

  useEffect(() => {
    const fetchPerson = () => {
      dispatch({ type: requestPerson });
      axios
        .get(`/api/pport-person/current`)
        .then(({ data: person }) => {
          dispatch({ type: receivePerson, person });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: receivePerson, person: {} });
        });
    };

    const fetchUserDetails = () => {
      dispatch({ type: requestUserDetails });
      axios
        .get(`/api/pport-edap-users/current`)
        .then(({ data: userDetails }) => {
          dispatch({ type: receiveUserDetails, userDetails });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: receiveUserDetails, userDetails: {} });
        });
    };
    fetchProviders();
    fetchPerson();
    fetchUserDetails();
  }, [fetchProviders]);

  const value = {
    ...state,
    setSelectedProvider,
    updateSelectedProvider,
    fetchProviders,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
}

export default SessionProvider;
