import {
  Segment,
  Menu,
  Form,
  FormField,
  Button,
  Icon
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { lookupCodeToValue } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import { useHistory } from "react-router-dom";
import RichTextEditor from "./RichTextEditor";

function FipDetails({ row }) {
  const { t } = useTranslation();
  const { lookups: actionTypeLookups } = useLookup(6111);
  const { lookups: actionAreaLookups } = useLookup(6114);
  const history = useHistory();
  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Action Details")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <Form>
          <Form.Input label={t("Action ID")} value={row.id} readOnly />

          <Form.Input
            label={t("Action Area")}
            value={t(lookupCodeToValue(actionAreaLookups, row.actionArea))}
            readOnly
          />

          <Form.Input
            label={t("Action Type")}
            value={t(lookupCodeToValue(actionTypeLookups, row.actionType))}
            readOnly
          />

          <FormField>
            <label>{t("Action Date")}</label>
            <input type="date" value={row.actionDate} readOnly />
          </FormField>

          <RichTextEditor
            label={t("Action")}
            value={row.action}
            readOnly
          />

          <Form.Input label={t("Action Owner")} value={row.owner} readOnly />

          <RichTextEditor
              label={t("Evidence")}
              value={row.evidence}
              readOnly
          />

          <FormField>
            <label>{t("Agreed Implementation Date")}</label>
            <input type="date" value={row.agreedInpDate} readOnly />
          </FormField>

          <FormField>
            <label>{t("Obsolete Date")}</label>
            <input type="date" value={row.obsoleteDate} readOnly />
          </FormField>

          <FormField>
            <label>{t("Completed Date")}</label>
            <input type="date" value={row.completedDate} readOnly />
          </FormField>
          <br />
          <Button onClick={() => history.goBack()} color="orange">
            <Icon name="arrow circle left" />
            {t("Back")}
          </Button>
        </Form>
      </Segment>
    </>
  );
}

export default FipDetails;
