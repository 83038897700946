import { Message } from "semantic-ui-react";
import useActiveLicence from "../consumer/ActiveLicenceConsumer";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";

function LicencedRoute({
  component: Component,
  requiresLicense,
  children,
  path,
  exact
}) {
  const { t } = useTranslation();
  const licenced = useActiveLicence(requiresLicense);
  return (
    <Route path={path} exact={exact}>
      {licenced ? (
        !!Component ? (
          <Component />
        ) : (
          children
        )
      ) : (
        <Message>
          <Message.Header>{t("Access Denied")}</Message.Header>
          <p>{t("Product component not licenced")}</p>
        </Message>
      )}
    </Route>
  );
}

export default LicencedRoute;
