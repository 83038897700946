import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Switch, Route } from "react-router-dom";

import "semantic-ui-css/semantic.min.css";

import reportWebVitals from "./reportWebVitals";
import PublicScreen from "./public/PublicScreen";
import axios from "axios";
import App from "./App";
import './assets/styles/main.css';
import { ToastProvider } from "react-toast-notifications";

(() => {
  const jwt = sessionStorage.getItem("jwt");
  if (!!jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
  }
  axios.interceptors.response.use((response) => {
    if (response.config.url === "/api/login" && response.status === 200) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.data;
      sessionStorage.setItem("jwt", response.data);
    }
    return response;
  });
})();

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/public">
        <PublicScreen />
      </Route>
      <Route path="/">
        <ToastProvider autoDismiss autoDismissTimeout={6000}>
          <App />
        </ToastProvider>
      </Route>
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
