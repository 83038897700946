import { useEffect, useContext } from "react";
import { Icon, Menu } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import UserAccessComponent from "../components/UserAccessComponent";
import NoticeScreen from "./NoticeScreen";
import { useSession } from "../consumer/SessionConsumer";
import ProviderView from "./ProviderView";
import {UserAccessContext} from "../context";
import TrainingTabs from "./TrainingTabs";
import LicencedRoute from "../components/LicencedRoute";

const portalTabs = [
  {
    userAccessCode: "PPORT_HM",
    endPath: "",
    tabName: "Home",
    tabIcon: "fa fa-home"
  },
  {
    userAccessCode: "PPORT_PM",
    endPath: "provider",
    tabName: "Provider",
    tabIcon: "fa fa-cubes"
  },
  {
    userAccessCode: "PPORT_TR",
    endPath: "training",
    tabName: "Training",
    tabIcon: "fal fa-chalkboard-teacher"
  }
];

function Portal({ match, history, location }) {
  const { t } = useTranslation();
  const {
    providers,
    loadingProviders,
    setSelectedProvider,
    selectedProvider
  } = useSession();
  const { userGroupAccess } = useContext(UserAccessContext);

  const getPath = endPath =>
    `/portal/${match.params.providerId}${endPath ? "/" + endPath : ""}`;

  useEffect(() => {
    if (
      !loadingProviders &&
      selectedProvider.providerId !== Number(match.params.providerId)
    ) {
      const provider =
        providers.find(
          prov => prov.providerId === Number(match.params.providerId)
        ) || {};
      setSelectedProvider(provider);
    }
  }, [loadingProviders]);

  useEffect(() => {
    const ugCode = userGroupAccess.map((uga) => uga.code);
    let { pathname } = location;
    if(pathname.charAt(0) === "/") {
      pathname = pathname.slice(1);
    }
    if(pathname.split("/").length === 2) {
      for (const tab of portalTabs) {
        if (ugCode.includes(tab.userAccessCode)) {
          history.push(getPath(tab.endPath));
          break;
        }
      }
    }
  }, []);

  return (
    <>
      <Menu>
        {portalTabs.map((tab, index) => (
            <UserAccessComponent requires={tab.userAccessCode} key={index}>
              <Menu.Item
                  as={NavLink}
                  to={getPath(tab.endPath)}
                  activeClassName={"active"}
                  exact={!tab.endPath}
              >
                <Icon>
                  <i className={tab.tabIcon} />
                </Icon>
                {t(tab.tabName)}
              </Menu.Item>
            </UserAccessComponent>
        ))}
      </Menu>
      <Switch>
        <Route path={`${match.path}`} exact>
          <UserAccessComponent requires={"PPORT_HM"}>
            <NoticeScreen />
          </UserAccessComponent>
        </Route>
        <Route path={`${match.path}/provider`}>
          <UserAccessComponent requires={"PPORT_PM"}>
            <ProviderView />
          </UserAccessComponent>
        </Route>
        <LicencedRoute path={`${match.path}/training`} requiresLicense="COURSEMGMT">
          <UserAccessComponent requires={"PPORT_TR"}>
            <TrainingTabs />
          </UserAccessComponent>
        </LicencedRoute>
      </Switch>
    </>
  );
}

export default withRouter(Portal);
