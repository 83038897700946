import {useEffect, useState} from "react";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as AssessmentGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import axios from "axios";
import {Icon, Popup, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import useLookup from "../consumer/LookupConsumer";
import {withRouter} from "react-router-dom";

function EarlyAssessmentTabScreen({ history, location, match }) {
  const [rows, setRows] = useState([]);
  const { lookups: inspectionStatus } = useLookup(6092);
  const { lookups: categoryOfSupport } = useLookup(6093);
  const { lookups: inspectionCycle } = useLookup(6094);
  const { lookups: earlyYearsQiOffer } = useLookup(6095);
  const { lookups: frequencyOfContacts } = useLookup(6096);

  const { t } = useTranslation();

  const fetchAssessment = () => {
    axios
      .get(`/api/early-assessment-list/${match.params.providerId}`)
      .then(({ data }) => {
        if (data.length > 0) setRows(data);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchAssessment();
  }, []);

  return (
    <>
      <Segment attached={"bottom"}>
        <AssessmentGrid
          actionsCell={({ row }) => (
            <div style={{ textAlign: "center" }}>
              <Popup
                content={t("View")}
                trigger={
                  <Icon
                    circular
                    name="eye"
                    className={"dark-blue-border"}
                    color="black"

                    to={`/provider-assessment/${row.id}`}
                    onClick={() => {
                      history.push(
                          `${location.pathname}/${row.id}`
                      );
                    }}
                  />
                }
              />
            </div>
          )}
          columns={[
            {
              key: "id",
              name: t("Assessment ID"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter,
            },
            {
              key: "regDate",
              name: t("Registration Date"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter,
            },
            {
              key: "inspDate",
              name: t("Inspection Date"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter,
            },
            {
              key: "inspStatus",
              name: t("Inspection Status"),
              filterable: true,
              sortable: true,
              filterRenderer: LookupFilter,
              formatter: LookupFormatter,
              lookups: inspectionStatus,
            },
            {
              key: "supportCategory",
              name: t("Category of Support"),
              filterable: true,
              sortable: true,
              filterRenderer: LookupFilter,
              formatter: LookupFormatter,
              lookups: categoryOfSupport,
            },
            {
              key: "inspCycle",
              name: t("Inspection Cycle"),
              filterable: true,
              sortable: true,
              filterRenderer: LookupFilter,
              formatter: LookupFormatter,
              lookups: inspectionCycle,
            },
            {
              key: "agreementDate",
              name: t("Agreement Date"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter,
            },
            {
              key: "qiOffer",
              name: t("Early Years QI Offer"),
              filterable: true,
              sortable: true,
              filterRenderer: LookupFilter,
              formatter: LookupFormatter,
              lookups: earlyYearsQiOffer,
            },
            {
              key: "contactFreq",
              name: t("Frequency of Contact"),
              filterable: true,
              sortable: true,
              filterRenderer: LookupFilter,
              formatter: LookupFormatter,
              lookups: frequencyOfContacts,
            },
            {
              key: "progressReviewDate",
              name: t("Progress Review Date"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter,
            },
            {
              key: "exitStrategyDate",
              name: t("Exit Strategy Completed"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter,
            },
          ]}
          rows={rows}
        />
      </Segment>
    </>
  );
}

export default withRouter(EarlyAssessmentTabScreen);
