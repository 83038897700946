import { Container, Grid, Icon } from "semantic-ui-react";

function SideBar(props) {
  return (
    <div
      className={"sidebar"}
      style={{
        right: props.visible
          ? "0px"
          : props.width
          ? "-" + props.width
          : "-800px",
        width: props.width ? props.width : "800px",
        background: props.background,
      }}
    >
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <h3>{props.header}</h3>
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              {!props.hideBackButton && (
                <Icon
                  size="large"
                  link
                  className="angle double right sidebar-icon"
                  onClick={props.onClose}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              {props.visible && props.children}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
}

export default SideBar;
