import { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import FipGridScreen from "../components/FipGridScreen";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import useGridRows from "../hooks/useGridRows";
import FipDetailsScreen from "../components/FipDetailsScreen";

export const fipRowMapper = ({ person, action, ...rest }) => ({
  ...rest,
  owner: person
    ? `${person.forename ? person.forename + " " : ""} ${person.surname} (${
        person.personId
      })`
    : "",
  actionText: action
    ? action.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, "")
    : "",
  action
});

function FipScreen() {
  const [refetch, setRefetch] = useState(false);
  const { rows, row, setRows, setRow } = useGridRows();
  const { selectedProvider: provider } = useSession();
  const match = useRouteMatch();

  useEffect(() => {
    const fetchFipRecords = () => {
      if (provider.providerId) {
        axios
          .get(`/api/early-provider-action-list/${provider.providerId}`)
          .then(({ data }) => {
            setRows(data.map(fipRowMapper));
          });
      }
    };
    fetchFipRecords();
  }, [provider.providerId, refetch]);

  return (
    <Switch>
      <Route path={`${match.path}/:fipId`}>
        <FipDetailsScreen setRefetch={setRefetch} row={row} setRow={setRow} />
      </Route>
      <Route>
        <FipGridScreen rows={rows} setRow={setRow} />
      </Route>
    </Switch>
  );
}

export default FipScreen;
