import { useState } from "react";
import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { lookupCodeToValue } from "../utils";

import useLookup from "../consumer/LookupConsumer";
import moment from "moment";

function ActionDetailsScreen({ row = {}, onBack }) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { lookups: fipActionArea } = useLookup(6114);
  const { lookups: fipActionType } = useLookup(6111);

  return (
    <Form>
      <Grid stackable>
        <Grid.Row className={"padding-top-0"}>
          <Grid.Column width={8} className={"padding-left-0 padding-right-10"}>
            <Form.Input
              name="id"
              value={row.id}
              label={t("Action ID")}
              readOnly
            />
            <Form.Input
              name="actionArea"
              value={t(lookupCodeToValue(fipActionArea, row.actionArea))}
              label={t("Action Area")}
              readOnly
            />
            <Form.Input
              name="actionType"
              value={t(lookupCodeToValue(fipActionType, row.actionType))}
              label={t("Action Type")}
              readOnly
            />

            <Form.Input
              name="actionDate"
              value={
                row.actionDate
                  ? moment(row.actionDate).format("DD/MM/YYYY")
                  : ""
              }
              label={t("Action Date")}
              readOnly
            />
            <Form.TextArea label={t("Action")} value={row.action} readOnly />
          </Grid.Column>
          <Grid.Column width={8} className={"padding-left-0 padding-right-10"}>
            <Segment attached={"top"} style={{ backgroundColor: "#eee" }}>
              <div align="center" style={{ marginBottom: "1rem" }}>
                <b className={"text-white-color"}>{t("Action Owner")}</b>
              </div>
              <Form.Input
                label={t("Impulse ID")}
                name="personId"
                value={row.personId}
              />
              <Form.Input
                label={t("First Name")}
                name="forename"
                value={row.forename}
              />
              <Form.Input
                label={t("Surname")}
                name="surname"
                value={row.surname}
              />
            </Segment>
            <Form.TextArea
              label={t("Evidence")}
              value={row.evidence}
              readOnly
            />
            <Form.Input
              name="agreedInpDate"
              value={
                row.agreedInpDate
                  ? moment(row.agreedInpDate).format("DD/MM/YYYY")
                  : ""
              }
              label={t("Agreed Implementation Date")}
              readOnly
            />
            <Form.Input
              name="completedDate"
              value={
                row.completedDate
                  ? moment(row.completedDate).format("DD/MM/YYYY")
                  : ""
              }
              label={t("Completed Date")}
              readOnly
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <Button onClick={() => onBack(false)} color="orange">
        <Icon name="arrow circle left" />
        {t("Back")}
      </Button>
    </Form>
  );
}

export default ActionDetailsScreen;
