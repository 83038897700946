import { useEffect } from "react";
import useGridRows from "../hooks/useGridRows";
import { useSession } from "../consumer/SessionConsumer";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import axios from "axios";
import useLookup from "../consumer/LookupConsumer";
import { lookupCodeToValue } from "../utils";
import StaffGridScreen from "./StaffGridScreen";
import StaffDetailsScreen from "./StaffDetailsScreen";

const addSpaceAtEnd = str => (str ? str + " " : "");

export const validationFlagsList = ["1", "2", "3"];

function StaffScreen({ setValidationFlag, urlSlug }) {
  const { rows, row, refetch, setRows, setRow, setRefetch } = useGridRows();
  const { selectedProvider: provider } = useSession();
  const match = useRouteMatch();
  const { lookups: titles } = useLookup(103);

  useEffect(() => {
    const fetchStaffRecords = () => {
      if (provider.providerId) {
        axios
          .get(`/api/pport-early-staff-list/${provider.providerId}`)
          .then(({ data }) => {
            setRows(
              data.map(({ pportPerson, ...rest }) => ({
                personName: `${addSpaceAtEnd(
                  lookupCodeToValue(titles, pportPerson?.title)
                )}${addSpaceAtEnd(pportPerson?.forename)}${addSpaceAtEnd(
                  pportPerson?.surname
                )} (${pportPerson?.personId})`,
                ...rest
              }))
            );
            const validationFlagIndicator = data.some(row =>
              validationFlagsList.includes(row.validationFlag)
            );
            setValidationFlag(urlSlug, {
              name: "staff",
              value: validationFlagIndicator
            });
          });
      }
    };
    fetchStaffRecords();
  }, [provider.providerId, refetch]);

  return (
    <Switch>
      <Route path={`${match.path}/:staffId`}>
        <StaffDetailsScreen
          setRefetch={setRefetch}
          refetch={refetch}
          row={row}
          setRow={setRow}
          setValidationFlag={setValidationFlag}
          urlSlug={urlSlug}
        />
      </Route>
      <Route>
        <StaffGridScreen
          rows={rows}
          setRow={setRow}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      </Route>
    </Switch>
  );
}

export default StaffScreen;
