import { useEffect } from "react";
import UserAccessComponent from "./UserAccessComponent";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Popup,
  Segment
} from "semantic-ui-react";
import RichTextEditor from "./RichTextEditor";
import { useTranslation } from "react-i18next";
import useGridRows from "../hooks/useGridRows";
import { useHistory, useParams } from "react-router-dom";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as NoticeGrid
} from "@caci/react-grid";
import axios from "axios";
import { stripHtml } from "../utils";

function ActionScreen({ recommendationId }) {
  const {
    showDetails,
    setShowDetails,
    row,
    setRow,
    rows,
    setRows
  } = useGridRows();
  const { t } = useTranslation();
  const history = useHistory();
  const { providerId } = useParams();

  useEffect(() => {
    if (recommendationId) {
      axios
        .get(`/api/early-provider-action?recommendationId=${recommendationId}`)
        .then(({ data: rows }) =>
          setRows(
            rows.map(r => ({
              ...r,
              action: r.action ? stripHtml(r.action) : ""
            }))
          )
        )
        .catch(console.log);
    }
  }, [recommendationId]);

  return (
    <>
      {!showDetails ? (
        <NoticeGrid
          actionsCell={({ row }) => (
            <div style={{ textAlign: "center" }}>
              <UserAccessComponent requires="PPORT_PM_FIP_L_EDIT">
                <Popup
                  content={t("View")}
                  trigger={
                    <Icon
                      circular
                      name="pencil"
                      className={"dark-blue-border"}
                      color="black"
                      onClick={() => {
                        setShowDetails(true);
                        setRow(row);
                      }}
                    />
                  }
                />
              </UserAccessComponent>
            </div>
          )}
          columns={[
            {
              key: "actionDate",
              name: t("Action Date"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter
            },
            {
              key: "action",
              name: t("Action"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "personName",
              name: t("Action Owner"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "agreedInpDate",
              name: t("Agreed Implementation Date"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter
            },
            {
              key: "completedDate",
              name: t("Completed Date"),
              filterable: true,
              sortable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter
            }
          ]}
          rows={rows}
        />
      ) : (
        <>
          <Menu attached="top" className={"border-bottom"}>
            <Menu.Item header className={"active"}>
              {t("Action Details")}
            </Menu.Item>
          </Menu>
          <Segment attached={"bottom"}>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Input label={t("Action ID")} value={row.id} />
                    <Form.Input
                      label={t("Action Date")}
                      value={row.actionDate}
                    />
                    <RichTextEditor label={t("Action")} value={row.action} />
                    <Form.Input
                      label={t("Action Owner")}
                      value={row.personName}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <RichTextEditor
                      label={t("Evidence")}
                      value={row.evidence}
                    />
                    <Form.Input
                      label={t("Agreed Implementation Date")}
                      value={row.agreedInpDate}
                    />
                    <Form.Input
                      label={t("Completed Date")}
                      value={row.completedDate}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            <Button
              className="orange"
              onClick={() => {
                setShowDetails(false);
              }}
            >
              <Icon name="arrow left circle" /> {t("Back")}
            </Button>

            <Button
              className="light-blue"
              onClick={() => {
                history.push(
                  `/portal/${providerId}/provider/provider-fip/${row.id}`
                );
              }}
            >
              <Icon>
                <i className="fas fa-map-marker-alt" />
              </Icon>
              {t("FIP Action")}
            </Button>
          </Segment>
        </>
      )}
    </>
  );
}

export default ActionScreen;
