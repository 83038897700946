import {Fragment, useEffect, useState} from "react";
import {Grid as CostVariationGrid, LookupFilter, LookupFormatter,} from "@caci/react-grid";
import axios from "axios";
import {Button, Form, Icon, Menu, Modal, Popup, Segment,} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useSession} from "../consumer/SessionConsumer";
import useLookup from "../consumer/LookupConsumer";
import {lookupCodeToValue, lookupToOptionsTranslation} from "../utils";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useToasts} from "react-toast-notifications";
import {Dropdown} from "@caci/react-form-fields";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UserAccessComponent from "./UserAccessComponent";
import DropdownWithSegments from "./DropdownWithSegments";
import RejectCommentsModal from "./RejectCommentsModal";

const validationFlagsList = ["1", "2", "3"];

const schema = yup.object({
  costVariDet: yup.string().required("Required"),
});

const putObject = {
  headers: {
    "Content-Type": "application/json-patch+json",
  },
};

function CostVariationScreen({ setValidationFlag, urlSlug }) {
  const [rows, setRows] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [isQuickAddModalOpen, setQuickAddModalOpen] = useState(false);
  const { selectedProvider: provider } = useSession();
  const { lookups: costVariation } = useLookup(897);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const fetchCostVariation = () => {
    axios
      .get(`/api/pport-early-variations-list/${provider.providerId}`)
      .then(({ data }) => {
        setRows(data);
        const validationFlagIndicator = data.some((row) =>
          validationFlagsList.includes(row.validationFlag)
        );
        setValidationFlag(urlSlug, {
          name: "costVariation",
          value: validationFlagIndicator,
        });
        setShowExclamation(validationFlagIndicator);
      })
      .catch(console.log);
  };

  const addCostVariation = (value) => {
    const data = {
      providerId: provider.providerId,
      costVariDet: value,
      validationFlag: "I",
    };
    axios
      .post(`/api/pport-early-variations`, data)
      .then(() => {
        addToast(
          `${t("Cost Variation")} ${t("has been successfully created")}`,
          {
            appearance: ("success"),
          }
        );
        fetchCostVariation();
      })
      .catch(console.log);
  };

  const handleDelete = (id) => {
    const data = [{ op: "add", path: "/validationFlag", value: "D" }];
    axios
      .put(`/api/pport-early-variations/${id}`, data, putObject)
      .then(() => {
        addToast(
          `${t("Cost Variation")} ${t("has been successfully removed")}`,
          {
            appearance: ("success"),
          }
        );
        fetchCostVariation();
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Cost Variation")} ${t("has not been deleted")}`, {
          appearance: ("success"),
        });
      });
  };

  const onSubmit = ({ costVariDet, id }) => {
    const data = {
      providerId: provider.providerId,
      costVariDet: costVariDet,
      validationFlag: "I",
    };

    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/costVariDet", value: costVariDet },
    ];

    if (id) {
      axios
        .put(`/api/pport-early-variations/${id}`, put, putObject)
        .then(() => {
          addToast(
            `${t("Cost Variation")} ${t("has been successfully updated")}`,
            {
              appearance: ("success"),
            }
          );
          fetchCostVariation();
        })
        .catch(console.log);
    } else {
      axios
        .post(`/api/pport-early-variations`, data)
        .then(() => {
          addToast(
            `${t("Cost Variation")} ${t("has been successfully created")}`,
            {
              appearance: ("success"),
            }
          );

          fetchCostVariation();
        })
        .catch(console.log);
    }
  };

  const { reset } = methods;

  useEffect(() => {
    if (provider.providerId) {
      fetchCostVariation();
    }
    return () => {
      reset({
        costVariDet: "",
        id: "",
      });
    };
  }, [provider.providerId, reset]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Cost Variation")}
        </Menu.Item>
        {showExclamation && (
          <Menu.Menu position="right">
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <div>
            <div>
              <CostVariationGrid
                actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <UserAccessComponent requires="PPORT_PM_HCP_CV_L_EDIT">
                      <Popup
                        content={t("Edit")}
                        trigger={
                          <Icon
                            circular
                            name="pencil"
                            className={"dark-blue-border"}
                            color="black"
                            onClick={() => {
                              setShowDetail(true);
                              methods.setValue(`costVariDet`, row.costVariDet);
                              methods.setValue(`id`, row.id);
                            }}
                          />
                        }
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_CV_L_EDIT">
                      <RejectCommentsModal
                        rejectComments={row.rejectComments}
                        rejectedDate={row.rejectedDate}
                        rejectedBy={row.rejectedBy}
                        disabled={
                          !validationFlagsList.includes(row.validationFlag)
                        }
                        circular
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_CV_L_DEL">
                      <DeleteConfirmationModal
                        message={t(
                          "Are you sure you want to delete this Cost Variation?"
                        )}
                        onDelete={() => handleDelete(row.id)}
                        iconName="trash alternate outline"
                        color="red-border"
                        circular
                      />
                    </UserAccessComponent>
                  </div>
                )}
                columns={[
                  {
                    key: "costVariDet",
                    name: t("Cost Variation"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: LookupFilter,
                    formatter: LookupFormatter,
                    lookups: costVariation,
                  },
                ]}
                rows={rows}
              />
            </div>
            <div>
              <div className={"gap-btw-grid-buttons"}>
                <UserAccessComponent requires="PPORT_PM_HCP_CV_L_ADD">
                  <Button
                    onClick={() => setShowDetail(true)}
                    className={"purple"}
                  >
                    <Icon>
                      <i className="fas fa-calendar-alt" />
                    </Icon>
                    {t("New Cost Variation")}
                  </Button>

                  <Button
                    onClick={() => setQuickAddModalOpen(true)}
                    className={"purple"}
                  >
                    <Icon>
                      <i className="fas fa-plus-circle" />
                    </Icon>
                    {t("Quick Add")}
                  </Button>
                </UserAccessComponent>
              </div>
            </div>
            <Modal open={isQuickAddModalOpen} className="dark-modal">
              <Modal.Header className="modal-header">
                {t("Quick Add")}
              </Modal.Header>
              <Modal.Content className="modal-content">
                <DropdownWithSegments
                  name={"costVariDet"}
                  fluid
                  search
                  selection
                  label={t("Availability")}
                  onChange={(value) => {
                    addCostVariation(value);
                  }}
                  options={
                    costVariation
                      ? costVariation.map(lookupToOptionsTranslation(t))
                      : []
                  }
                  selected={rows.map((av) => ({
                    text: lookupCodeToValue(costVariation, av.costVariDet),
                    value: av.costVariDet,
                    id: av.id,
                  }))}
                  removeIcon={({ option, disabled }) => (
                    <UserAccessComponent requires="PPORT_PM_HCP_CV_L_DEL">
                      <Icon
                        name="delete"
                        style={{ float: "right" }}
                        links
                        onClick={() => {
                          if (option.id) handleDelete(option.id);
                        }}
                        disabled={disabled}
                      />
                    </UserAccessComponent>
                  )}
                />
              </Modal.Content>
              <Modal.Actions className={"modal-actions"} align={"left"}>
                <Button
                  onClick={() => {
                    setQuickAddModalOpen(false);
                    reset();
                  }}
                  className={"orange"}
                >
                  <Icon name="times circle" />
                  {t("Back")}
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        ) : (
          <Fragment>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Dropdown
                  fluid
                  search
                  selection
                  label={t("Cost Variation")}
                  options={costVariation.map(lookupToOptionsTranslation(t))}
                  name="costVariDet"
                />
                <br />
                <Button
                  onClick={() => {
                    setShowDetail(false);
                    reset();
                  }}
                  color="orange"
                >
                  <Icon name="arrow circle left" />
                  {t("Back")}
                </Button>
                <UserAccessComponent requires="PPORT_PM_HCP_CV_D_SUB">
                  <Button className={"green"} type="submit">
                    <Icon name="check circle" />
                    {t("Submit")}
                  </Button>
                </UserAccessComponent>
              </Form>
            </FormProvider>
          </Fragment>
        )}
      </Segment>
    </>
  );
}

export default CostVariationScreen;
