import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Form, Icon } from "semantic-ui-react";
import UserAccessComponent from "./UserAccessComponent";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { useToasts } from "react-toast-notifications";
import { getValidationFlag, setValueHelper } from "../utils";
import EstablishmentSearch from "./EstablishmentSearch";

function EstablishmentPickupDropOffDetails({
  row = {},
  onBack,
  estabValid = []
}) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { selectedProvider: provider } = useSession();
  const { addToast } = useToasts();
  let searchSource = {};

  const schema = yup.object({
    estabNameWithLeaNoAndDfeeNo: yup
      .string()
      .required(t("Please enter a value"))
      .unique({
        message: t("Already in use"),
        existing: estabValid
      })
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({ leaNo, dfeeNo, id, validationFlag }) => {
    const postData = {
      validationFlag: "I",
      providerId: provider.providerId,
      leaNo,
      dfeeNo
    };

    const putData = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag)
      },
      { op: "add", path: "/leaNo", value: leaNo },
      { op: "add", path: "/dfeeNo", value: dfeeNo }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      }
    };

    setSaving(true);

    if (id) {
      axios
        .put(`/api/pport-early-pickup-drop-sch/${id}`, putData, putObject)
        .then(() => {
          addToast(
            `${t("Establishment Pickup & Drop Off")} ${t(
              "has been successfully updated"
            )}`,
            {
              appearance: "success"
            }
          );
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(
            `${t("Establishment Pickup & Drop Off")} ${t(
              "has not been updated"
            )}`,
            {
              appearance: "error"
            }
          );
          setSaving(false);
        });
    } else {
      axios
        .post(`/api/pport-early-pickup-drop-sch`, postData)
        .then(({ data: { id } }) => {
          addToast(
            `${t("Establishment Pickup & Drop Off")} ${t(
              "has been successfully created"
            )}`,
            {
              appearance: "success"
            }
          );
          methods.setValue("id", id);
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(
            `${t("Establishment Pickup & Drop Off")} ${t(
              "has not been created"
            )}`,
            {
              appearance: "error"
            }
          );
          setSaving(false);
        });
    }
  };

  const handleSearchChange = query => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if(Object.keys(searchSource).length > 0) {
      searchSource.cancel();
    }
    searchSource = source;
    return axios.get(`/api/search/pport-establishment?query=${query}`, { cancelToken: source.token });
  }

  useEffect(() => {
    return () => {
      methods.reset({
        leaNo: "",
        dfeeNo: "",
        providerId: "",
        estabNameWithLeaNoAndDfeeNo: "",
        id: ""
      });
    };
  }, []);

  useEffect(() => {
    setValueHelper(row, methods.setValue);
  }, [row]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <EstablishmentSearch
          disabled={!!row.id}
          name="estabNameWithLeaNoAndDfeeNo"
          onSearchChange={handleSearchChange}
          label={t("Establishment")}
          onResultSelect={({ leaNo, dfeeNo }) => {
            methods.setValue("leaNo", leaNo);
            methods.setValue("dfeeNo", dfeeNo);
          }}
          onValueChange={value => {
            if (!value) {
              methods.setValue("leaNo", "");
              methods.setValue("dfeeNo", "");
              methods.setValue("estabNameWithLeaNoAndDfeeNo", "");
            }
          }}
        />
        <br />
        <Button onClick={() => onBack(false)} color="orange">
          <Icon name="arrow circle left" />
          {t("Back")}
        </Button>
        <UserAccessComponent requires="PPORT_PM_DT_PUD_E_D_SUB">
          <Button className={"green"} type="submit" disabled={saving || !!row.id}>
            <Icon name="check circle" />
            {t("Submit")}
          </Button>
        </UserAccessComponent>
      </Form>
    </FormProvider>
  );
}

export default EstablishmentPickupDropOffDetails;
