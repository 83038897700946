import { Search } from "@caci/react-form-fields";
import { Item } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

function EstablishmentSearch({
  name,
  onResultSelect,
  onSearchChange,
  onValueChange,
  label,
  disabled
}) {
  const { t } = useTranslation();
  return (
    <Search
      fluid
      name={name}
      label={label}
      disabled={disabled}
      placeholder="Enter a Establishment Name"
      resultFormatter={({ estabName, leaNo, dfeeNo }) =>
        `${estabName || ""} (LA:${leaNo} DfE:${dfeeNo})`
      }
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
      onValueChange={onValueChange}
      selectOn="estabId"
      searchLength="2"
      resultRenderer={({ estabId, estabName, leaNo, dfeeNo }) => (
        <Item key={estabId}>
          <Item.Content>
            <Item.Header>
              <b>{estabName}</b>
            </Item.Header>
            <Item.Meta>
              <b>{t("Impulse ID")}:</b> {estabId}
            </Item.Meta>
            <Item.Meta>
              <b>{t("LA No")}:</b> {leaNo}
            </Item.Meta>
            <Item.Meta>
              <b>{t("DfE No")}:</b> {dfeeNo}
            </Item.Meta>
          </Item.Content>
        </Item>
      )}
    />
  );
}

export default EstablishmentSearch;
