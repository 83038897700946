export const SET_ROWS = "ROWS";
export const SET_ROW = "ROW";
export const SET_REFETCH = "REFETCH";
export const SET_IS_NEW = "IS_NEW";
export const SET_SHOW_DETAILS = "SHOW_DETAILS";

export const initialState = {
  rows: [],
  selectedRow: {},
  refetch: false,
  isNew: false,
  showDetails: false,
};

const hasRows = rows => Array.isArray(rows) && rows.length > 0;

const hasRow = row =>
  typeof row === "object" && !Array.isArray(row) && Object.keys(row).length > 0;

function gridRowsReducer(state, action) {
  switch (action.type) {
    case SET_SHOW_DETAILS:
      return { ...state, showDetails: action.details };
    case SET_IS_NEW:
      return { ...state, isNew: action.isNew};
    case SET_REFETCH:
      return { ...state, refetch: action.refetch};
    case SET_ROWS:
      return {
        ...state,
        rows: hasRows(action.rows) ? [...action.rows] : []
      };
    case SET_ROW:
      return { ...state, selectedRow: hasRow(action.row) ? { ...action.row } : {} };
    default:
      return state;
  }
}

export default gridRowsReducer;
