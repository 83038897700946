import { useState, useEffect } from "react";
import { Container, Grid, Message, Search, Card } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ProviderSelector({ providers, setProvider }) {
  const [earlyProviders, setEarlyProviders] = useState([]);
  const { t } = useTranslation();

  const handleProviderSearch = (e, { value }) => {
    setEarlyProviders(
      providers.filter(p =>
        p.providerName.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setEarlyProviders(providers);
  }, [providers]);

  return (
    <Container className={"provider-selector"}>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Message
              info
              icon="info"
              content={t(
                "Please select the provider from the list below that you will be working with during this session."
              )}
            />
            <Search
              minCharacters={0}
              icon="search"
              open={false}
              fluid
              onSearchChange={handleProviderSearch}
              placeholder={`${t("Search")}...`}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card.Group itemsPerRow={3} stackable>
              {earlyProviders.map((provider, index) => (
                <Card
                  key={index}
                  value={provider}
                  onClick={(e, data) => setProvider(provider)}
                  header={`${provider.providerName} (${provider.providerId})`}
                  as={Link}
                  to={`/portal/${provider.providerId}`}
                  className={"provider-card"}
                />
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default ProviderSelector;
