import { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as DataGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import { DateInput, Dropdown, Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "../../components/UserAccessComponent";
import moment from "moment";

function EYFParentDetails() {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [eypChildDob, setEypChildDob] = useState("");
  const [saving, setSaving] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  const { eypChildId } = useParams();
  const { providerId } = useParams();
  function isPrimaryCarerSet(eypParentId) {
    let row = Array.from(rows.filter((r) => r.eypParentId !== eypParentId));
    if (row.length > 0) {
      return row[0].primaryCarerInd === "Y";
    }
    return false;
  }

  useEffect(() => {
    if (!methods.getValues()?.eypParentId) {
      if (!methods.getValues()?.niNassToggle)
        methods.setValue("niNassToggle", "NI");
    }
    if (editMode) {
      axios
        .get(`/api/eyp-child/${eypChildId}`)
        .then((response) => {
          setEypChildDob(response.data.dob);
        })
        .catch(console.log);
    }
  });

  const yesNoOptions = [
    { codeValue: "Y", codeDescription: t("Yes") },
    { codeValue: "N", codeDescription: t("No") },
  ];

  const dropdownYesNoOptions = [
    { value: "Y", text: t("Yes") },
    { value: "N", text: t("No") },
  ];

  const dropdownNoOption = [{ value: "N", text: t("No") }];

  const dropdownNiNassToggleOptions = [
    { value: "NI", text: t("NI Number") },
    { value: "NASS", text: t("NASS Number") },
  ];

  const schema = yup.object({
    forename: yup.string().required(t("Please enter a value")),
    surname: yup.string().required(t("Please enter a value")),
    birthdate: yup
      .date()
      .test(
        "date_after_test",
        "Parent/Carer birthdate cannot be after child's birthdate.",
        function (value) {
          return moment(value).isSameOrBefore(
            moment(eypChildDob).format("YYYY-MM-DD")
          );
        }
      )
      .required(t("Please enter a value")),
    niNassToggle: yup.string(),
    eypChildParentId: yup.string(),
    eypParentId: yup.string(),
    endDate: yup
      .date()
      .test(
        "date_after_test",
        "Parent/Carer end date cannot be before Child's birthdate.",
        function (value) {
          return moment(value).isSameOrAfter(
            moment(eypChildDob).format("YYYY-MM-DD")
          );
        }
      )
      .nullable()
      .default(undefined)
      .notRequired(),
    contactNo: yup.string(),
    email: yup.string(),
    niNo: yup
      .string()
      .when("niNassToggle", {
        is: (niNassToggle) => {
          return "NI" === niNassToggle;
        },
        then: yup.string().required(t("Please enter a value")),
      })
      .matches(
        /^(?![A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]{0,1}$)/,
        "Please enter a valid NI Number"
      ),
    nassNo: yup
      .string()
      .nullable()
      .when("niNassToggle", {
        is: (niNassToggle) => {
          return "NASS" === niNassToggle;
        },
        then: yup.string().required(t("Please enter a value")),
      })
      .matches(
        /^(?!\d{2}\/{1}\d{2}\/{1}\d{5}$|\d{2}\/{1}\d{2}\/{1}\d{5}\/{1}\d{3}$)/,
        "Please enter a valid NASS Number"
      ),
    decInd: yup.string(),
    primaryCarerInd: yup.string(),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleCancel = () => {
    methods.setValue("eypChildParentId", "");
    methods.setValue("eypParentId", "");
    methods.setValue("forename", "");
    methods.setValue("surname", "");
    methods.setValue("birthdate", undefined);
    methods.setValue("endDate", undefined);
    methods.setValue("contactNo", "");
    methods.setValue("email", "");
    methods.setValue("niNassToggle", "NI");
    methods.setValue("niNo", "");
    methods.setValue("nassNo", "");
    methods.setValue("decInd", "");
    methods.setValue("primaryCarerInd", "");
    setEditMode(false);
    getParentsList();
  };

  const handleEdit = (row) => {
    methods.setValue("eypChildParentId", row.eypChildParentId);
    methods.setValue("eypParentId", row.eypParentId);
    methods.setValue("forename", row.forename);
    methods.setValue("surname", row.surname);
    methods.setValue("birthdate", moment(row.birthdate).format("YYYY-MM-DD"));
    methods.setValue("endDate", moment(row.endDate).format("YYYY-MM-DD"));
    methods.setValue("contactNo", row.contactNo);
    methods.setValue("email", row.email);
    methods.setValue("niNassToggle", row.niNo ? "NI" : "NASS");
    methods.setValue("niNo", row.niNo);
    methods.setValue("nassNo", row.nassNo);
    methods.setValue("decInd", row.decInd);
    methods.setValue("primaryCarerInd", row.primaryCarerInd);
    setEditMode(true);
  };

  const onSubmit = ({
    eypChildParentId,
    eypParentId,
    forename,
    surname,
    birthdate,
    endDate,
    contactNo,
    email,
    niNo,
    nassNo,
    decInd,
    primaryCarerInd,
  }) => {
    const postData = {
      forename,
      surname,
      birthdate: moment(birthdate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      contactNo,
      email,
      niNo,
      nassNo,
      decInd,
      primaryCarerInd,
      extCheckInd: "N",
      eyppCheckInd: "N",
      eypChildId: eypChildId,
    };

    const putParentData = [
      { op: "add", path: "/forename", value: forename },
      { op: "add", path: "/surname", value: surname },
      {
        op: "add",
        path: "/birthdate",
        value: moment(birthdate).format("YYYY-MM-DD"),
      },
      { op: "add", path: "/contactNo", value: contactNo },
      { op: "add", path: "/email", value: email },
      { op: "add", path: "/niNo", value: niNo },
      { op: "add", path: "/nassNo", value: nassNo },
      { op: "add", path: "/decInd", value: decInd },
      { op: "add", path: "/primaryCarerInd", value: primaryCarerInd },
    ];

    const putChildParentData = [
      {
        op: "add",
        path: "/endDate",
        value: moment(endDate).format("YYYY-MM-DD"),
      },
      { op: "add", path: "/primaryCarerInd", value: primaryCarerInd },
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    setSaving(true);

    if (!eypParentId) {
      axios
        .post(`/api/eyp-child-parent`, postData)
        .then(({ data }) => {
          history.push(
            `/portal/${providerId}/provider/provider-funding/live-register/${data.id}`
          );
          setSaving(false);
          addToast(t("Parent/Carer has been successfully created"), {
            appearance: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          addToast(t("Parent/Carer has not been created"), {
            appearance: "error",
          });
          setSaving(false);
        });
    } else {
      axios
        .put(
          `/api/eyp-child-parent/${eypChildParentId}`,
          putChildParentData,
          putObject
        )
        .then(({ data }) => {
          axios
            .put(`/api/eyp-parent/${eypParentId}`, putParentData, putObject)
            .then(({ data }) => {
              addToast(t("Parent/Carer has been successfully updated"), {
                appearance: "success",
              });
              setSaving(false);
            })
            .catch((error) => {
              console.log(error);
              addToast(t("Parent/Carer has not been updated"), {
                appearance: "error",
              });
            });
        })
        .catch((error) => {
          console.log(error);
          addToast(t("Parent/Carer has not been updated"), {
            appearance: "error",
          });
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    if (eypChildId !== "new") {
      getParentsList(eypChildId);
    }
  }, [eypChildId]);

  const getParentsList = () => {
    axios
      .get(`/api/eyp-child-parent-list/${eypChildId}`)
      .then(({ data: rows }) => setRows(rows))
      .catch(console.log);
  };

  return (
    <div>
      {!editMode && (
        <Segment attached={"bottom"}>
          <DataGrid
            id={"eyfChildParentLGrid"}
            t={t}
            actionsCell={({ row }) => (
              <div style={{ textAlign: "center" }}>
                <Popup
                  trigger={
                    <Icon
                      circular
                      link
                      color="dark-blue-border"
                      id={"eyfChildParentLEdit"}
                      name={"pencil"}
                      onClick={() => handleEdit(row)}
                    />
                  }
                  content={t("Edit")}
                />
              </div>
            )}
            columns={[
              {
                key: "forename",
                name: t("First Name"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "surname",
                name: t("Surname"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "contactNo",
                name: t("Contact Number"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "email",
                name: t("Email"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "niNo",
                name: t("NI Number"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "nassNo",
                name: t("NASS Number"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "birthdate",
                name: t("Birthdate"),
                filterable: true,
                sortable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
              },
              {
                key: "decInd",
                name: t("Parental declaration signed"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: yesNoOptions,
              },
              {
                key: "endDate",
                name: t("End Date"),
                filterable: true,
                sortable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
              },
              {
                key: "primaryCarerInd",
                name: t("Primary Carer"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: yesNoOptions,
              },
            ]}
            rows={rows}
          />
          <br />
          <Button
            id={"eyfChildParentAddBtn"}
            style={{ marginTop: ".5rem" }}
            onClick={() => {
              setEditMode(true);
            }}
            disabled={rows.length > 1 || eypChildId === "new"}
            className={"purple"}
          >
            <Icon>
              <i className="fas fa-link"></i>
            </Icon>

            {t("Add Parent/Carer")}
          </Button>
        </Segment>
      )}
      {editMode && (
        <Segment attached="bottom">
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Input
                      id="eyfParentForename"
                      label={t("Parent or Carer First Name")}
                      name="forename"
                    />
                    <Input
                      id="eyfParentSurname"
                      label={t("Parent or Carer Surname")}
                      name="surname"
                    />
                    <Dropdown
                      id="eyfParentNiNassToggle"
                      label={t("NI/Nass Number")}
                      name="niNassToggle"
                      options={dropdownNiNassToggleOptions}
                    />
                    {methods.watch("niNassToggle") === "NI" && (
                      <Input
                        id="eyfParentNiNo"
                        label={t("Parent or Carer Ni Number")}
                        name="niNo"
                      />
                    )}
                    {methods.watch("niNassToggle") === "NASS" && (
                      <Input
                        id="eyfParentNiNass"
                        label={t("Parent or Carer Nass Number")}
                        name="nassNo"
                      />
                    )}
                    <Dropdown
                      id="eyfParentPrimaryCarerInd"
                      label={t("Primary Carer/Parent")}
                      name="primaryCarerInd"
                      options={
                        isPrimaryCarerSet(methods.getValues().eypParentId)
                          ? dropdownNoOption
                          : dropdownYesNoOptions
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Input
                      id="eyfParentcontactNo"
                      label={t("Parent or Carer Contact Number")}
                      name="contactNo"
                    />
                    <Input
                      id="eyfParentEmail"
                      label={t("Parent or Carer Email")}
                      name="email"
                    />
                    <DateInput
                      id="eyfParentBirthDate"
                      name="birthdate"
                      label={t("Parent or Carer Birth Date")}
                    />
                    <Dropdown
                      id="eyfParentDecInd"
                      label={t("Has a Parental Declaration been signed?")}
                      name="decInd"
                      options={dropdownYesNoOptions}
                    />
                    <DateInput
                      id="eyfParentEndDate"
                      name="endDate"
                      label={t("Parent or Carer End Date")}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button
                id="eyfParentBackBtn"
                onClick={() => {
                  handleCancel();
                }}
                className={"orange"}
              >
                <Icon name="arrow circle left" />
                {t("Back")}
              </Button>
              <UserAccessComponent requires="PPORT_EYF_PAR_D_SUB">
                <Button
                  id="eyfParentSubmitButton"
                  className={"green"}
                  type="submit"
                  disabled={saving}
                >
                  <Icon name="check circle" />
                  {t("Submit")}
                </Button>
              </UserAccessComponent>
            </Form>
          </FormProvider>
        </Segment>
      )}
    </div>
  );
}

export default EYFParentDetails;
