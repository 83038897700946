import { useEffect, useState } from "react";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import useGridRows from "../hooks/useGridRows";
import CourseDetailsModal from "../components/CourseDetailsModal";
import UserAccessComponent from "../components/UserAccessComponent";
import { Button, Icon, Segment } from "semantic-ui-react";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as CourseGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import useLookup from "../consumer/LookupConsumer";
import {useTranslation} from "react-i18next";

function MyCourses() {
  const { rows, setRows, row, setRow } = useGridRows();
  const { selectedProvider: provider } = useSession();
  const [courseDetailsModalOpen, setCourseDetailsModalOpen] = useState(false);
  const { lookups: subjectCodeLookups } = useLookup(603);
  const { lookups: levelLookups } = useLookup(6132);
  const { lookups: districtLookups } = useLookup(6134);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`/api/course-list/${provider.providerId}`)
      .then(({ data }) => setRows(data))
      .catch(console.log);
  }, [provider.providerId]);

  return (
    <Segment className={"grid-container-550"}>
      <CourseDetailsModal
        row={row}
        isModalOpen={courseDetailsModalOpen}
        onClose={() => setCourseDetailsModalOpen(false)}
        levelLookups={levelLookups}
        districtLookups={districtLookups}
        subjectCodeLookups={subjectCodeLookups}
      />
      <CourseGrid
        actionCellWidth={170}
        actionsCell={({ row }) => (
          <div style={{ textAlign: "center" }}>
            <UserAccessComponent requires="PPORT_TR_MC_DET">
              <Button
                color="blue"
                onClick={() => {
                  setRow(row);
                  setCourseDetailsModalOpen(true);
                }}
                style={{ padding: "0.6rem 1.5rem" }}
              >
                <Icon>
                  <i className="fal fa-chalkboard-teacher" />
                </Icon>
                {t("Course Details")}
              </Button>
            </UserAccessComponent>
          </div>
        )}
        columns={[
          {
            key: "courseCode",
            name: t("Course Code"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "courseDesc",
            name: t("Course Name"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "subjectCode",
            name: t("Course Area"),
            filterable: true,
            sortable: true,
            filterRenderer: LookupFilter,
            formatter: LookupFormatter,
            lookups: subjectCodeLookups
          },
          {
            key: "level",
            name: t("Course Level"),
            filterable: true,
            sortable: true,
            filterRenderer: LookupFilter,
            formatter: LookupFormatter,
            lookups: levelLookups
          },
          {
            key: "name",
            name: t("Course Organiser"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "startDate",
            name: t("Start Date"),
            filterable: true,
            sortable: true,
            filterRenderer: DateFilter,
            formatter: DateFormatter
          },
          {
            key: "duration",
            name: t("Duration"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          },
          {
            key: "maxAttend",
            name: t("Max Attendees"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter
          }
        ]}
        rows={rows}
      />
    </Segment>
  );
}

export default MyCourses;
