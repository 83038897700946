import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Dropdown, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import AvailabilityScreen from "../components/AvailabilityScreen";
import CostVariationScreen from "../components/CostVariationScreen";
import HoursChargesPlacesDetails from "../components/HoursChargesPlacesDetails";
import { useSession } from "../consumer/SessionConsumer";
import PeriodManagementModal from "../components/PeriodManagementModal";
import AgeRangesScreen from "../components/AgeRangesScreen";
import ChargesScreen from "../components/ChargesScreen";
import EntitlementScreen from "../components/EntitlementScreen";
import Hours from "../components/Hours";

const validationFlagsList = ["1", "2", "3"];

function HoursScreen({ setValidationFlag, urlSlug }) {
  const { t } = useTranslation();
  const { selectedProvider: provider } = useSession();
  // set hidePeriod to true and wait until the HCP_PER_HIDE API loads
  const [hidePeriod, setPeriod] = useState(true);
  const [earlyHolidays, setEarlyHolidays] = useState([]);
  const [holidayId, setHolidayId] = useState();
  const [isPeriodModalOpen, setPeriodOpenModal] = useState(false);
  const [showExclamation, setShowExclamation] = useState(false);

  const fetchHolidaySetting = () => {
    axios
      .get("/api/impulse-config/HCP_PER_HIDE")
      .then(({ data }) => setPeriod(data.active === "Y"))
      .catch(console.log);
  };

  const fetchEarlyHolidays = () => {
    axios
      .get(`/api/pport-early-holiday-list/${provider.providerId}`)
      .then(({ data }) => {
        if (data.length > 0) setEarlyHolidays(data);
        const validationFlagIndicator = data.some((row) =>
          validationFlagsList.includes(row.validationFlag)
        );
        setValidationFlag(urlSlug, {
          name: "periodManagement",
          value: validationFlagIndicator,
        });
        setShowExclamation(validationFlagIndicator);
        const defaultPeriod = data.find((eh) => eh.name === "Default Period");
        if (!defaultPeriod) {
          const postData = {
            providerId: provider.providerId,
            name: "Default Period",
            overnightCcInd: "N",
            validateFlag: "I",
          };
          createEarlyHoliday(postData)
            .then(({ data }) => fetchEarlyHolidays())
            .catch(console.log);
        } else {
          setHolidayId((holidayId) => {
            const earlyHolidayExist = earlyHolidays.some(
              (eh) => eh.holidayId === holidayId
            );
            return Object.assign(
                { setEarlyHolidays },
                earlyHolidayExist ? earlyHolidayExist : defaultPeriod
            );
          });
        }
      })

      .catch(console.log);
  };

  const createEarlyHoliday = (postData) => {
    return axios.post(`/api/pport-early-holiday`, postData);
  };

  const options = earlyHolidays
    .map((eh, i) => ({
      text: t(`${eh.name} (${eh.holidayId})`),
      value: eh.holidayId,
    }))
    .sort((a, b) => a.value - b.value);

  useEffect(() => {
    fetchHolidaySetting();
    if (provider.providerId) {
      fetchEarlyHolidays();
    }
  }, [provider.providerId]);

  return (
    <>
      <Grid stackable>
        <Grid.Row className={"padding-top-0"} style={{ paddingBottom: "3px" }}>
          {!hidePeriod && (
            <div>
              <Dropdown
                button
                floating
                options={options}
                value={holidayId ? holidayId.holidayId : ""}
                name="holidayId"
                className={"light-blue"}
                onChange={(_, { value }) => {
                  const earlyHoliday =
                    earlyHolidays.find(
                      (eh) => eh.holidayId === Number(value)
                    ) || {};
                  setHolidayId({ ...earlyHoliday, setEarlyHolidays });
                }}
              />

              <Button
                icon={"calendar plus"}
                color={"purple"}
                onClick={setPeriodOpenModal}
              />
            </div>
          )}

          <PeriodManagementModal
            isPeriodModalOpen={isPeriodModalOpen}
            closePeriodModal={() => setPeriodOpenModal(false)}
            rows={earlyHolidays}
            fetchEarlyHolidays={fetchEarlyHolidays}
            setValidationFlag={setValidationFlag}
            urlSlug={urlSlug}
            validationFlagsList={validationFlagsList}
            showExclamation={showExclamation}
          />
        </Grid.Row>
        <Grid.Row className={"padding-top-0 padding-bottom-0"}>
          <Grid.Column width={6} className={"padding-left-0 padding-right-10"}>
            <HoursChargesPlacesDetails
              holidayId={holidayId}
              rows={earlyHolidays}
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
          <Grid.Column width={5} className={"padding-left-0 padding-right-10"}>
            <AvailabilityScreen
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
          <Grid.Column width={5} className={"padding-left-0 padding-right-3"}>
            <CostVariationScreen
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={"padding-top-0 padding-bottom-0"}>
          <Grid.Column width={9} className={"padding-left-0 padding-right-10"}>
            <AgeRangesScreen
              holidayId={holidayId}
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
          <Grid.Column width={7} className={"padding-left-0 padding-right-3"}>
            <ChargesScreen
              holidayId={holidayId}
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={"padding-top-0 padding-bottom-0"}>
          <Grid.Column width={8} className={"padding-left-0 padding-right-10"}>
            <Hours
              holidayId={holidayId}
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
          <Grid.Column width={8} className={"padding-left-0 padding-right-0"}>
            <EntitlementScreen
              setValidationFlag={setValidationFlag}
              urlSlug={urlSlug}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default HoursScreen;
