import { useState, useEffect } from "react";
import { Menu, Segment, Icon, Popup, Button, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
    Grid as PickupDropOffGrid,
    DefaultFilter,
    LookupFilter,
    LookupFormatter
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useSession } from "../consumer/SessionConsumer";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import RejectCommentsModal from "./RejectCommentsModal";
import { getValidationFlag, lookupToOptionsTranslation } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown } from "@caci/react-form-fields";
import { setValueHelper } from "../utils";

const validationFlagsList = ["1", "2", "3"];

const putObject = {
    headers: {
        "Content-Type": "application/json-patch+json"
    }
};

const combinationInitialState = {
    facility: "",
    details: ""
};

function FacilityScreen({ setValidationFlag, urlSlug }) {
    const { t } = useTranslation();
    const [showDetail, setShowDetail] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [rows, setRows] = useState([]);
    const [showExclamation, setShowExclamation] = useState(false);
    const { selectedProvider: provider } = useSession();
    const { lookups: facilityLookups } = useLookup(1094);
    const { addToast } = useToasts();
    const [nameLookupCodeType, setNameLookupCodeType] = useState("");
    const [saving, setSaving] = useState(false);
    const [combinationKeyValues , setCombinationKeyValues] = useState(combinationInitialState);

    const { lookups: nameLookups } = useLookup(nameLookupCodeType);

    const schema = yup.object({
        facility: yup
            .string()
            .required(t("Please enter a value"))
            .when("details", (details) => {
                if(details) {
                    return yup.string().uniqueCombination({
                        message: "Facilities record already exists for facility and details combination",
                        existing: rows,
                        combinationKeyValues
                    });
                }
            }),
        details: yup.string().required(t("Please enter a value")).max(8, "8 character limit exceeded")
    });

    const methods = useForm({
        resolver: yupResolver(schema)
    });

    const handleDelete = row => {
        const data = [
            {
                op: "add",
                path: "/validationFlag",
                value: getValidationFlag(row.validationFlag, "D")
            }
        ];

        axios
            .put(
                `/api/pport-early-facilities/${row.id}`,
                data,
                putObject
            )
            .then(() => {
                addToast(
                    `${t("Facility")} ${t("has been successfully removed")}`,
                    {
                        appearance: "success"
                    }
                );
                setRefetch(!refetch);
            })
            .catch(error => {
                console.log(error);
                addToast(`${t("Facility")} ${t("has not been removed")}`, {
                    appearance: "error"
                });
            });
    };

    const onSubmit = ({ facility, details, validationFlag, id }) => {
        const postData = {
            facility,
            details,
            validationFlag: "I",
            providerId: provider.providerId
        };

        const putData = [
            {
                op: "add",
                path: "/validationFlag",
                value: getValidationFlag(validationFlag)
            },
            { op: "add", path: "/facility", value: facility },
            { op: "add", path: "/details", value: details }
        ];

        setSaving(true);

        if (id) {
            axios
                .put(`/api/pport-early-facilities/${id}`, putData, putObject)
                .then(() => {
                    addToast(
                        `${t("Facility")} ${t(
                            "has been successfully updated"
                        )}`,
                        {
                            appearance: "success"
                        }
                    );
                    setSaving(false);
                })
                .catch(error => {
                    console.log(error);
                    addToast(
                        `${t("Facility")} ${t(
                            "has not been updated"
                        )}`,
                        {
                            appearance: "error"
                        }
                    );
                    setSaving(false);
                });
        } else {
            axios
                .post(`/api/pport-early-facilities`, postData)
                .then(({ data: { id } }) => {
                    addToast(
                        `${t("Facility")} ${t(
                            "has been successfully created"
                        )}`,
                        {
                            appearance: "success"
                        }
                    );
                    methods.setValue("id", id);
                    setSaving(false);
                })
                .catch(error => {
                    console.log(error);
                    addToast(
                        `${t("Facility")} ${t(
                            "has not been created"
                        )}`,
                        {
                            appearance: "error"
                        }
                    );
                    setSaving(false);
                });
        }
    };

    useEffect(() => {
        const fetchPickups = () => {
            if (!showDetail)
                axios
                    .get(`/api/pport-early-facilities-list/${provider.providerId}`)
                    .then(({ data: rows }) => {
                        setRows(rows);
                        const validationFlagIndicator = rows.some(row =>
                            validationFlagsList.includes(row.validationFlag)
                        );
                        setValidationFlag(urlSlug, {
                            name: "facilities",
                            value: validationFlagIndicator
                        });
                        setShowExclamation(validationFlagIndicator);
                    })
                    .then(console.log);
        };
        if (provider.siteId) fetchPickups();
    }, [provider.siteId, showDetail, refetch]);

    useEffect(() => {
        if(combinationKeyValues?.details) {
            methods.trigger("facility");
        }
    }, [combinationKeyValues?.details])

    return (
        <>
            <Menu attached="top" className={"border-bottom"}>
                <Menu.Item header className={"active"}>
                    {t("Facilities")}
                </Menu.Item>
                <Menu.Menu position="right">
                    {showExclamation && (
                        <Menu.Item>
                            <Icon color={"red"}>
                                <i className="fas fa-exclamation-triangle" />
                            </Icon>
                        </Menu.Item>
                    )}
                </Menu.Menu>
            </Menu>
            <Segment attached={"bottom"}>
                {!showDetail ? (
                    <>
                        <PickupDropOffGrid
                            actionsCell={({ row }) => (
                                <div style={{ textAlign: "center" }}>
                                    <UserAccessComponent requires="PPORT_PM_DT_FAC_L_EDIT">
                                        <Popup
                                            content={t("Edit")}
                                            trigger={
                                                <Icon
                                                    circular
                                                    name="pencil"
                                                    className={"dark-blue-border"}
                                                    color="black"
                                                    onClick={() => {
                                                        setShowDetail(true);
                                                        setValueHelper(row, methods.setValue);
                                                        setNameLookupCodeType(row.facility);
                                                    }}
                                                />
                                            }
                                        />
                                    </UserAccessComponent>

                                    <UserAccessComponent requires="PPORT_PM_DT_FAC_L_EDIT">
                                        <RejectCommentsModal
                                            rejectComments={row.rejectComments}
                                            rejectedDate={row.rejectedDate}
                                            rejectedBy={row.rejectedBy}
                                            disabled={
                                                !validationFlagsList.includes(row.validationFlag)
                                            }
                                            circular
                                        />
                                    </UserAccessComponent>

                                    <UserAccessComponent requires="PPORT_PM_DT_FAC_L_DEL">
                                        <DeleteConfirmationModal
                                            message={t(
                                                "Are you sure you want to delete this Facility?"
                                            )}
                                            onDelete={() => handleDelete(row)}
                                            iconName="trash alternate outline"
                                            color="red-border"
                                            circular
                                        />
                                    </UserAccessComponent>
                                </div>
                            )}
                            columns={[
                                {
                                    key: "facility",
                                    name: t("Facility"),
                                    filterable: true,
                                    sortable: true,
                                    filterRenderer: LookupFilter,
                                    formatter: LookupFormatter,
                                    lookups: facilityLookups
                                },
                                {
                                    key: "detailsDesc",
                                    name: t("Details"),
                                    filterable: true,
                                    sortable: true,
                                    filterRenderer: DefaultFilter
                                }
                            ]}
                            rows={rows}
                        />
                        <div className={"gap-btw-grid-buttons"}>
                            <UserAccessComponent requires="PPORT_PM_DT_FAC_L_ADD">
                                <Button
                                    onClick={() => setShowDetail(true)}
                                    className={"purple"}
                                >
                                    <Icon>
                                        <i className="fas fa-school" />
                                    </Icon>
                                    {t("New Facility")}
                                </Button>
                            </UserAccessComponent>
                        </div>
                    </>
                ) : (
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Dropdown
                                label={t("Facility")}
                                options={facilityLookups.map(lookupToOptionsTranslation(t))}
                                name="facility"
                                cb={val => {
                                    setNameLookupCodeType(val);
                                    methods.setValue('details', '');
                                    setCombinationKeyValues({ ...combinationKeyValues, facility: val });
                                }}
                                disabled={methods.getValues().id}
                            />
                            <Dropdown
                                label={t("Details")}
                                options={nameLookups.map(lookupToOptionsTranslation(t))}
                                name="details"
                                cb={val => {
                                    setCombinationKeyValues({ ...combinationKeyValues, details: val });
                                }}
                                disabled={methods.getValues().id}
                            />
                            <Button
                                onClick={() => {
                                    setShowDetail(false);
                                    methods.reset();
                                }}
                                color="orange"
                            >
                                <Icon name="arrow circle left" />
                                {t("Back")}
                            </Button>
                            <UserAccessComponent requires="PPORT_PM_DT_FAC_D_SUB">
                                <Button className={"green"} type="submit" disabled={saving || methods.getValues().id}>
                                    <Icon name="check circle" />
                                    {t("Submit")}
                                </Button>
                            </UserAccessComponent>
                        </Form>
                    </FormProvider>
                )}
            </Segment>
        </>
    );
}

export default FacilityScreen;
