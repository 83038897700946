import { useReducer } from "react";
import gridRowsReducer, {
  initialState,
  SET_ROWS,
  SET_ROW,
  SET_IS_NEW,
  SET_REFETCH,
  SET_SHOW_DETAILS
} from "../reducer/gridRowsReducer";

function useGridRows() {
  const [state, dispatch] = useReducer(gridRowsReducer, initialState);
  const setRows = rows => dispatch({ type: SET_ROWS, rows });
  const setRow = row => dispatch({ type: SET_ROW, row });
  const setIsNew = isNew => dispatch({ type: SET_IS_NEW, isNew });
  const setRefetch = refetch => dispatch({ type: SET_REFETCH, refetch });
  const setShowDetails = details =>
    dispatch({ type: SET_SHOW_DETAILS, details });
  const { rows, selectedRow: row, isNew, refetch, showDetails } = state;
  return {
    rows,
    row,
    isNew,
    refetch,
    showDetails,
    setRows,
    setRow,
    setIsNew,
    setRefetch,
    setShowDetails
  };
}

export default useGridRows;
